import { writable } from "svelte/store";
import API from "@/services/apiService";
import { GET_RISK_METRICS_OVERVIEW } from "@/static/endPoints";

const overviewData = writable({});
const dataLoaded = writable<boolean>(false);

let isDataFetched = false;

const fetchOverviewData = (): void => {
    if (!isDataFetched) {
        API.get(GET_RISK_METRICS_OVERVIEW)
            .then((res) => {
                overviewData.set(res);
                dataLoaded.set(true);
                isDataFetched = true;
            })
            .catch(() => {});
    }
};

export { overviewData, dataLoaded, fetchOverviewData };
