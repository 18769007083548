/*

README
Please do not add any changes specific to portals here as the plan is for this to be moved into an npm package
so we can use this in all of our FE repos

*/

export type RelativeUrl = `/${string}`;
export type AbsoluteUrl = `http://${string}` | `https://${string}`;
export type _URL = RelativeUrl | AbsoluteUrl;
export interface UrlRule {
    urls: _URL[];
    config: RequestInit;
    matchPartialUrl?: boolean;
}
interface IResponse {
    data: any;
    response: Response | null;
}

function fetchAbsolute(baseUrl: _URL): (url: _URL, options: RequestInit) => Promise<Response> {
    /*
    Fetch wrapper that can handle absolute and relative URLs.
    Also allows us to easily replace fetch with axios or another
    HTTP client in the future
    */
    return function configureFetch(url: _URL, options?: RequestInit): Promise<Response> {
        var url_ = url;
        if (options?.body) {
            options.body = JSON.stringify(options.body);
        }
        if (typeof url === "string" && url.startsWith("/")) {
            url_ = (baseUrl + url) as RelativeUrl;
        }
        return fetch(url_, options);
    };
}

function isPartialMatch(urls: string[], url: _URL): boolean {
    return urls.some((u) => url.includes(u));
}

function applyRulesOptions(url: _URL, options: RequestInit, rules?: UrlRule[]): RequestInit {
    var result = { ...options };
    if (rules) {
        for (const rule of rules) {
            // Set default value for matchPartialUrl if it's undefined
            let matchPartialUrl = rule.matchPartialUrl !== undefined ? rule.matchPartialUrl : true;
            let urlMatch;
            if (matchPartialUrl) {
                urlMatch = isPartialMatch(rule.urls, url);
            } else {
                urlMatch = rule.urls.includes(url);
            }
            if (urlMatch) {
                result = { ...result, ...rule.config };
            }
        }
    }
    return result;
}

export function createRequest(
    baseUrl: _URL,
    {
        defaultOptions,
        urlRules
    }: {
        defaultOptions: RequestInit;
        urlRules?: { urls: _URL[]; config: RequestInit; matchPartialUrl?: boolean }[];
    }
): (url: _URL, options?: RequestInit) => Promise<IResponse> {
    return async function request(url: _URL, options: RequestInit = {}): Promise<IResponse> {
        var _fetch = fetchAbsolute(baseUrl);
        var response: Response | null = null;
        var data = null;
        var finalOptions = applyRulesOptions(url, defaultOptions, urlRules);
        // override with any options passed to request
        finalOptions = { ...finalOptions, ...options };
        try {
            response = await _fetch(url, finalOptions);
            try {
                data = await response.json();
            } catch (err) {
                console.error("Error parsing JSON:", err);
            }
        } catch (err) {
            console.error("Network request failed:", err);
        } finally {
            return { data, response };
        }
    };
}

export function createHttpClient(request: (url: _URL, options: RequestInit) => Promise<IResponse>) {
    return {
        get(url: _URL, options?: RequestInit): Promise<IResponse> {
            return request(url, { ...options, method: "GET" });
        },
        post(url: _URL, options?: RequestInit): Promise<IResponse> {
            return request(url, { ...options, method: "POST" });
        },
        put(url: _URL, options?: RequestInit): Promise<IResponse> {
            return request(url, { ...options, method: "PUT" });
        },
        patch(url: _URL, options?: RequestInit): Promise<IResponse> {
            return request(url, { ...options, method: "PATCH" });
        },
        delete(url: _URL, options?: RequestInit): Promise<IResponse> {
            return request(url, { ...options, method: "DELETE" });
        }
    };
}
