/* eslint-disable
@typescript-eslint/no-unsafe-assignment
*/
import type { IBuyerInvoiceResponse } from "../../../static/types";
import utils from "../../../utilsV2/currency";
import { BASE_URL } from "@/static/endPoints";
import {
    IIconLink,
    ITextLink,
    determinePaymentStatus,
    paymentStatusTagColors,
    paymentStatusTextColors
} from "../../../utilsV2/tableHelpers";

const pStatusMapping: Record<string, string> = {
    NOT_PAID: "Unpaid",
    PAID: "Paid",
    PARTIAL_PAYMENT: "Partially paid",
    OVERDUE: "Overdue",
    IN_COLLECTIONS: "In collection",
    REFUNDED: "Refunded",
    PARTIAL_REFUND: "Partially refunded",
    CANCELLED: "Cancelled",
    RECOURSED: "Recoursed",
    PARTIAL_RECOURSE: "Partially recoursed"
};

export interface IInvoiceObject {
    "Merchant details": {
        tableItemBold: string;
        subItem: string;
    };
    "Purchased by": {
        tableItem: string;
    };
    "Invoice №": {
        tableItem: string;
        subItem: string;
        itemStatusTagTextColor: string;
        itemBgColor: string;
    };
    "Invoice date": {
        tableItem: Date;
    };
    "Due date": {
        tableItem: Date;
        itemTextColor?: string;
        subItem?: string;
        subItemTextColor?: string;
    };
    "Invoice amount": {
        tableItemBold: string;
        subItem?: string;
    };
    Actions: {
        icons: (IIconLink | ITextLink)[];
    };
}

export function buildAllInvoicesTable(invoices: IBuyerInvoiceResponse[]): IInvoiceObject[] {
    const tableRows: IInvoiceObject[] = [];
    if (!Array.isArray(invoices)) return [];
    invoices?.forEach((invoice) => {
        const paymentStatus =
            pStatusMapping[invoice.payment_status] ||
            determinePaymentStatus(invoice.gross_amount, invoice.unreconciled_amount);

        const dueDate = {
            tableItem: invoice.due_date,
            itemTextColor: "",
            subItem: "",
            subItemTextColor: ""
        };

        if (new Date() > new Date(invoice.due_date) && paymentStatus != "Paid") {
            dueDate.itemTextColor = "var(--error-600)";
            dueDate.subItem = "Overdue";
            dueDate.subItemTextColor = "var(--error-600)";
        }

        const icons: (IIconLink | ITextLink)[] = [
            {
                description: "downloadInvoice",
                iconSource: "/pictures/file-download.svg",
                link: `${BASE_URL}/v1/invoice/${invoice.order_id}/pdf`
            }
        ];

        if (invoice.invoice_pis_link) {
            icons.push({
                description: "payStatement",
                iconText: "Pay",
                link: invoice.invoice_pis_link,
                disabled: invoice.unreconciled_amount == 0.0
            });
        }

        const rowItem: IInvoiceObject = {
            "Merchant details": {
                tableItemBold: invoice.merchant_company_name,
                subItem: invoice.merchant_website || ""
            },
            "Purchased by": {
                tableItem: invoice.buyer_representative_name
            },
            "Invoice №": {
                tableItem: invoice.invoice_number,
                subItem: paymentStatus,
                itemStatusTagTextColor: paymentStatusTextColors[paymentStatus],
                itemBgColor: paymentStatusTagColors[paymentStatus]
            },
            "Invoice date": {
                tableItem: invoice.invoice_date
            },
            "Due date": dueDate,
            "Invoice amount": {
                tableItemBold:
                    invoice.unreconciled_amount !== invoice.gross_amount &&
                    invoice.unreconciled_amount == 0.0
                        ? `${utils.formatNumber(invoice.gross_amount)} ${invoice.currency}`
                        : `${utils.formatNumber(invoice.unreconciled_amount)} ${invoice.currency}`,
                subItem:
                    invoice.unreconciled_amount !== invoice.gross_amount &&
                    invoice.unreconciled_amount != 0.0
                        ? `of ${utils.formatNumber(invoice.gross_amount)} ${invoice.currency}`
                        : ""
            },
            Actions: {
                icons
            }
        };
        tableRows.push(rowItem);
    });

    return tableRows;
}

export function filterPaidInvoicesTable(allRows: IInvoiceObject[]): IInvoiceObject[] {
    return allRows.filter((row) => row["Invoice №"].subItem == "Paid");
}

export function filterUnpaidInvoicesTable(allRows: IInvoiceObject[]): IInvoiceObject[] {
    return allRows.filter((row) => ["Unpaid", "Partially paid"].includes(row["Invoice №"].subItem));
}
