import { BASE_URL } from "../../static/endPoints";
import { UrlRule, _URL, createHttpClient, createRequest } from "./config";

const includeCredentialsUrls: UrlRule = {
    urls: [
        "/v1/portal/merchant",
        "/v1/merchant/update",
        "/v1/portal/buyer",
        "/v1/portal/logout",
        "/v1/monboarding",
        "/v1/get_svix_app_portal_url",
        "/v2/portal/merchant",
        "/v1/portal/merchant/dashboard",
        "/billing/v1",
        "/v1/portal/mi/order",
        "/v1/mi/orders",
        "/v1/order",
        "/v1/portal/merchant/buyer_credit_limit",
        "/v1/invoice",
        "/merchant/recourse_limit",
        "/merchant/buyer_recourse_limit"
    ],
    config: { credentials: "include" }
};

const defaultOptions: RequestInit = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
    }
};
const request = createRequest(BASE_URL as _URL, {
    defaultOptions,
    urlRules: [includeCredentialsUrls]
});

export const client = createHttpClient(request);
