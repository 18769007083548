<script lang="ts">
    import { Router, Route, navigate } from "svelte-routing";

    import { CREATE_ORDER_PATH } from "@/pages/merchant/orders/variables";
    export let routes: any[] = [];

    // Add redirect logic
    if (window.location.pathname === "/merchant/createorder") {
        navigate(CREATE_ORDER_PATH, { replace: true });
    }
</script>

<Router>
    {#each routes as route}
        <Route path={route.path} component={route.component} />
    {/each}
</Router>
