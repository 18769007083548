<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button as TwoUIButton } from "@two-ui/core";

    import StatementsTable from "./StatementsTable.svelte";
    import { type IStatementObject, buildStatementsRows } from "./statementUtils";
    import { billingStatements } from "../../../../../store/merchant/billingStatement.store";
    import PageHeader from "../../../../../components/pageHeader/PageHeader.svelte";
    import { showAccountAlert, account } from "../../../../../store/merchant/account.store";
    import * as helpLinks from "../../../../../services/helpCentreService";
    import environment from "@/services/environment";
    import { onMount } from "svelte";

    const helpMenuLinksMerchant = {
        [$_("helpLinks.creditCheckFaq")]: helpLinks.CREDIT_CHECK_FAQ,
        [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
            environment.branding.aboutLink,
        [$_("helpLinks.paymentFaq")]: helpLinks.PAYMENT_FAQ,
        [$_("helpLinks.dunningCollectionFaq")]: helpLinks.DUNNING_COLLECTION_FAQ,
        [$_("helpLinks.customerRecourseFaq")]: helpLinks.CUSTOMER_RECOURSE_FAQ
    };
    const helpMenuLinksBuyer = {
        [$_("helpLinks.buyerRefundFaq")]: helpLinks.BUYER_REFUND_FAQ
    };
    let isBuyerPortal = false;
    let rows: IStatementObject[] = [];

    let helpMenuLinks: Record<string, string> = {};

    $: rows = buildStatementsRows($billingStatements);

    onMount(loadHelpMenuLinks);

    function loadHelpMenuLinks() {
        isBuyerPortal = window.location.pathname.split("/").at(1) === "buyer";
        helpMenuLinks = isBuyerPortal ? helpMenuLinksBuyer : helpMenuLinksMerchant;
    }

    // TODO make util
    function openPage(link: string) {
        window.open(link);
    }
</script>

<div class="page-container">
    <PageHeader
        title="Statements"
        showAccountAlert={$showAccountAlert}
        newUser={!$account?.flags?.has_made_order}
        {helpMenuLinks}
    >
        <div slot="cta">
            <TwoUIButton
                on:click={() => openPage("https://docs.two.inc/openapi/billing-account-api")}
                size="md"
                variant="secondaryGray"
                content={$_("pageHeader.apiDocument")}
            ></TwoUIButton>
        </div>
    </PageHeader>
    <div class="content-container">
        <div class="main-container">
            <StatementsTable {rows} />
        </div>
    </div>
</div>

<style>
    .main-container {
        background: #ffffff;
        border: 1px solid #f2f4f7;
        box-shadow: 0px 2px 6px rgba(208, 213, 221, 0.25);
        border-radius: 8px;
        margin: 1.5rem 2rem 1.5rem 2rem;
        display: flex;
        overflow: auto;
        flex-direction: column;
        height: 80vh;
    }
</style>
