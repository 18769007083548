<script lang="ts">
    // import Header from "../../components/sidenav/Header.svelte";
    import SideNav from "../../components/sidenav/SideNav.svelte";
    import { buyerItemList } from "../../static/itemList";
    import buyerRoutes from "../../routes/buyer.routes";
    import Router from "../../routes/CustomRouter.svelte";
    import { loggedIn, buyerStoreMethods } from "../../store/buyer/account.store";
    import { onMount } from "svelte";
    export let url: string;

    let hidden: boolean = false;
    let show: boolean = true;

    // const toggleHidden = (): any => {
    //     show = !show;
    //     hidden = !show;
    // };
    const close = (): any => {
        show = false;
        hidden = true;
    };

    onMount(() => {
        buyerStoreMethods.checkLoggedIn();
        buyerStoreMethods.getBillingAccountBuyerStatements();
        buyerStoreMethods.getBuyerInvoices();
    });
</script>

{#if $loggedIn}
    <div class="relative w-full">
        <!-- <Header on:toggle={toggleHidden} /> -->
        <div class="flex flex-row w-full h-content">
            <SideNav itemList={buyerItemList} type="Buyer" {hidden} {show} on:close={close} />
            <div class="w-full overflow-auto bg-user-gray-50 relative">
                <div class="w-full md:h-0" />
                <Router routes={buyerRoutes} {url} />
            </div>
        </div>
    </div>
{/if}
