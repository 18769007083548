<script lang="ts">
    import { _ } from "svelte-i18n";
    import Fa from "svelte-fa";
    import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
    import { Button } from "@two-ui/core";

    import Modal from "@/components/Elements/Modal.svelte";
    import WhitelistModal from "./WhitelistModal.svelte";
    import BlockModal from "./BlockModal.svelte";
    import API from "@/services/apiService";
    import { GET_CUSTOMER_BETA } from "@/static/endPoints";
    import notificationState from "@/store/notifications.store";
    import { NotificationType } from "@/static/types";

    let showDelete = false;
    let deleteProcessing = false;
    let showWhitelistModal = false;
    let showBlockModal = false;

    function onDeleteRequested() {
        deleteProcessing = true;
        API.get(GET_CUSTOMER_BETA)
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("riskManagement.fraud.itemDeleted")
                );
            })
            .catch((err) => {
                const errorMessage =
                    err.response?.data?.error_message || $_("errors.somethingWrong");
                notificationState.actions.create(NotificationType.ERROR, errorMessage);
            })
            .finally(() => {
                deleteProcessing = false;
                showDelete = false;
            });
    }
</script>

<div class="flex items-center justify-between px-6 py-4 border-b sticky top-0 bg-white">
    <!-- Left Section: Company Details -->
    <div class="flex flex-col">
        <h2 class="text-xl font-semibold">{"Big Spender AS"}</h2>
        <p class="text-sm text-gray-700">{"1234567890"}</p>
    </div>

    <!-- Right Section: Actions -->
    <div class="flex items-center gap-2">
        <!-- Delete Icon -->
        <Button
            on:click={() => {
                showDelete = true;
            }}
            size="sm"
            class="mr-1"
            variant="secondaryGray"
        >
            <!-- Trash Icon -->
            <Fa size="md" slot="leftIcon" icon={faTrashCan} />
        </Button>

        <!-- Whitelist Button -->
        <Button
            on:click={() => (showWhitelistModal = true)}
            size="sm"
            class="mr-1"
            variant="secondaryGray"
            content={$_("riskManagement.fraud.whitelist")}
        />

        <!-- Block Button -->
        <Button
            on:click={(e) => (showBlockModal = true)}
            size="sm"
            class="mr-1"
            variant="secondaryGray"
            content={$_("riskManagement.fraud.block")}
        />
    </div>

    <!-- Delete modal -->
    <Modal title={$_("riskManagement.fraud.deleteFromQueue")} bind:show={showDelete}>
        <p class="mt-4 mb-6">
            {$_("riskManagement.fraud.deleteFromQueueText")}
        </p>
        <div class="flex mt-6" slot="footer">
            <Button
                class="mr-2"
                size="md"
                variant="secondaryGray"
                on:click={() => {
                    showDelete = false;
                }}
                content={$_("components.cancel")}
            />
            <Button
                size="md"
                on:click={onDeleteRequested}
                variant="destructivePrimary"
                disabled={deleteProcessing}
                content={deleteProcessing ? $_("components.loading") : $_("components.delete")}
            />
        </div>
    </Modal>
    <!-- Whitelist modal -->
    <WhitelistModal bind:showWhitelistModal />
    <!-- Block modal -->
    <BlockModal bind:showBlockModal />
</div>
