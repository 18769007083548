<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import { Button } from "@two-ui/core";

    import mAllOrdersState from "@/store/merchant/allOrders.store";
    import { DateToString } from "@/utils/functions";
    import DateSelector from "./DateSelector.svelte";
    import { clickOutside } from "@/utils/clickOutside.js";
    import Icon from "../../icons/Icon.svelte";
    import FiltersSidebar from "../../filters/FiltersSidebar.svelte";
    import { OrderType } from "@/static/types";
    import CheckboxItem from "@/components/checkboxitem/CheckboxItem.svelte";
    import Radio from "../../Elements/Radio.svelte";
    import environment from "@/services/environment";
    import { deleteQueryParam } from "@/utilsV2/helpers";

    const ALL = "ALL";
    const ALL_STATUSES = "ALL-STATUSES";

    export let searchValue: string = "";
    export let selectedTab: string = "";
    export let selectedStatus: string[] = [ALL];
    export let selectedAction: string = "Any";
    export let orderSource: OrderType[] = [OrderType.MERCHANT_INITIATED, OrderType.BUYER_INITIATED];

    let minimumOrderValue: string = "";
    let maximumOrderValue: string = "";
    let orderStatus: string[] = [ALL];
    let actionRequired: string = "Any";
    let dateChanged: boolean = false;
    let filterCounter: number = 0;

    const PURCHASE_DATE: string = "Purchase date";
    const ORDER_VALUE_LESS_THAN: string = "Order value less than";
    const ORDER_VALUE_MORE_THAN: string = "Order value more than";
    const CUSTOMER_CONFIRMATION: string = "CUSTOMER_CONFIRMATION";
    const CUSTOMER_VERIFICATION: string = "CUSTOMER_VERIFICATION";
    const AWAITING_FULFILLMENT: string = "AWAITING_FULFILLMENT";
    const allowedActions = [
        CUSTOMER_CONFIRMATION,
        CUSTOMER_VERIFICATION,
        "Any",
        AWAITING_FULFILLMENT
    ];

    const statusItems = [
        {
            name: "selectall",
            value: "All"
        },
        {
            name: "selectrefunded",
            value: "Refunded"
        },
        {
            name: "selectpending",
            value: "Pending"
        },
        {
            name: "selectreadytofulfil",
            value: "Ready to fulfill"
        },
        {
            name: "selectcancelled",
            value: "Cancelled"
        },
        {
            name: "selectfulfilled",
            value: "Fulfilled"
        }
    ];

    const riskTypeItems = [
        {
            value: "any",
            label: "Any"
        },
        {
            value: "direct_invoice",
            label: "Direct invoice"
        },
        {
            value: "funded_invoice",
            label: "Funded invoice"
        },
        {
            value: "funded_invoice_recourse",
            label: "Funded invoice on recourse"
        }
    ];

    // assume the URL might be as ?tab=fulfilled&filter=PAID,REFUNDED
    function getInitialPaymentStatuses() {
        const urlParams = new URLSearchParams(window.location.search);
        const filter = urlParams.get("filter");

        if (filter) {
            const existingFilters = filter
                .split(",")
                .filter((item) => filterPaymentStatuses.map((item) => item.value).includes(item));
            if (existingFilters.length > 0) {
                mAllOrdersState.actions.setPaymentStatuses(existingFilters);
                return existingFilters;
            }
            return [ALL_STATUSES];
        }
        return [ALL_STATUSES];
    }

    const filterPaymentStatuses = [
        { value: ALL_STATUSES, label: "All" },
        { value: "PAID", label: "Paid" },
        { value: "NOT_PAID", label: "Unpaid" },
        { value: "PARTIAL_PAYMENT", label: "Partial payment" },
        { value: "REFUNDED", label: "Refunded" },
        { value: "PARTIAL_REFUND", label: "Partial refund" },
        { value: "RECOURSED", label: "Recoursed" },
        { value: "PARTIAL_RECOURSE", label: "Partial recourse" }
    ];

    let paymentStatuses: string[] = getInitialPaymentStatuses();

    const approvalTypeItems = [
        {
            value: "all",
            label: "All orders"
        },
        {
            value: "approved",
            label: `Approved by ${environment.branding.displayName}`
        },
        {
            value: "rejected",
            label: `Rejected by ${environment.branding.displayName}`
        }
    ];

    let riskTypeSelected = riskTypeItems[0];
    let approvalTypeSelected = approvalTypeItems[0];

    let showFilters: boolean = false;
    let filterItem: string = "";

    let startDate: Date;
    let endDate: Date;

    let showPortalOrders: boolean = true;
    let showWebshopOrders: boolean = true;

    onMount(() => {
        mAllOrdersState.startDate.subscribe((value: Date) => (startDate = value));
        mAllOrdersState.endDate.subscribe((value: Date) => (endDate = value));
        mAllOrdersState.filters.subscribe((value: any) => {
            if (
                value[ORDER_VALUE_LESS_THAN] > 0 &&
                value[ORDER_VALUE_LESS_THAN] !== maximumOrderValue
            ) {
                maximumOrderValue = value[ORDER_VALUE_LESS_THAN];
            }
            if (
                value[ORDER_VALUE_MORE_THAN] > 0 &&
                value[ORDER_VALUE_MORE_THAN] !== minimumOrderValue
            ) {
                minimumOrderValue = value[ORDER_VALUE_MORE_THAN];
            }
        });
        mAllOrdersState.selectedSource((value: []) => {
            orderSource = value;
            showPortalOrders = orderSource.includes(OrderType.MERCHANT_INITIATED);
            showWebshopOrders = orderSource.includes(OrderType.BUYER_INITIATED);
        });
        mAllOrdersState.selectedStatus((value: []) => {
            selectedStatus = value;
            orderStatus = value;
        });
        // mAllOrdersState.selectedTab((value: string) => {
        //     selectedTab = value;
        // });
        mAllOrdersState.selectedAction((value: string) => {
            selectedAction = value;
            actionRequired = value;
        });
        updateFilterCount();
    });

    export const updateFilterCount = function () {
        filterCounter = 0;
        if (startDate || endDate) {
            filterCounter++;
        }

        if (maximumOrderValue !== "" || minimumOrderValue !== "") {
            filterCounter++;
        }
        if (
            !orderSource.includes(OrderType.MERCHANT_INITIATED) ||
            !orderSource.includes(OrderType.BUYER_INITIATED)
        ) {
            filterCounter++;
        }
        if (selectedTab === "All" && selectedStatus && !selectedStatus.includes(ALL)) {
            // selected ALL statuses in "All" tab
            filterCounter++;
        }
        if (selectedAction !== "Any") {
            filterCounter++;
        }
        if (riskTypeSelected != riskTypeItems[0]) {
            filterCounter++;
        }
        if (approvalTypeSelected != approvalTypeItems[0]) {
            filterCounter++;
        }

        if (!paymentStatuses?.includes(ALL_STATUSES) && paymentStatuses?.length > 0) {
            filterCounter++;
        }
    };

    function checkPayments(event) {
        const allValues = filterPaymentStatuses
            .filter((item) => item.value != ALL_STATUSES)
            .map((item) => item.value);
        paymentStatuses = filterArray(event, paymentStatuses, allValues);
    }

    const filterArray = (event, checkedArray: string[], allValues: string[]) => {
        if (event.detail.checked) {
            if (event.detail.checkedItem === ALL_STATUSES) {
                checkedArray = [ALL_STATUSES];
            } else {
                checkedArray.push(event.detail.checkedItem);

                if (allValues.every((item) => checkedArray.includes(item))) {
                    checkedArray = [ALL_STATUSES];
                }
            }
        }

        if (!event.detail.checked) {
            if (event.detail.checkedItem === ALL_STATUSES) {
                checkedArray = [];
            } else if (checkedArray.includes(ALL_STATUSES)) {
                checkedArray = allValues;
            }

            const index = checkedArray.indexOf(event.detail.checkedItem);
            if (index !== -1) {
                checkedArray.splice(index, 1);
            }
        }
        return checkedArray;
    };

    function clearFilters() {
        deleteQueryParam("filter");
        riskTypeSelected = riskTypeItems[0];
        approvalTypeSelected = approvalTypeItems[0];
        minimumOrderValue = "";
        maximumOrderValue = "";
        updateOrderValue();
        mAllOrdersState.actions.setAmountFrom(minimumOrderValue);
        mAllOrdersState.actions.setAmountTo(maximumOrderValue);
        mAllOrdersState.actions.setSearchText(searchValue);

        startDate = null;
        mAllOrdersState.actions.setStartDate(startDate);
        endDate = null;
        mAllOrdersState.actions.setEndDate(endDate);
        mAllOrdersState.actions.setSelectedRiskType(riskTypeItems[0].value);
        mAllOrdersState.actions.setSelectedApprovalType(approvalTypeItems[0].value);
        mAllOrdersState.actions.getAllOrders();

        showPortalOrders = true;
        showWebshopOrders = true;
        orderSource = [OrderType.MERCHANT_INITIATED, OrderType.BUYER_INITIATED];
        mAllOrdersState.actions.setOrderSource(orderSource);

        selectedStatus = [ALL];
        mAllOrdersState.actions.setSelectedOrderStatus(selectedStatus);

        selectedAction = "Any";
        mAllOrdersState.actions.setSelectedActionRequired(selectedAction);

        paymentStatuses = [ALL_STATUSES];

        dateChanged = false;
        showFilters = false;
        updateFilterCount();
    }

    function handleClick(item) {
        filterItem = item;
    }

    function handleClose() {
        filterItem = "";
    }

    function openFilters() {
        showFilters = !showFilters;
    }

    function applyFilters() {
        if (dateChanged) {
            mAllOrdersState.startDate.set(startDate);
            mAllOrdersState.endDate.set(endDate);
        }
        updateOrderValue();
        mAllOrdersState.actions.setAmountFrom(minimumOrderValue);
        mAllOrdersState.actions.setAmountTo(maximumOrderValue);
        mAllOrdersState.actions.setSearchText(searchValue);

        const tempSource = [];
        if (showPortalOrders) tempSource.push(OrderType.MERCHANT_INITIATED);
        if (showWebshopOrders) tempSource.push(OrderType.BUYER_INITIATED);
        orderSource = tempSource;
        mAllOrdersState.actions.setOrderSource(orderSource);

        selectedStatus = orderStatus;
        mAllOrdersState.actions.setSelectedOrderStatus(selectedStatus);

        selectedAction = actionRequired;
        mAllOrdersState.actions.setSelectedActionRequired(selectedAction);

        mAllOrdersState.actions.setSelectedRiskType(riskTypeSelected.value);
        mAllOrdersState.actions.setSelectedApprovalType(approvalTypeSelected.value);
        mAllOrdersState.actions.setPaymentStatuses(paymentStatuses);

        updateFilterCount();

        mAllOrdersState.actions.getAllOrders();

        dateChanged = false;
        showFilters = false;
        // TODO apply risk/fraud type filter once BE PR done
    }

    const updateFilter = (item: string) => {
        return (event: CustomEvent) => {
            if (item === PURCHASE_DATE) {
                startDate = event.detail.startDate;
                endDate = event.detail.endDate;
                dateChanged = true;
            }
        };
    };

    function updateOrderRange(e) {
        if (e.target.id === "maxVal") {
            maximumOrderValue = e.target.value;
        } else {
            minimumOrderValue = e.target.value;
        }
    }

    function updateOrderValue() {
        mAllOrdersState.filters.update((filters: any) => {
            if (maximumOrderValue === "") delete filters[ORDER_VALUE_LESS_THAN];
            if (minimumOrderValue === "") delete filters[ORDER_VALUE_MORE_THAN];

            return {
                ...filters,
                ...(maximumOrderValue !== "" && { [ORDER_VALUE_LESS_THAN]: maximumOrderValue }),
                ...(minimumOrderValue !== "" && { [ORDER_VALUE_MORE_THAN]: minimumOrderValue })
            };
        });
    }

    function toggleOrderSource(event) {
        if (event.target.id === "portal") {
            showPortalOrders = !showPortalOrders;
        } else if (event.target.id === "webshop") {
            showWebshopOrders = !showWebshopOrders;
        }
    }

    function filterStatus(event) {
        if (event.detail.checked) {
            if (event.detail.checkedItem.toUpperCase() === "ALL") {
                orderStatus = ["ALL"];
            } else {
                orderStatus.push(event.detail.checkedItem.toUpperCase());
                if (
                    orderStatus.includes("PENDING") &&
                    orderStatus.includes("READYTOFULFIL") &&
                    orderStatus.includes("FULFILLED") &&
                    orderStatus.includes("REFUNDED") &&
                    orderStatus.includes("CANCELLED")
                ) {
                    orderStatus = ["ALL"];
                }
            }
        }

        if (!event.detail.checked) {
            if (event.detail.checkedItem.toUpperCase() === "ALL") {
                orderStatus = [];
            } else if (orderStatus.includes("ALL")) {
                orderStatus = ["PENDING", "FULFILLED", "REFUNDED", "CANCELLED", "READY TO FULFILL"];
            }

            const index = orderStatus.indexOf(event.detail.checkedItem.toUpperCase());
            if (index !== -1) {
                orderStatus.splice(index, 1);
            }
        }
    }

    function onChange(event) {
        if (allowedActions.includes(event.currentTarget.value)) {
            actionRequired = event.currentTarget.value;
        }
    }
</script>

<div class="filter-button" class:filters-active={filterCounter > 0} on:click={openFilters} on:keyup>
    <Icon name="sliders" size={16} />
    <span>{$_("filters.filters")}</span>
    {#if filterCounter > 0}
        <div class="filter-counter">{filterCounter}</div>
    {/if}
</div>

{#if showFilters}
    <FiltersSidebar>
        <div slot="header" class="filter-header">
            <div>
                <span>{$_("filters.filtersFor")}</span>
                <span class="header-subtext"
                    >{selectedTab.toLowerCase()} {$_("filters.orders")}</span
                >
            </div>
            <div class="close">
                <button on:click={openFilters}>
                    <Icon name="cross" size={32} />
                </button>
            </div>
        </div>
        <div slot="main">
            <div class="filter-item">
                <span class="filter-item-header-text">{$_("filters.orderCreated")}</span>
                <div class="order-source">
                    <div
                        id="portal"
                        class="order-source-item"
                        class:selected={showPortalOrders}
                        on:click={toggleOrderSource}
                        on:keyup
                    >
                        <div class="order-source-icon">
                            <Icon name="merchant-portal" size={32} />
                        </div>
                        <div class="order-source-text">
                            <span class="filter-item-header-text">{"In Merchant portal"}</span>
                            <span>{$_("filters.merchantPortalDescription")}</span>
                        </div>
                    </div>
                    <div
                        id="webshop"
                        class="order-source-item"
                        class:selected={showWebshopOrders}
                        on:click={toggleOrderSource}
                        on:keyup
                    >
                        <div class="order-source-icon">
                            <Icon name="webshop" size={32} />
                        </div>
                        <div class="order-source-text">
                            <span class="filter-item-header-text"
                                >{$_("filters.inYourWebshop")}</span
                            >
                            <span>{$_("filters.webshopDescription")}</span>
                        </div>
                    </div>
                </div>
            </div>
            {#if ["All", "Fulfilled", "Refunded"].includes(selectedTab)}
                <div class="filter-item">
                    <span class="filter-item-header-text">{$_("filters.orderStatus")}</span>
                    <div class="order-status-items">
                        {#each statusItems as statusItem}
                            <CheckboxItem
                                {statusItem}
                                isChecked={orderStatus.includes(statusItem.value.toUpperCase()) ||
                                    orderStatus.includes("ALL")}
                                on:checkedItem={filterStatus}
                            />
                        {/each}
                    </div>
                </div>

                <div class="filter-item">
                    <span class="filter-item-header-text">{$_("filters.creditFraudRiskType")}</span>
                    <div class="risk-type-items">
                        {#each riskTypeItems as riskItem}
                            <Radio
                                bind:group={riskTypeSelected}
                                option={riskItem}
                                id={riskItem.label}
                            />
                        {/each}
                    </div>
                </div>

                {#if ["All", "Fulfilled"].includes(selectedTab)}
                    <div class="filter-item">
                        <span class="filter-item-header-text">{$_("filters.paymentStatus")}</span>
                        <div class="order-status-items">
                            {#each filterPaymentStatuses as paymentStatus}
                                <CheckboxItem
                                    statusItem={paymentStatus}
                                    isChecked={paymentStatuses.includes(paymentStatus.value) ||
                                        paymentStatuses.includes(ALL_STATUSES)}
                                    checkedEventName="statusChanged"
                                    on:statusChanged={checkPayments}
                                />
                            {/each}
                        </div>
                    </div>
                {/if}
            {/if}
            {#if ["All", "Cancelled"].includes(selectedTab)}
                <div class="filter-item">
                    <span class="filter-item-header-text">{$_("filters.orderApproval")}</span>
                    <div class="risk-type-items">
                        {#each approvalTypeItems as approvalItem}
                            <Radio
                                bind:group={approvalTypeSelected}
                                option={approvalItem}
                                id={approvalItem.label}
                            />
                        {/each}
                    </div>
                </div>
            {/if}
            <div class="filter-item">
                <span class="filter-item-header-text">{PURCHASE_DATE}</span>
                <span>{$_("filters.chooseRange")}</span>
                <div
                    on:click={() => handleClick(PURCHASE_DATE)}
                    on:keyup
                    class="data-range date-range"
                >
                    {`${DateToString(startDate, true)} - ${DateToString(endDate, true)}`}
                </div>
                {#if filterItem === PURCHASE_DATE}
                    <div use:clickOutside on:click_outside={handleClose}>
                        <DateSelector
                            on:close={handleClose}
                            on:setDate={updateFilter(PURCHASE_DATE)}
                            {startDate}
                            {endDate}
                            top="155px"
                        />
                    </div>
                {/if}
            </div>
            {#if selectedTab === "Pending"}
                <div class="filter-item">
                    <span class="filter-item-header-text">{$_("filters.actionRequired")}</span>
                    <div class="order-action-required">
                        <div class="radio-item">
                            <input
                                type="radio"
                                id="any"
                                name="action_required"
                                value="Any"
                                on:change={onChange}
                                checked={actionRequired === "Any"}
                            />
                            <label for="any">{$_("filters.any")}</label><br />
                        </div>
                        <div class="radio-item">
                            <input
                                type="radio"
                                id="customer_confirmation"
                                name="action_required"
                                value={CUSTOMER_CONFIRMATION}
                                on:change={onChange}
                                checked={actionRequired === CUSTOMER_CONFIRMATION}
                            />
                            <label for="customer_confirmation"
                                >{$_("filters.customerConfirmation")}</label
                            ><br />
                        </div>
                        <div class="radio-item">
                            <input
                                type="radio"
                                id="customer_verification"
                                name="action_required"
                                value={CUSTOMER_VERIFICATION}
                                on:change={onChange}
                                checked={actionRequired === CUSTOMER_VERIFICATION}
                            />
                            <label for="customer_verification"
                                >{$_("filters.customerVerification")}</label
                            ><br />
                        </div>
                    </div>
                </div>
            {/if}
            <div class="filter-item">
                <span class="filter-item-header-text">{$_("filters.orderValue")}</span>
                <span>{$_("filters.chooseOrderValues")}</span>
                <div class="data-range order-value">
                    <input
                        id="minVal"
                        placeholder={$_("filters.min")}
                        bind:value={minimumOrderValue}
                        on:input={updateOrderRange}
                    />
                    <span>-</span>
                    <input
                        id="maxVal"
                        placeholder={$_("filters.max")}
                        bind:value={maximumOrderValue}
                        on:input={updateOrderRange}
                    />
                </div>
            </div>
        </div>
        <div slot="footer" class="filter-footer">
            <Button
                on:click={applyFilters}
                size="md"
                variant="primary"
                content={$_("filters.applyFilters")}
                fullSize={false}
            />
            <Button
                on:click={clearFilters}
                size="md"
                variant="secondaryGray"
                content={$_("filters.clearAll")}
                fullSize={false}
            />
        </div>
    </FiltersSidebar>
{/if}

<style>
    .date-range {
        border-radius: 0.5rem;
        max-width: 15rem;
    }

    .order-status-items {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        gap: 0.5rem;
    }

    .risk-type-items {
        display: grid;
        gap: 0.5rem;
    }

    .order-value {
        border-radius: 0.5rem;
        max-width: 14rem;
    }

    .order-value input {
        max-width: 90px;
    }

    .order-value input:first-child {
        text-align: right;
        padding-right: 0.5rem;
    }

    .order-value input:last-child {
        padding-left: 0.5rem;
    }

    .order-value input:focus {
        outline: none;
    }

    .data-range {
        display: flex;
        border: 1px solid var(--borderGeneralSecondary);
        padding: 0 1rem;
        height: 2.5rem;
        line-height: 2.5rem;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: var(--ctnGeneralPrimary);
    }

    .order-source {
        display: flex;
        gap: 1.5rem;
    }

    .order-source-item {
        width: 263px;
        height: 103px;
        background: #ffffff;
        border-radius: 12px;
        display: flex;
        cursor: pointer;
        border: 1px solid #d0d5dd;
    }

    .selected {
        border: 1px solid var(--primary-600);
        box-shadow: 0px 0px 0px 4px var(--primary-50);
    }

    .order-source-icon {
        margin: 20px 1rem 35px 1rem;
        pointer-events: none;
    }

    .order-source-text {
        margin: auto;
        pointer-events: none;
    }

    .radio-item {
        display: flex;
        margin-bottom: 10px;
    }

    .radio-item input {
        height: 16px;
        margin: auto;
        width: 16px;
        cursor: pointer;
    }

    .radio-item label {
        flex: 1;
        cursor: pointer;
        padding-left: 10px;
    }
</style>
