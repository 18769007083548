<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher, onMount } from "svelte";

    import utils from "../../../../utilsV2/currency";
    import { DATA_TYPE } from "../productTable.constants";
    import type { IMorderSummary, IOrderItem, LanguageCode } from "../../../../static/types";
    import modalState from "../../../../store/modals.store";
    import { COUNTRIES } from "../../../../static/constant";
    import Icon from "../../../../components/icons/Icon.svelte";
    import Input from "../../../../components/Elements/InputBox.svelte";

    const dispatch = createEventDispatcher();
    export let order: IMorderSummary | undefined;
    export let fulfilledAmount = 0;
    export let title = "";
    export let columns = [];
    export let items: IOrderItem[] = [];
    export let locale = "";
    export let currency: any;
    export let taxNaming: string = $_("order.partialFulfil.vat");
    export let shippingAddress: {} = {};
    export let billingAddress: {} = {};

    let fulfillLineItems: IOrderItem[] = [];

    let netAmount: number = 0;
    let taxAmount: number = 0;
    let grossAmount: number = 0;

    let allProductsChecked: boolean = false;
    let disabledItemCheckbox: boolean = false;
    let fulfillGroup: number[] = [];
    let disabledFulfilledClass: string = "-disabled";
    let displayTableError: boolean = false;
    let tableErrorText: string = "";
    let errorElements: number[] = [];

    onMount(() => {
        fulfillLineItems = items.map((obj) => ({ ...obj }));
    });

    $: modalState.modals((values) => {
        if (values.props?.isFulfilled) {
            resetPartialFulfillProcess();
        }
    });

    function toggleAllProductItems() {
        allProductsChecked = !allProductsChecked;
        fulfillGroup = [];

        if (!allProductsChecked) {
            netAmount = 0;
            taxAmount = 0;
            grossAmount = 0;
            disabledFulfilledClass = "-disabled";
        } else {
            let index: number = 0;

            fulfillLineItems.forEach(() => {
                fulfillGroup.push(index);
                index++;
            });
            allProductsChecked = true;
            fulfillGroup = fulfillGroup;
            setPartialFulfillAmount();
            disabledFulfilledClass = "";
        }
    }

    function setPartialFulfillAmount() {
        setTimeout(() => {
            netAmount = 0;
            taxAmount = 0;
            grossAmount = 0;
            fulfillGroup.forEach((index) => {
                netAmount += Number(fulfillLineItems[index].net_amount.toFixed(2));
                taxAmount += Number(fulfillLineItems[index].tax_amount.toFixed(2));
                grossAmount += Number(fulfillLineItems[index].gross_amount.toFixed(2));
            });
            allProductsChecked = fulfillGroup.length === fulfillLineItems.length;
            disabledItemCheckbox = false;
            disabledFulfilledClass =
                fulfillGroup.length > 0 && !displayTableError ? "" : "-disabled";
        });
    }

    function resetPartialFulfillProcess() {
        fulfillGroup = [];
        netAmount = 0;
        taxAmount = 0;
        grossAmount = 0;
        fulfillLineItems = [];
        dispatch("cancelpartialfulfillment");
    }

    function sendPartialFulfillRequest() {
        const itemsToFulfill = fulfillGroup.length
            ? fulfillLineItems.filter((item, index) => fulfillGroup.includes(index))
            : null;

        let remainingItems = items.map((obj) => ({ ...obj }));
        remainingItems = remainingItems.filter((item) => {
            const removeItem = itemsToFulfill.find((removeItem) => removeItem.id === item.id);
            if (removeItem) {
                if (item.quantity === removeItem.quantity) {
                    return false;
                } else {
                    item.quantity -= removeItem.quantity;
                    item.net_amount = item.unit_price * item.quantity;
                    item.tax_amount = item.net_amount * item.tax_rate;
                    item.gross_amount = item.net_amount + item.tax_amount;
                }
            }
            return true;
        });

        modalState.actions.setModal("FULFILL_ORDER", {
            orderID: order.orderID,
            merchantOrderID: order.merchantOrderID,
            merchantAdditionalInfo: order.merchantAdditionalInfo,
            buyerName: order.buyerName,
            lang: COUNTRIES[order.country]?.languageCode || LanguageCode.EN,
            isPartial: true,
            currency,
            billingAddress,
            shippingAddress,
            remainingItems,
            lineItems: itemsToFulfill
        });
    }

    function formatProductValue(product: IOrderItem, index, column) {
        const rawValue = column.propertyName ? product[column.propertyName] : index + 1;
        switch (column.type) {
            case DATA_TYPE.AMOUNT:
                return formatAmount(rawValue);
            case DATA_TYPE.PERCENT:
                return formatPercent(rawValue);
            default:
                return rawValue;
        }
    }

    function formatAmount(amount: number | string) {
        return utils.formatCurrency(currency)(amount, { locale });
    }

    function formatPercent(aNumber: number | string) {
        return (typeof aNumber === "string" ? parseFloat(aNumber) : aNumber).toLocaleString("en", {
            style: "percent"
        });
    }

    function calculateAmount(columnType: string, product: IOrderItem, index, event) {
        const itemIndex = errorElements.indexOf(index);
        if (itemIndex !== -1) {
            errorElements.splice(itemIndex, 1);
        }

        if (errorElements.length === 0) {
            displayTableError = false;
        }

        const selecteditem: IOrderItem = items.find(
            (orderItem) =>
                orderItem.description === product.description &&
                orderItem.unit_price === product.unit_price
        );
        if (columnType === "quantity") {
            const quantity = event.detail.value;
            product.quantity = Number(quantity);
            product.net_amount = Number(
                (quantity * product.unit_price - product.discount_amount).toFixed(2)
            );
            product.tax_amount = Number((product.net_amount * product.tax_rate).toFixed(2));
            product.gross_amount = Number((product.net_amount + product.tax_amount).toFixed(2));
            fulfillLineItems = fulfillLineItems;

            if (quantity > selecteditem.quantity || quantity <= 0) {
                tableErrorText =
                    quantity > selecteditem.quantity
                        ? $_("order.partialFulfil.quantity_exceeding_error")
                        : $_("order.partialFulfil.select_quantity_error");
                displayTableError = true;
                errorElements.push(index);
                errorElements = errorElements;
            }
        }

        setPartialFulfillAmount();
    }
</script>

<div class="scrollbar">
    <div class="main-bar">
        <span class="card-title">{title}</span>
    </div>

    <div class="product-table-wrap">
        <table class="table">
            <thead class="table-head">
                <th class="table-header table-header--left checkbox-col" style="width: 50px">
                    <input
                        type="checkbox"
                        id="all-products"
                        name="all-products"
                        class="product-checkbox"
                        bind:checked={allProductsChecked}
                        on:click={toggleAllProductItems}
                        disabled={disabledItemCheckbox}
                    />
                </th>
                {#each columns as column}
                    <th
                        class="table-header table-header--{column.alignment}"
                        class:extend-margin={column.label === $_("order.edit.invoice_number") &&
                            fulfillLineItems.length > 1}
                        style="width: {column.width}"
                    >
                        {@html column.label}
                    </th>
                {/each}
            </thead>
            {#if displayTableError}
                <tr class="header-error-row">
                    <td colspan="9">
                        <div class="flex px-5 gap-2">
                            <Icon name="cross-circle" size={22} fill="#912018" />
                            {tableErrorText}
                        </div>
                    </td>
                </tr>
            {/if}
            {#each fulfillLineItems as item, index}
                <tr class="table-row">
                    <td class="table-cell table-cell--left checkbox-col">
                        <input
                            type="checkbox"
                            value={index}
                            id={`line_item_checkbox_${index}`}
                            name="fulfillGroup"
                            on:change={setPartialFulfillAmount}
                            bind:group={fulfillGroup}
                            class="product-checkbox"
                        />
                    </td>
                    {#each columns as column}
                        {#if column.propertyName === "quantity" && fulfillGroup.includes(index)}
                            <td
                                class="table-cell table-cell--{column.alignment}"
                                class:table-cell--bold={column.bold}
                            >
                                <Input
                                    type="text"
                                    value={item[column.propertyName]}
                                    placeholder="0"
                                    on:change={(event) =>
                                        calculateAmount(column.propertyName, item, index, event)}
                                    showInvalidError={true}
                                    invalid={errorElements.includes(index) ? " " : ""}
                                />
                            </td>
                        {:else}
                            <td
                                class="table-cell table-cell--{column.alignment}"
                                class:table-cell--bold={column.bold}
                                class:table-cell--editable={column.propertyName === "quantity"}
                            >
                                {formatProductValue(item, index, column)}
                            </td>
                        {/if}
                    {/each}
                </tr>
            {/each}
            <tr>
                <td colspan="6" />
                <td class="table-cell summary-cell table-cell--right"
                    >{$_("order.partialFulfil.subtotal")}</td
                >
                <td class="summary-cell" />
                <td class="table-cell summary-cell table-cell--right">
                    {formatAmount(netAmount)}
                </td>
            </tr>
            <tr>
                <td colspan="6" />
                <td class="table-cell summary-cell table-cell--right">{taxNaming}</td>
                <td class="table-cell summary-cell table-cell--right" />
                <td class="table-cell summary-cell table-cell--right table-cell">
                    {formatAmount(taxAmount)}
                </td>
            </tr>
            <tr>
                <td colspan="6" />
                <td class="table-cell summary-cell table-cell--right table-cell">
                    {$_("order.partialFulfil.total_to_be_invoiced")}
                </td>
                <td class="summary-cell" />
                <td class="table-cell summary-cell table-cell--right table-cell table-cell--bold">
                    {formatAmount(grossAmount)}
                </td>
            </tr>
            <tr>
                <td colspan="6" />
                <td class="table-cell summary-cell table-cell--right table-cell table-cell--bold"
                    >{$_("order.partialFulfil.total_remaining")}</td
                >
                <td class="summary-cell" />
                <td class="table-cell summary-cell table-cell--right table-cell table-cell--bold">
                    {formatAmount(Number(order.grossAmount) - fulfilledAmount - grossAmount)}
                </td>
            </tr>
        </table>
    </div>
    <div class="fulfill-buttons-area-container">
        <button class="button button--fulfill-cancel" on:click={resetPartialFulfillProcess}
            >{$_("components.cancel")}</button
        >
        <button
            class="button button--fulfill-full{disabledFulfilledClass}"
            id="partial_fulfill_order"
            on:click={sendPartialFulfillRequest}
            disabled={disabledFulfilledClass !== "" || displayTableError}
        >
            {$_("order.partialFulfil.send_invoice", {
                values: { amount: formatAmount(grossAmount) }
            })}
        </button>
    </div>
</div>

<style lang="scss">
    .card {
        flex-basis: 100%;
        border-radius: 0.5rem;
        background-color: var(--basic);
        padding: 1.5rem;
        --tw-bg-opacity: 1;

        border: 1px solid var(--gray-100);
        box-shadow: 0 0 6px rgba(208, 213, 221, 0.25);

        &--table {
            padding: 0;
        }
    }

    .scrollbar {
        overflow: auto;
    }

    .card-title {
        display: flex;
        gap: 10px;
        font-weight: 700;
        line-height: 24px;
        padding: 24px;
    }

    .product-table-wrap {
        width: 100%;
    }

    .main-bar {
        display: flex;
        justify-content: space-between;
    }

    .product-checkbox {
        height: 1rem;
        width: 1rem;
        margin-right: 1rem;
    }

    .table {
        width: 100%;
        min-width: 930px;
        table-layout: fixed;
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-700);
        margin-bottom: 24px;

        border-collapse: collapse;
        border-spacing: unset;
    }

    th,
    td {
        cursor: default;
    }

    .table-head {
        background-color: var(--bgGeneralQuarterary);
        color: var(--ctnGeneralSecondary);
    }

    .table-header {
        width: 100%;
        padding: 0 8px;
        height: 48px;

        &:first-child {
            padding-left: 24px;
        }

        &.extend-margin {
            padding-left: 56px;
        }

        &:last-child {
            padding-right: 24px;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }
    }

    .table-row {
        border-bottom: 1px solid var(--gray-200);
        height: 76px;
    }

    .checkbox-col {
        padding-top: 5px;
    }

    .table-cell {
        padding: 0 8px;
        font-weight: 400;

        &:first-child {
            padding-left: 24px;
        }

        &:last-child {
            padding-right: 24px;
        }

        &--center {
            text-align: center;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }

        &--bold {
            font-weight: 700;
            color: var(--gray-800);
        }

        &--editable {
            padding-right: 1.5rem;
        }
    }

    .summary-cell {
        border-bottom: 1px solid var(--gray-200);
    }

    .header-error-row {
        color: #912018;
        background-color: #fef3f2;
        height: 2.5rem;
        padding: 0.5rem;
    }

    .fulfill-buttons-area-container {
        display: flex;
        gap: 8px;
        margin-left: auto;
        float: right;
        padding-right: 24px;
        padding-bottom: 24px;
    }

    .button {
        display: flex;
        gap: 10px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 10px 20px;
        height: 40px;

        &--fulfill-cancel {
            color: var(--gray-700);
            background-color: var(--basic);
            border-color: var(--gray-300);
            border-style: solid;
            border-width: 1px;
        }

        &--fulfill-cancel:hover {
            background-color: var(--gray-300);
        }

        &--fulfill-full {
            color: var(--basic);
            background-color: var(--primary-600);
            min-width: 152px;
        }

        &--fulfill-full-disabled {
            color: var(--basic);
            background-color: var(--primary-200);
        }
    }

    * :global(.incorrect) {
        border-radius: 8px;
        border: 1px solid var(--error-400, #f97066);
        background: var(--basic-white, #fff);
        box-shadow:
            0px 0px 0px 4px #fee4e2,
            0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    * :global(.input_wrapper > input) {
        text-align: right;
        padding-right: 1rem;
    }

    * :global(.table-cell .input_wrapper) {
        margin-top: 0;
    }
</style>
