<script lang="ts">
    import { _ } from "svelte-i18n";
    import Modal from "@/components/Elements/Modal.svelte";
    import { Button, Tooltip } from "@two-ui/core";
    import Fa from "svelte-fa";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

    import API from "@/services/apiService";
    import { GET_CUSTOMER_BETA } from "@/static/endPoints";
    import notificationState from "@/store/notifications.store";
    import { NotificationType } from "@/static/types";
    import Radio from "@/components/Elements/Radio.svelte";
    import CheckboxItem from "@/components/checkboxitem/CheckboxItem.svelte";

    export let showBlockModal = false;

    let fraudType = { value: "", label: "" };
    let selectedValues: string[] = [];
    let comments = "";
    let isLoading = false;
    let isSubmitted = false;

    const RADIO_OPTIONS = [
        {
            value: "first_party",
            label: $_("riskManagement.fraud.firstParty")
        },
        {
            value: "third_party",
            label: $_("riskManagement.fraud.thirdParty")
        }
    ];

    const CHECKBOX_OPTIONS = [
        { value: "all", label: $_("riskManagement.fraud.all") },
        { value: "phone", label: $_("riskManagement.fraud.phone") },
        { value: "email", label: $_("riskManagement.fraud.email") },
        { value: "organization", label: $_("riskManagement.fraud.organizationNumber") }
    ];

    function toggleSelection(value: string) {
        if (value === "all") {
            selectedValues = selectedValues.includes(value)
                ? []
                : CHECKBOX_OPTIONS.map((item) => item.value);
        } else {
            const isSelected = selectedValues.includes(value);
            selectedValues = isSelected
                ? selectedValues.filter((item) => item !== value && item !== "all")
                : [...selectedValues, value];

            // Automatically select "all" if all other options are selected
            if (selectedValues.length === CHECKBOX_OPTIONS.length - 1 && !isSelected) {
                selectedValues = [...selectedValues, "all"];
            }
        }
    }

    function isFormValid() {
        isSubmitted = true;
        console.log("Reason:", fraudType.value);
        console.log("Selected Values:", selectedValues);
        console.log("Comments:", comments);
        return Boolean(fraudType.value) && selectedValues.length && Boolean(comments);
    }

    function onBlockRequested() {
        if (!isFormValid()) return;

        isLoading = true;
        API.get(GET_CUSTOMER_BETA)
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("riskManagement.fraud.blockSuccess"),
                    null,
                    $_("riskManagement.fraud.blockSuccessText")
                );
            })
            .catch((err) => {
                const errorMessage =
                    err.response?.data?.error_message || $_("errors.somethingWrong");
                notificationState.actions.create(NotificationType.ERROR, errorMessage);
            })
            .finally(() => {
                isLoading = false;
                showBlockModal = false;
            });
    }

    function resetModal() {
        fraudType = { value: "", label: "" };
        selectedValues = [];
        comments = "";

        showBlockModal = false;
    }
</script>

<Modal
    title={$_("riskManagement.fraud.blockCustomer")}
    bind:show={showBlockModal}
    on:close={resetModal}
>
    <!-- Body Description -->
    <p class="mb-4 leading-relaxed">
        {$_("riskManagement.fraud.blockCustomerText")}
    </p>

    <!-- Reason Section -->
    <div class="mb-6">
        <h3 class="text-sm font-medium mb-2 flex items-center">
            <span class="mr-2">{$_("riskManagement.fraud.fraudType")}</span>
            <Tooltip
                placement="center"
                position="right"
                wrapLines
                width={276}
                label={$_("riskManagement.fraud.blockTooltip")}
            >
                <Fa size="md" icon={faCircleQuestion} color="var(--primary-500)" />
            </Tooltip>
        </h3>
        <div class="grid grid-cols-2 gap-2">
            {#each RADIO_OPTIONS as option}
                <Radio bind:group={fraudType} {option} id={option.value} />
            {/each}
        </div>
        {#if isSubmitted && !fraudType.value}
            <p class="text-Content-Semantic-Error-Primary text-sm">{$_("errors.fieldRequired")}</p>
        {/if}
    </div>

    <!-- Select Values to Block -->
    <div class="mb-6">
        <h3 class="text-sm font-medium mb-3">{$_("riskManagement.fraud.selectValuesToBlock")}</h3>
        <div class="grid grid-cols-2 gap-2">
            {#each CHECKBOX_OPTIONS as item}
                <CheckboxItem
                    statusItem={item}
                    isChecked={selectedValues.includes(item.value) ||
                        selectedValues.includes("all")}
                    on:checkedItem={() => toggleSelection(item.value)}
                />
            {/each}
        </div>
        {#if isSubmitted && !selectedValues.length}
            <p class="text-Content-Semantic-Error-Primary text-sm">{$_("errors.fieldRequired")}</p>
        {/if}
    </div>

    <!-- Comments Section -->
    <div class="mb-6">
        <h3 class="text-sm font-medium mb-2">{$_("riskManagement.fraud.comments")}</h3>
        <textarea
            id="block-comment"
            bind:value={comments}
            rows="4"
            class:border-Content-Semantic-Error-Primary={isSubmitted && !comments}
            placeholder={$_("components.descriptionPlaceholder")}
            class="w-full border rounded-sm p-2 text-sm"
        ></textarea>
        {#if isSubmitted && !comments}
            <p class="text-Content-Semantic-Error-Primary text-sm">{$_("errors.fieldRequired")}</p>
        {/if}
        <div class="text-Content-General-Tertiary text-sm font-normal">
            *{$_("errors.allRequired")}
        </div>
    </div>

    <!-- Footer Buttons -->
    <div class="flex justify-end gap-2 mt-6" slot="footer">
        <Button
            class="mr-2"
            size="md"
            variant="secondaryGray"
            on:click={resetModal}
            content={$_("components.cancel")}
        />
        <Button
            id="block-btn"
            size="md"
            on:click={onBlockRequested}
            variant="destructivePrimary"
            disabled={isLoading}
            content={isLoading ? $_("components.loading") : $_("riskManagement.fraud.block")}
        />
    </div>
</Modal>
