<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";

    import API from "../../../services/apiService";
    import LoadingWrapper from "../../../components/Elements/LoadingWrapper.svelte";
    import Accordion from "../../../components/Elements/Accordion.svelte";
    import SingleBillingAccountView from "./SingleBillingAccountView.svelte";
    import SingleBillingAccountEdit from "./SingleBillingAccountEdit.svelte";
    import { adaptBillingAccountsToUI, type BillingAccountUI } from "./helpers";
    import { BASE_URL } from "@/static/endPoints";
    import type { BillingAccount } from "../../../static/types";

    export let id;

    const searchParams = new URLSearchParams(window.location.search);
    const buyerOrgNumber = id;
    const buyerCountryCode = searchParams.get("country");

    let loadingBillingAccounts: boolean = false;
    let billingAccounts: Array<BillingAccountUI> = [];
    let openedBillingAccountsIds = [];
    let billingAccountsCopy = [];

    function onToggleAccordionAccount(id) {
        if (openedBillingAccountsIds.includes(id)) {
            openedBillingAccountsIds = openedBillingAccountsIds.filter((item) => item !== id);
        } else {
            openedBillingAccountsIds = [...openedBillingAccountsIds, id];
        }
    }

    function onCancelEditing(id) {
        billingAccounts = billingAccounts.map((account, index) => {
            if (account.id === id) return billingAccountsCopy[index];
            return account;
        });
    }

    $: if (!billingAccountsCopy.length && billingAccounts.length) {
        billingAccountsCopy = JSON.parse(JSON.stringify(billingAccounts));
    }

    async function fetchBillingAccounts() {
        loadingBillingAccounts = true;
        API.get(
            `${BASE_URL}/v1/portal/merchant/billing/accounts?country_code=${buyerCountryCode}&organization_number=${buyerOrgNumber}`
        )
            .then((response: { data: Array<BillingAccount> }) => {
                billingAccounts = adaptBillingAccountsToUI(response.data, $_);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                loadingBillingAccounts = false;
            });
    }

    onMount(async () => {
        await fetchBillingAccounts();
    });
</script>

<div class="gcard">
    <h1 class="page-title">{$_("customer.billingAccount")}</h1>
    <p class="description mb-6">
        {$_("customer.billingAccountDescription")}
    </p>
    {#if loadingBillingAccounts}
        <div><LoadingWrapper loading={true} /></div>
    {:else}
        {#each billingAccounts as billingAccount}
            <Accordion
                title={billingAccount.name}
                alwaysOpened={billingAccounts.length === 1}
                isActive={openedBillingAccountsIds.includes(billingAccount.id)}
                onToggle={() => onToggleAccordionAccount(billingAccount.id)}
                onCancel={() => onCancelEditing(billingAccount.id)}
            >
                <SingleBillingAccountView slot="view" account={billingAccount} />
                <SingleBillingAccountEdit
                    slot="edit"
                    account={billingAccount}
                    {fetchBillingAccounts}
                />
            </Accordion>
        {/each}

        {#if !billingAccounts.length}
            <p>{$_("customer.noBillingAccounts")}</p>
        {/if}
    {/if}
</div>

<style>
    .gcard {
        padding: 1.5rem;
    }
    .description {
        font-size: 14px;
        color: var(--gray-700);
    }
</style>
