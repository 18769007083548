export function scrollToFirstVisibleError() {
    // Define the selectors to check in order of priority
    const selectors = [
        ".with-error",
        ".svelteui-Input-invalid",
        '.error:not([hidden]):not([style*="display: none"]):not(:empty)'
    ];

    let firstVisibleErrorElem = null;

    // Iterate through the selectors and find the first matching element
    for (const selector of selectors) {
        firstVisibleErrorElem =
            selector === ".svelteui-Input-invalid"
                ? document.querySelector(selector)?.parentElement?.parentElement
                : document.querySelector(selector);
        if (firstVisibleErrorElem) {
            break; // Stop checking once an element is found
        }
    }

    // Scroll to the first visible error element if found
    if (firstVisibleErrorElem) {
        // Get the height of the fixed header
        const header = document.querySelector("header"); // Adjust selector to match your header
        const headerHeight = header ? header.offsetHeight : 0;

        // Calculate the top position accounting for the header height
        const heightOfTheElem = 74;
        const elementTop = firstVisibleErrorElem.getBoundingClientRect().top + window.scrollY;
        const offsetTop = elementTop - headerHeight - heightOfTheElem;

        // Smooth scroll to the adjusted position
        window.scrollTo({
            top: offsetTop,
            behavior: "smooth"
        });
    }
}
