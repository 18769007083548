/* eslint-disable */
// NEED A VOLUNTEER TO REMOVE THIS AND CLEAN UP THIS FILE!

import axios from "axios";
import type { AxiosRequestConfig } from "axios";
import { getBaseUrl } from "../utils/functions";
import { getFingerprintHeaders } from "./fingerprint";

// Create a instance of axios to use the same base url.
// axios.defaults.withCredentials = true;
// const portalApi = axios.create({
//   withCredentials: true,
//   baseURL: BASE_URL,
// });

export const searchApi = axios.create({
    withCredentials: false,
    baseURL: getBaseUrl()
});

// Create a instance of axios to use the same base url.
const portalAPI = () => {
    let baseUrl = getBaseUrl();
    return axios.create({
        baseURL: baseUrl,
        withCredentials: true
    });
};

// Create a instance of axios to use the same base url without credential.
const generalAPI = () => {
    let baseUrl = getBaseUrl();
    return axios.create({
        baseURL: baseUrl,
        withCredentials: false
    });
};

// implement a method to execute all the request from here.

const apiRequest = ({ method, url, data, headers }: AxiosRequestConfig) => {
    //check if the url is for the merchant portal to set the cookie
    headers = {
        ...headers,
        "Access-Control-Allow-Origin": "*"
    };
    if (
        url.indexOf("v1/portal/merchant") !== -1 ||
        url.indexOf("v1/portal/buyer/") !== -1 ||
        url.indexOf("v1/portal/logout") !== -1 ||
        url.indexOf("v1/monboarding") !== -1 ||
        url.indexOf("v1/get_svix_app_portal_url") !== -1 ||
        url.indexOf("v2/portal/merchant") !== -1 ||
        url.indexOf("billing/v1") !== -1 ||
        url.indexOf("v1/portal/mi/order") !== -1 ||
        url.indexOf("/v1/mi/orders") !== -1 ||
        url.indexOf("/v1/order/") !== -1 ||
        url.indexOf("/v1/portal/merchant/buyer_credit_limit") !== -1 ||
        url.indexOf("/v1/portal/merchant/risk_metrics/overview") !== -1 ||
        url.indexOf("/v1/invoice/") !== -1 ||
        url.indexOf("/merchant/recourse_limit") ||
        url.indexOf("/merchant/buyer_recourse_limit")
    ) {
        return new Promise((resolve, reject) => {
            getFingerprintHeaders()
                .then((fingerprintHeaders) => {
                    if (fingerprintHeaders) {
                        headers = { ...headers, ...fingerprintHeaders };
                    }

                    return portalAPI()({ method, url, data, headers });
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err instanceof Error ? err : new Error(err));
                });
        });
    }
    //using the axios instance to perform the request that received from each http method
    return new Promise((resolve, reject) => {
        getFingerprintHeaders()
            .then((fingerprintHeaders: { [key: string]: string }) => {
                if (fingerprintHeaders) {
                    headers = { ...headers, ...fingerprintHeaders };
                }

                return generalAPI()({ method, url, data, headers });
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err instanceof Error ? err : new Error(err));
            });
    });
};

// function to execute the http get request
const get = (url: string, data?: any, headers?: any) =>
    apiRequest({ method: "get", url, data, headers });

// function to execute the http delete request
const deleteRequest = (url: string, data: any) => apiRequest({ method: "delete", url, data });

// function to execute the http post request
const post = (url: string, data?: any, headers?: any) =>
    apiRequest({ method: "post", url, data, headers });

// function to execute the http put request
const put = (url: string, data: any) => apiRequest({ method: "put", url, data });

// function to execute the http path request
const patch = (url: string, data: any) => apiRequest({ method: "patch", url, data });

// expose your method to other services or actions
const API = {
    get,
    delete: deleteRequest,
    post,
    put,
    patch
};

export default API;
