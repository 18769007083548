<script lang="ts">
    import { createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher();

    export let statusItem;
    export let isChecked;
    export let checkedEventName = "checkedItem";
    export let isInvalid = false;

    function itemChecked(event) {
        dispatch(checkedEventName, {
            checkedItem: statusItem.value,
            checkedItemKey: statusItem.name,
            checked: event.target.checked
        });
    }
</script>

<div class="checkbox-container">
    <label class="container" for={`checkbox-item-${statusItem.value}`}
        >{statusItem.label || statusItem.value}
        <input
            id={`checkbox-item-${statusItem.value}`}
            class="checkmark"
            type="checkbox"
            name={statusItem.name}
            value={statusItem.value}
            bind:checked={isChecked}
            on:change={itemChecked}
        />
        <span class="checkmark" class:is-invalid={isInvalid} />
    </label>
</div>

<style>
    .checkbox-container {
        display: flex;
        height: 1.5rem;
        gap: 0.5rem;
    }
    .container {
        color: #344054;
        position: relative;
        padding-left: 1.4rem;
        display: flex;
        align-items: center;
        line-height: 16px;
        height: 16px;
    }

    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        border-radius: 4px;
        &.is-invalid {
            border-color: var(--error-300);
        }
    }

    .container:hover input ~ .checkmark {
        border: 1px solid var(--ctnGeneralSecondary);
    }

    .container input:checked ~ .checkmark {
        background: #eef4ff;
        border: 1px solid var(--borderGeneralSecondary);
        border-radius: 4px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .container input:checked ~ .checkmark:after {
        display: block;
    }

    .container .checkmark:after {
        left: 4.5px;
        top: 1px;
        width: 5px;
        height: 9px;
        border: solid var(--ctnGeneralSecondary);
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
</style>
