<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import { Button } from "@two-ui/core";

    import Badge from "../../../components/Elements/Badge.svelte";
    import PayoutsTable from "../../../components/table/Table.svelte";
    import { client } from "../../../services/http/client";
    import {
        GET_RECENT_PAYOUTS,
        GET_SETTLEMENT_REPORT_BY_SETTLEMENT_ID
    } from "../../../static/endPoints";
    import { DateToString } from "../../../utils/functions";
    import utils from "../../../utilsV2/currency";
    import CustomSettlementReportModal from "../../../components/modals/CustomSettlementReportModal.svelte";
    import type { _URL } from "../../../services/http/config";
    import { getFingerprintHeaders } from "@/services/fingerprint";

    export let showKredinorView = false;

    let payouts = [];
    let page = {
        current: 1,
        limit: 15,
        items: undefined
    };
    let tableRows;
    let loading = false;
    let showCustomReportModal = false;
    let ref: HTMLDivElement;
    let refY: number;

    onMount(setupComponent);

    $: tableHeaders = showKredinorView
        ? [
              {
                  title: "Estimated arrival",
                  translation: $_("payouts.recent.table.estimated_arrival")
              },
              { title: "Paid from", translation: $_("payouts.recent.table.paid_from") },
              {
                  title: "Settlement number",
                  translation: $_("payouts.recent.table.settlement_number")
              },
              {
                  title: "Amount",
                  type: "right-align",
                  translation: $_("payouts.recent.table.amount")
              },
              {
                  title: "Download report",
                  type: "right-align",
                  translation: $_("payouts.recent.table.download_report")
              }
          ]
        : [
              {
                  title: "Estimated arrival",
                  translation: $_("payouts.recent.table.estimated_arrival")
              },
              // temporarily hidden until "all" payout account country code supported
              // { title: "Paid to", translation: $_("payouts.recent.table.paid_to") },
              { title: "Paid from", translation: $_("payouts.recent.table.paid_from") },
              {
                  title: "Payment reference",
                  translation: $_("payouts.recent.table.payment_reference")
              },
              {
                  title: "Amount",
                  type: "right-align",
                  translation: $_("payouts.recent.table.amount")
              },
              {
                  title: "Download report",
                  type: "right-align",
                  translation: $_("payouts.recent.table.download_report")
              }
          ];

    $: getRecentPayouts(showKredinorView);

    // temporarily hidden until "all" payout account country code supported
    // function getRecipientBankDetails(payout): string | undefined {
    //   let result: string | undefined;
    //   if (payout.recipient_account_number) {
    //     result = `Account number: ${payout.recipient_account_number}`;
    //   } else if (payout.recipient_bban) {
    //     result = `BBAN: ${payout.recipient_bban}`;
    //   } else if (payout.recipient_iban) {
    //     result = `IBAN: ${payout.recipient_iban}`;
    //   }
    //   return result;
    // }

    function buildPayoutsTable(payouts, showKredinorView: boolean) {
        return showKredinorView
            ? buildPayoutsTableKredinor(payouts)
            : buildPayoutsTableDefault(payouts);
    }

    function buildPayoutsTableKredinor(payouts) {
        const rows = [];
        for (const payout of payouts) {
            // show "Details pending" if data incomplete, "N/A" for sample row
            const fallback = payout.settlement_id ? $_("payouts.recent.details_pending") : "N/A";
            const row = {
                "Estimated arrival": {
                    tableItem: payout.settlement_date
                        ? DateToString(new Date(payout.settlement_date))
                        : fallback
                },
                "Paid from": {
                    tableItem: payout.funding_provider_account_holder_name || fallback,
                    subItem: payout.funding_provider_account_number
                        ? `${$_("payouts.recent.accountNumber")} ${
                              payout.funding_provider_account_number
                          }`
                        : fallback
                },
                "Settlement number": {
                    tableItem: payout.payment_reference || fallback
                },
                Amount: {
                    tableItem: payout.payout_amount
                        ? utils.formatCurrency(payout.payout_currency)(payout.payout_amount)
                        : fallback
                },
                "Download report": {
                    icons: [
                        {
                            description: $_("payouts.recent.download_xlsx"),
                            iconSource: "/pictures/xlsx.svg",
                            link: GET_SETTLEMENT_REPORT_BY_SETTLEMENT_ID(
                                "xlsx",
                                payout.settlement_id
                            ),
                            noContainer: true
                        },
                        {
                            description: $_("payouts.recent.download_csv"),
                            iconSource: "/pictures/csv.svg",
                            link: GET_SETTLEMENT_REPORT_BY_SETTLEMENT_ID(
                                "csv",
                                payout.settlement_id
                            ),
                            noContainer: true
                        }
                    ]
                }
            };
            rows.push(row);
        }
        return rows;
    }

    function buildPayoutsTableDefault(payouts) {
        const rows = [];
        for (const payout of payouts) {
            // show "Details pending" if data incomplete, "N/A" for sample row
            const fallback = payout.settlement_id ? $_("payouts.recent.details_pending") : "N/A";
            const row = {
                "Estimated arrival": {
                    tableItem: payout.settlement_date
                        ? DateToString(new Date(payout.settlement_date))
                        : fallback
                },
                // temporarily hidden until "all" payout account country code supported
                // "Paid to": {
                //   tableItem: payout.recipient_account_name || fallback,
                //   subItem: getRecipientBankDetails(payout) || fallback
                // },
                "Paid from": {
                    tableItem: payout.funding_provider_account_holder_name || fallback,
                    subItem: payout.funding_provider_account_number
                        ? `${$_("payouts.recent.accountNumber")} ${
                              payout.funding_provider_account_number
                          }`
                        : fallback
                },
                "Payment reference": {
                    tableItem: payout.payment_reference || fallback
                },
                Amount: {
                    tableItem: payout.payout_amount
                        ? utils.formatCurrency(payout.payout_currency)(payout.payout_amount)
                        : fallback
                },
                "Download report": {
                    icons: [
                        {
                            description: $_("payouts.recent.download_xlsx"),
                            iconSource: "/pictures/xlsx.svg",
                            link: GET_SETTLEMENT_REPORT_BY_SETTLEMENT_ID(
                                "xlsx",
                                payout.settlement_id
                            ),
                            noContainer: true
                        },
                        {
                            description: $_("payouts.recent.download_csv"),
                            iconSource: "/pictures/csv.svg",
                            link: GET_SETTLEMENT_REPORT_BY_SETTLEMENT_ID(
                                "csv",
                                payout.settlement_id
                            ),
                            noContainer: true
                        }
                    ]
                }
            };
            rows.push(row);
        }
        return rows;
    }

    function handleDownloadCustomReportClick() {
        showCustomReportModal = true;
    }

    async function getRecentPayouts(showKredinorView: boolean) {
        loading = true;
        const { data, response } = await getFingerprintHeaders().then((headers) =>
            client.get(
                (GET_RECENT_PAYOUTS + `?page=${page.current}&page_size=${page.limit}`) as _URL,
                { headers }
            )
        );
        loading = false;
        if (response?.ok) {
            ({ items: payouts, page } = data);
            tableRows = buildPayoutsTable(payouts, showKredinorView);
        }
    }

    function scrollToTableTop() {
        window.scrollTo({
            top: refY,
            behavior: "smooth"
        });
    }
    async function setupComponent() {
        refY = ref.getBoundingClientRect().top + window.scrollY;
    }

    async function handlePageChange() {
        await getRecentPayouts(showKredinorView);
        scrollToTableTop();
    }
</script>

<div class="root gcard" bind:this={ref}>
    <div class="header">
        <div class="left">
            <h1 class="page-title">
                {$_("payouts.recent.recent_payouts_title")}
                <span class="badge-wrapper">
                    <Badge text={$_("payouts.recent.badge_text")} />
                </span>
            </h1>
            <h2>{$_("payouts.recent.recent_payouts_description")}</h2>
        </div>
        <div class="right" on:click|stopPropagation on:keyup>
            <Button
                on:click={handleDownloadCustomReportClick}
                size="sm"
                variant="secondaryGray"
                content={$_("payouts.recent.download_custom_report")}
                fullSize={true}
            />
        </div>
    </div>
    <div>
        {#if !loading && !payouts.length}
            <div class="error">{$_("payouts.recent.no_data_available")}</div>
        {:else}
            <PayoutsTable
                useBackendPagination
                numRows={page.limit}
                bind:pageNum={page.current}
                numAllRecords={page.items}
                {tableHeaders}
                {tableRows}
                {loading}
                on:pagechanged={handlePageChange}
                --cursor="default"
            />
        {/if}
    </div>
</div>
<CustomSettlementReportModal bind:show={showCustomReportModal} />

<style lang="scss">
    .root {
        padding-block-start: 20px;
        /* overwrite gcard margin */
        margin-block-start: 1rem;
        margin-block-end: 2rem;
        :global(.page-counter) {
            margin-block-start: 0.5rem;
        }
    }
    h1 {
        color: var(--gray-800);
    }
    h2 {
        color: var(--gray-800);
        font-size: 0.875rem;
        line-height: 1.4;
    }
    .header {
        display: flex;
        justify-content: space-between;
        margin-inline: 24px;
        margin-block-end: 1.25rem;
    }
    .error {
        font-size: 0.875rem;
        text-align: center;
    }
    .badge-wrapper {
        margin-inline-start: 8px;
    }
</style>
