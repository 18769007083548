<script lang="ts">
    import { _ } from "svelte-i18n";

    import Button from "../../../components/Elements/Button.svelte";
    import CardCentered from "../../../lib/components/common/CardCentered.svelte";
    import authenticationService from "@/services/authenticationService";

    export let buyerEmail: string;

    let code: string = "";
    let errorMessage: string = "";

    async function completeBuyerLogin() {
        const formValue = {
            verification_type: "EMAIL",
            code,
            verification_id: localStorage.getItem("verification_id")
        };

        authenticationService
            .completeVerification(formValue)
            .then((res: any) => {
                window.location.href = res.complete_url;
            })
            .catch((err) => {
                if (err.response.data.error_code === "VERIFICATION_NOT_FOUND") {
                    errorMessage = $_("login.codeMismatch");
                } else if (err.response.data.error_code === "ACCESS_DENIED") {
                    errorMessage = $_("login.accessDenied");
                } else {
                    errorMessage = $_("login.loginError");
                    console.error(err);
                }
                code = "";
            });
    }

    const clearErrorMessage = () => (errorMessage = "");
    const removeNonNumericValues = (e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""));
</script>

<CardCentered>
    <header>
        <h1>{$_("login.verifyEmail")}</h1>
        <p>{$_("login.enterCodeDescription")}</p>
    </header>
    <form on:submit|preventDefault={completeBuyerLogin} on:input={clearErrorMessage}>
        <p class="email">{buyerEmail}</p>
        <input
            id="otp-code"
            required
            type="text"
            minlength="6"
            maxlength="6"
            bind:value={code}
            on:input={removeNonNumericValues}
        />
        <p id="error-message">{errorMessage}</p>
        <div class="button-submit">
            <Button
                id="otp-code-btn"
                size="large"
                bgColor="var(--primary-600)"
                label="Submit code"
                disable={code.length != 6}
            />
        </div>
    </form>
</CardCentered>

<style lang="scss">
    header {
        text-align: center;

        h1 {
            font-size: 1.25rem;
            font-weight: 700;
        }

        p {
            margin-top: 1.25rem;
        }
    }

    form {
        margin-top: 1.5rem;

        .email {
            color: #667085;
            background-color: #f9fafb;
            width: fit-content;
            margin: auto;
            padding: 8px 14px;
            border: 1px solid #d0d5dd;
            border-radius: 16px;
        }

        $char-w: 1ch;
        $gap: 0.5 * $char-w;
        $n-char: 6;
        $in-w: $n-char * ($char-w + $gap);
        input {
            display: block;
            margin: auto;
            margin-top: 2rem;
            border: none;
            padding: 0;
            width: $in-w;
            background: repeating-linear-gradient(
                    90deg,
                    black 0,
                    black $char-w,
                    transparent 0,
                    transparent $char-w + $gap
                )
                0 100% / #{$in-w - $gap} 2px no-repeat;
            font:
                5ch "Aeonik-Mono",
                monospace;
            letter-spacing: $gap;

            &:focus {
                outline: none;
            }
        }

        #error-message {
            text-align: center;
            color: red;
            font-size: 0.875rem;
            font-weight: 500;
            height: 1.5rem;
        }
    }

    .button-submit {
        margin-top: 1rem;
    }
</style>
