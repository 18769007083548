<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Skeleton } from "@two-ui/core";

    import API from "@/services/apiService";
    import Search from "@/components/search/Search.svelte";
    import FraudInsightsFilter from "./FraudInsightsFilter.svelte";
    import FraudCard from "./FraudCard.svelte";
    import SingleFraudInsight from "./SingleFraudInsight.svelte";
    import NewPagination from "@/components/pagination/NewPagination.svelte";
    import {
        actions as fraudActions,
        fraudInsightsList,
        isLoading
    } from "@/store/merchant/fraudInsights.store";
    import { BASE_URL } from "@/static/endPoints";

    let searchValue: string = "";
    let filterQueryString: string = "";
    let singleInsightLoading = false;
    let selectedInsightId = "";
    let pageNum = 1;

    function setSearch(input: InputEvent | string): void {
        if (input instanceof InputEvent) {
            const inputE = input.target as HTMLInputElement;
            searchValue = inputE.value;
        } else {
            searchValue = input;
        }
        getInsights();
    }

    const filtersUpdated = (filterQuery: string) => {
        filterQueryString = filterQuery;
        getInsights();
    };

    function getInsights() {
        fraudActions.getFraudInsights(searchValue, filterQueryString, pageNum);
    }

    async function fetchSingleInsight(id) {
        selectedInsightId = id;

        singleInsightLoading = true;
        API.get(`${BASE_URL}/v1/portal/merchant`)
            .then((response) => {
                console.log("response", response);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                singleInsightLoading = false;
                setTimeout(adjustFraudColumns, 0);
            });
    }

    function adjustFraudColumns() {
        // Select the elements
        const rightColumn: HTMLDivElement = document.querySelector(".fraud-right-column");
        const leftColumn: HTMLDivElement = document.querySelector(".fraud-left-column");
        const pageHeader: HTMLDivElement = document.querySelector(".header_pb");
        const tableHeader: HTMLDivElement = document.querySelector(".table-header-bar");
        const fraudInsights = document.querySelector("#fraud-insights");
        const fraudInsightsStyles = window.getComputedStyle(fraudInsights);

        if (rightColumn && leftColumn && pageHeader && tableHeader && fraudInsights) {
            // Calc max height
            const maxHeight =
                window.innerHeight -
                pageHeader.offsetHeight -
                tableHeader.offsetHeight -
                parseInt(fraudInsightsStyles.marginTop) -
                parseInt(fraudInsightsStyles.marginBottom);

            // Set the max-height of the columns
            rightColumn.style.maxHeight = `${maxHeight}px`;
            leftColumn.style.maxHeight = `${maxHeight}px`;
        }
    }

    // on pagination change
    $: if (pageNum) {
        getInsights();
    }

    $: if ($fraudInsightsList && $fraudInsightsList.length) {
        fetchSingleInsight($fraudInsightsList[0].uuid);
    }

    function onItemClick(id) {
        fetchSingleInsight(id);
    }
</script>

<section id="fraud-insights" class="rounded-lg bg-white mx-8 mt-6 mb-8">
    <div
        class="table-header-bar flex justify-between items-center content-center border-b border-Border-General-Primary"
    >
        <div>
            <p class="table-title text-lg font-bold">
                {$_("riskManagement.fraud.title")}
            </p>
            <span class="">
                {$_("riskManagement.fraud.description")}
            </span>
        </div>
        <div class="w-[50%] flex gap-2">
            <Search bind:searchValue {setSearch} placeholder={$_("riskManagement.fraud.search")} />
            <FraudInsightsFilter updateTable={filtersUpdated} />
        </div>
    </div>
    <div class="flex flex-row">
        <!-- Left col -->
        <div
            class="fraud-left-column basis-1/3 relative max-h-screen overflow-y-scroll border-r border-Border-General-Primary"
        >
            {#if $isLoading}
                <div class="mx-6">
                    {#each Array(6) as _}
                        <Skeleton height={70} radius="md" class=" my-4" />
                    {/each}
                </div>
            {:else if !$fraudInsightsList.length}
                <div class="px-6 py-4">{$_("components.noItemsYet")}</div>
            {:else}
                {#each $fraudInsightsList as insight}
                    <FraudCard {insight} {selectedInsightId} onClick={onItemClick} />
                {/each}
            {/if}
            <NewPagination numAllRecords={50} numPages={5} bind:pageNum />
        </div>
        <!-- Right col -->
        <div class="fraud-right-column basis-2/3 max-h-screen overflow-y-scroll">
            <SingleFraudInsight isLoading={singleInsightLoading} />
        </div>
    </div>
</section>

<style lang="scss">
    .table-title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        color: var(--ctnGeneralPrimary);
    }
    .table-header-bar div {
        margin: 1.25rem 2rem 1.25rem 2rem;
    }
</style>
