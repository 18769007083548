import { capitalize } from "lodash";

import type { BillingAccountUI } from "./helpers";

export const LABEL_TO_VALUE_MAP = ($_) => ({
    tradeAccountName: $_("customer.accounts.tradeAccountName"),
    legalName: $_("customer.accounts.legalName"),
    accountCreatedOn: $_("customer.accounts.accountCreatedOn"),
    totalSpend: $_("customer.accounts.totalSpend"),
    estimatedMonthlySpend: $_("customer.accounts.estimatedMonthlySpend"),
    shippingAddress: $_("customer.accounts.shippingAddress"),
    verification: $_("customer.accounts.verification"),
    verificationProvider: $_("customer.accounts.verificationProvider"),

    billingAccountName: $_("customer.accounts.billingAccountName"),
    accountantEmailAddress: $_("customer.accounts.accountantEmailAddress"),
    ccEmailAddress: $_("customer.accounts.ccEmailAddress"),
    contactNumber: $_("customer.accounts.contactNumber"),
    currency: $_("customer.accounts.currency"),
    billingPeriod: $_("customer.accounts.billingPeriod"),
    statementWillBeIssued: $_("customer.accounts.statementWillBeIssued"),
    paymentTerms: $_("customer.accounts.paymentTerms"),
    lastOrderMadeOn: $_("customer.accounts.lastOrderMadeOn"),
    lastAcceptedOrderDate: $_("customer.accounts.lastAcceptedOrderDate")
});

// Billing Account
export const BILLING_ACCOUNT_CONFIG = [
    {
        key: "billingAccountName",
        dataKey: "name",
        inputType: "text",
        required: true,
        disabled: false,
        isFocused: false
    },
    {},
    {
        key: "accountantEmailAddress",
        dataKey: "accountantEmails",
        inputType: "multi-emails",
        required: false,
        disabled: false,
        isFocused: false,
        formatValue: (data: BillingAccountUI, $_): string => data.accountantEmails.join(", ")
    },
    {
        key: "ccEmailAddress",
        dataKey: "ccEmails",
        inputType: "multi-emails",
        required: false,
        disabled: false,
        isFocused: false,
        formatValue: (data: BillingAccountUI, $_): string => data.ccEmails.join(", ")
    },
    {
        key: "contactNumber",
        dataKey: "phone",
        inputType: "phone",
        required: false,
        disabled: false,
        isFocused: false
    },
    {
        key: "currency",
        dataKey: "currency",
        inputType: "text",
        required: false,
        disabled: true,
        isFocused: false,
        tooltipContent: ($_) => $_("account.billingPeriodTooltip")
    },
    {
        key: "billingPeriod",
        dataKey: "billingPeriod",
        inputType: "text",
        required: false,
        disabled: true,
        isFocused: false,
        formatValue: (data: BillingAccountUI) => capitalize(data.billingPeriod),
        tooltipContent: ($_) => $_("account.billingPeriodTooltip")
    },
    {
        key: "paymentTerms",
        dataKey: "paymentTerms",
        inputType: "number",
        required: false,
        disabled: false,
        isFocused: false,
        formatValue: (data: BillingAccountUI, $_) =>
            $_("netTerms.days", { values: { amount: data.paymentTerms || 0 } }),
        helperText: ($_) => $_("account.paymentTermsRange")
    },
    {
        key: "lastOrderMadeOn",
        dataKey: "lastOrderDate",
        inputType: "text",
        required: false,
        disabled: true,
        isFocused: false,
        tooltipContent: ($_) => $_("account.orderDateTooltip")
    },
    {}
];

// Trade Account
export const TRADE_ACCOUNT_CONFIG = [
    {
        key: "tradeAccountName",
        dataKey: "name",
        inputType: "text",
        required: false,
        disabled: false,
        isFocused: false
    },
    {},
    {
        key: "legalName",
        dataKey: "legalName",
        inputType: "text",
        required: false,
        disabled: false,
        isFocused: false
    },
    {
        key: "accountCreatedOn",
        dataKey: "createdAt",
        inputType: "text",
        required: false,
        disabled: true,
        isFocused: false,
        tooltipContent: ($_) => $_("account.itemCantBeEdited")
    },
    {
        key: "shippingAddress",
        dataKey: "shippingAddress",
        inputType: "text",
        required: false,
        disabled: true,
        isFocused: false,
        tooltipContent: ($_) => $_("account.itemCantBeEdited")
    },
    {}
];
