<script lang="ts">
    import { _ } from "svelte-i18n";
    import UserCreateUpdateForm from "./UserCreateUpdateForm.svelte";

    export let closeModal;
    export let props: any = {};
    const isUpdate = props?.id;
</script>

<div class="flex flex-col h-full w-full text-sm">
    <div class="flex flex-col header-text">
        <h1 class="ctn-general-primary font-bold text-lg sm:text-2xl">
            {isUpdate ? $_("components.editTeamMember") : $_("components.addTeamMember")}
        </h1>
    </div>
    <!-- divider -->
    <div class="border-t border-Border-General-Primary" />

    <div class="flex flex-col h-full w-full py-7 px-6 sm:py-6 sm:px-8">
        <div class="h-full grow">
            <UserCreateUpdateForm
                {props}
                submitText={isUpdate ? $_("components.update") : $_("components.add")}
                on:close={closeModal}
            />
        </div>
    </div>
</div>

<style>
    .header-text {
        padding: 1.5rem 2rem;
        padding-bottom: 1rem;
    }
</style>
