import environment from "@/services/environment";

const PURCHASE_DATE = "Purchase date";
const CUSTOMER_DETAILS = "Customer details";
const CUSTOMER_DETAILS_CLASS = "customer-details";

const tableHeadersAllOrders = [
    { title: PURCHASE_DATE, clickable: true, type: "date" },
    { title: "Order №", clickable: false, type: "number" },
    { title: "Invoice №", clickable: false, type: "number" },
    { title: "Order status", clickable: false, type: "status" },
    { title: CUSTOMER_DETAILS, clickable: false, type: CUSTOMER_DETAILS_CLASS },
    { title: "Amount", clickable: true, type: "number" }
];

const tableHeadersAllOrdersRecourse = getRecourseHeaders(tableHeadersAllOrders);

const tableHeadersFulfilledOrders = [
    { title: PURCHASE_DATE, clickable: true, type: "date" },
    { title: "Order №", clickable: false, type: "number" },
    { title: CUSTOMER_DETAILS, clickable: false, type: CUSTOMER_DETAILS_CLASS },
    { title: "Invoice №", clickable: false, type: "number" },
    { title: "Payment due date", clickable: false, type: "status" },
    { title: "Amount fulfilled", clickable: true, type: "number" }
];

const tableHeadersFulfilledOrdersRecourse = getRecourseHeaders(tableHeadersFulfilledOrders);

const tableHeadersRefundedOrders = [
    { title: PURCHASE_DATE, clickable: true, type: "date" },
    { title: "Order №", clickable: false, type: "number" },
    { title: "Latest refund date", clickable: false, type: "date" },
    { title: CUSTOMER_DETAILS, clickable: false, type: CUSTOMER_DETAILS_CLASS },
    { title: "Amount", clickable: true, type: "number" },
    { title: "Refunded", clickable: false, type: "number" }
];

const tableHeadersRefundedOrdersRecourse = getRecourseHeaders(tableHeadersRefundedOrders);

function getRecourseHeaders(headersArray) {
    var headers = [...headersArray];

    if (!environment.branding.hideRiskBearer) {
        headers.splice(headersArray.length - 1, 0, {
            title: "Risk bearer",
            clickable: false,
            type: "status",
            tooltip: true
        });
    }
    return headers;
}

export const TAB_ITEMS = {
    All: {
        tableHeaders: tableHeadersAllOrders,
        tableHeadersRecourse: tableHeadersAllOrdersRecourse,
        title: "All orders"
    },
    Pending: {
        tableHeaders: [
            { title: PURCHASE_DATE, clickable: true, type: "date" },
            { title: "Order №", clickable: false, type: "number" },
            { title: CUSTOMER_DETAILS, clickable: false, type: CUSTOMER_DETAILS_CLASS },
            { title: "Action required", clickable: false, tooltip: true },
            { title: "Amount", clickable: true, type: "number" }
        ],
        title: "Pending orders"
    },
    "Ready to fulfill": {
        tableHeaders: [
            { title: PURCHASE_DATE, clickable: true, type: "date" },
            { title: "Order №", clickable: false, type: "number" },
            { title: CUSTOMER_DETAILS, clickable: false, type: CUSTOMER_DETAILS_CLASS },
            { title: "Amount", clickable: true, type: "number" }
        ],
        title: "Ready to fulfill"
    },
    Fulfilled: {
        tableHeaders: tableHeadersFulfilledOrders,
        tableHeadersRecourse: tableHeadersFulfilledOrdersRecourse,
        title: "Fulfilled orders"
    },
    Refunded: {
        tableHeaders: tableHeadersRefundedOrders,
        tableHeadersRecourse: tableHeadersRefundedOrdersRecourse,
        title: "Refunded orders"
    },
    Cancelled: {
        tableHeaders: [
            { title: PURCHASE_DATE, clickable: true, type: "date" },
            { title: "Order №", clickable: false, type: "number" },
            { title: CUSTOMER_DETAILS, clickable: false, type: CUSTOMER_DETAILS_CLASS },
            { title: "Contact details", clickable: false },
            { title: "Amount", clickable: true, type: "number" }
        ],
        title: "Cancelled orders"
    }
};
