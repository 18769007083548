<script lang="ts">
    import { _ } from "svelte-i18n";
    import { navigate } from "svelte-routing";

    import {
        loading,
        searchFilter,
        statements,
        cursorPage
    } from "../../../../../store/merchant/billingStatement.store";
    import { account as merchantAccount } from "../../../../../store/merchant/account.store";
    import StatementTable from "../../../../../components/table/Table.svelte";
    import type { IStatementObject } from "./statementUtils";
    import Search from "../../../../../components/search/Search.svelte";
    import helpers from "../../../../../utilsV2/helpers";
    import BillingStatementFilter from "../../../../../components/invoices/filters/BillingStatementFilter.svelte";
    import StatementInfo from "../../../../../components/StatementsInfo/StatementInfo.svelte";

    export let rows: IStatementObject[] = [];
    let searchValue: string = "";
    let prevPage = "";
    let nextPage = "";
    const pageLimit = 25;
    let pageNum = 1;
    const imgSrc = "/pictures/mailboxstate.svg";
    let filterQueryStringParams = "";
    function setSearch(input: InputEvent | string): void {
        if (input instanceof InputEvent) {
            const inputE = input.target as HTMLInputElement;
            searchValue = inputE.value;
        } else {
            searchValue = input;
        }
        handleSearchChanged();
    }

    const handleSearchChanged = helpers.debounce(() => {
        statements.actions.getStatements(searchValue, null, null, filterQueryStringParams);
        pageNum = 1;
    }, 400);

    function pageUpdated(event) {
        statements.actions.getStatements(
            $searchFilter,
            event.detail.pageReference,
            null,
            filterQueryStringParams
        );
        pageNum = 1;
    }
    const filtersUpdated = (filterQueryString: string) => {
        filterQueryStringParams = filterQueryString;
        statements.actions.getStatements($searchFilter, null, null, filterQueryStringParams);
        pageNum = 1;
    };

    $: if ($cursorPage) {
        prevPage = $cursorPage.previous_page_cursor;
        nextPage = $cursorPage.next_page_cursor;
    }

    const tableHeaders = [
        {
            title: "Issue date",
            translation: $_("statements.table.issueDate"),
            clickable: false,
            type: "statement_date"
        },
        {
            title: "Statement №",
            translation: $_("statements.table.statementNumber"),
            clickable: false,
            type: "statement_number"
        },
        {
            title: "Customer details",
            translation: $_("statements.table.customerDetails"),
            clickable: false,
            type: "customer_details"
        },
        {
            title: "Amount",
            translation: $_("statements.table.amount"),
            clickable: false,
            type: "gross_amount",
            tooltip: $_("statements.totalAmountTooltip")
        },
        { title: "Tags", translation: $_("statements.table.tags"), clickable: false, type: "tags" },
        {
            title: "Download",
            translation: $_("statements.table.download"),
            clickable: false,
            notext: true,
            type: "actions"
        }
    ];

    $: merchantHasBillingAccounts = $merchantAccount?.flags?.has_billing_accounts;

    function itemSelected(event, tableRow) {
        const url = `/merchant/customers/billing/${tableRow.billingAccountId}/period/${tableRow.periodId}`;
        if (event.metaKey) {
            window.open(url, "_blank");
        } else {
            navigate(url);
        }
    }
</script>

{#if $loading || merchantHasBillingAccounts}
    <div class="table-header-bar">
        <div class="table-title">{$_("statements.table.allStatementsTitle")}</div>
        <div class="search">
            <Search
                id="search-statement"
                disabled={$loading}
                bind:searchValue
                {setSearch}
                placeholder={$_("statements.table.searchPlaceholder")}
            />
            <BillingStatementFilter updateTable={filtersUpdated} />
        </div>
    </div>
{/if}

{#if $loading || (rows.length > 0 && merchantHasBillingAccounts)}
    <StatementTable
        id="statements-table"
        {tableHeaders}
        bind:tableRows={rows}
        bind:numAllRecords={rows.length}
        on:pagechanged={pageUpdated}
        loading={$loading}
        numRows={pageLimit}
        useCursorPagination={true}
        cursorNextPage={nextPage}
        cursorPrevPage={prevPage}
        rowDescription={$_("statements.title")}
        {itemSelected}
        bind:pageNum
    />
{:else if rows.length == 0 && merchantHasBillingAccounts}
    <div class="w-full h-48 flex flex-col items-center pt-8">
        <img src={imgSrc} alt="mailbox" />
        <p class="my-4 font-semibold text-black-dark text-center">
            {$_("statements.table.noStatementsMessage")}
        </p>
    </div>
{:else if !$loading && !merchantHasBillingAccounts}
    <div>
        <StatementInfo />
    </div>
{/if}

<style lang="scss">
    div {
        img {
            width: 10%;
        }
    }
    .table-header-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: stretch;
        align-content: center;
    }
    .table-title {
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: var(--ctnGeneralPrimary);
    }
    .table-header-bar div {
        margin: 1.25rem 2rem 1.25rem 2rem;
    }
    .search {
        width: 70%;
        display: flex;
        gap: 0.5rem;
    }
</style>
