import { get, writable } from "svelte/store";
import type { IAccount, IAllOrders, IMorderSummary, IBuyerDetails } from "@/static/types";
import { NotificationType, OrderState } from "@/static/types";
import { OrderType } from "@/static/types";
import notificationState from "../notifications.store";

import { GET_ALL_ORDERS, UPDATE_INVOICE_STATUS } from "@/static/endPoints";
import API from "@/services/apiService";
import { DateToFormat } from "@/utils/functions";
import { account, signupComplete } from "@/store/merchant/account.store";
import environment from "@/services/environment";
import { orders as mockOrders } from "@/static/signupDemoMockResponses";

export const orderReplica = writable<IMorderSummary | null>(null);
export const buyerDetails = writable<IBuyerDetails | null>(null);
export const isCancelledRemainingOrder = writable<boolean>(false);

function createOrder() {
    let orderList: IAllOrders[] = [];

    const orders = writable(orderList);
    const loading = writable(false);
    const orderTab = writable("All");
    const orderPage = writable(1);
    const orderSource = writable([OrderType.MERCHANT_INITIATED, OrderType.BUYER_INITIATED]);
    const selectedOrderStatus = writable(["ALL"]);
    const orderAmountFrom = writable();
    const orderAmountTo = writable();
    const selectedActionRequired = writable("Any");
    const searchText = writable("");
    const numRowsPerPage = 25;
    const numAllRecords = writable();
    const riskType = writable("");
    const paymentStatuses = writable([]);
    const approvalType = writable("");
    let loadingUrl = "";
    let fetchLeafOrdersOnly = false;
    let accountSignupComplete = true;

    let ordersSource: IAllOrders[] = orderList;

    const startDate = writable();
    const endDate = writable();
    const filters = writable({});

    const setAllOrdersLoading = (value: boolean, url: string = null): void => {
        loading.set(value);
        loadingUrl = url;
    };

    const isLoading = (url) => {
        return loadingUrl === url;
    };

    account.subscribe((value: IAccount) => {
        if (value && Object.keys(value).length) {
            // only if the account state is loaded
            fetchLeafOrdersOnly = value.flags.fetch_only_leaf_orders === true;
        }
    });

    signupComplete.subscribe((value: boolean) => (accountSignupComplete = value));

    return {
        subscribe: orders.subscribe,
        loading: loading.subscribe,
        selectedTab: orderTab.subscribe,
        selectedPage: orderPage.subscribe,
        selectedSource: orderSource.subscribe,
        selectedStatus: selectedOrderStatus.subscribe,
        selectedAction: selectedActionRequired.subscribe,
        searchValue: searchText.subscribe,
        numRowsPerPage,
        numAllRecords,
        fetchLeafOrdersOnly,
        startDate,
        endDate,
        filters,
        actions: {
            getAllOrders: () => {
                if (!accountSignupComplete) {
                    ordersSource = mapOrders(mockOrders);
                    orders.set(ordersSource);
                    numAllRecords.set(mockOrders.page.items);
                    setAllOrdersLoading(false);
                    return;
                }
                let start_date: string;
                let end_date: string;
                startDate.subscribe((value: Date) => (start_date = DateToFormat(value)));
                endDate.subscribe((value: Date) => (end_date = DateToFormat(value)));
                // list of query parameters
                let paramsToLoad = "";
                // search by start date
                if (start_date) {
                    paramsToLoad += `&start_date=${start_date}`;
                }
                // search by end date
                if (end_date) {
                    paramsToLoad += `&end_date=${end_date}`;
                }

                // fetch only leaf orders
                if (!fetchLeafOrdersOnly) {
                    paramsToLoad += `&root_not_leaf=true`;
                }
                // search by type if selected merchant_initiated or buyer_initiated
                if (get(orderSource)) {
                    for (const oSrc of get(orderSource)) {
                        paramsToLoad += `&type=${oSrc}`;
                    }
                }
                // search by status if not selected ALL
                let orderStatuses = get(selectedOrderStatus);
                let currentTab = get(orderTab).toUpperCase();

                if (currentTab === "ALL") {
                    if (orderStatuses && !orderStatuses.includes("ALL")) {
                        for (const orderStatus of orderStatuses) {
                            // Originally tab=PENDING included CUSTOMER_CONFIRMATION, CUSTOMER_VERIFICATION and AWAITING_FULFILLMENT
                            // but we want to split it
                            if (
                                orderStatus === "PENDING" &&
                                !orderStatuses.includes("READY TO FULFILL")
                            ) {
                                paramsToLoad += `&tab=${orderStatus}`;
                                paramsToLoad += "&action_required=CUSTOMER_CONFIRMATION";
                                paramsToLoad += "&action_required=CUSTOMER_VERIFICATION";
                            } else if (
                                orderStatus === "READY TO FULFILL" &&
                                !orderStatuses.includes("PENDING")
                            ) {
                                paramsToLoad += "&tab=PENDING";
                                paramsToLoad += "&action_required=AWAITING_FULFILLMENT";
                            } else {
                                paramsToLoad += `&tab=${orderStatus}`;
                            }
                        }
                    }
                } else if (currentTab === "READY TO FULFILL") {
                    paramsToLoad += "&tab=PENDING";
                    if (get(selectedActionRequired) === "Any") {
                        paramsToLoad += "&action_required=AWAITING_FULFILLMENT";
                    }
                } else if (currentTab === "PENDING") {
                    paramsToLoad += "&tab=PENDING";
                    if (get(selectedActionRequired) === "Any") {
                        paramsToLoad += "&action_required=CUSTOMER_CONFIRMATION";
                        paramsToLoad += "&action_required=CUSTOMER_VERIFICATION";
                    }
                } else {
                    paramsToLoad += `&tab=${currentTab}`;
                }
                // search by minimum amount
                if (get(orderAmountFrom)) {
                    paramsToLoad += `&amount_from=${get(orderAmountFrom)}`;
                }
                // search by maximum amount
                if (get(orderAmountTo)) {
                    paramsToLoad += `&amount_to=${get(orderAmountTo)}`;
                }
                // search by action required
                if (get(selectedActionRequired) && get(selectedActionRequired) !== "Any") {
                    paramsToLoad += `&action_required=${get(selectedActionRequired)}`;
                }
                // search by text
                if (get(searchText)) {
                    paramsToLoad += `&q=${get(searchText)}`;
                }

                // risk type
                if (get(riskType) && get(riskType) !== "any") {
                    paramsToLoad += `&risk_type=${get(riskType)}`;
                }
                if (get(paymentStatuses)) {
                    get(paymentStatuses).forEach((status) => {
                        if (status !== "ALL-STATUSES" && status !== "ALL") {
                            paramsToLoad += `&payment_status=${status}`;
                        }
                    });
                }
                // approval type
                if (get(approvalType) && get(approvalType) !== "all") {
                    paramsToLoad += `&approval_type=${get(approvalType)}`;
                }
                // pagination by number of orders per page and page number (started from 1)
                paramsToLoad += `&limit=${numRowsPerPage}&page=${get(orderPage)}`;

                // replace the initial & (if any) with ?
                let urlToLoad = paramsToLoad
                    ? `${GET_ALL_ORDERS}?${paramsToLoad.substring(1)}`
                    : GET_ALL_ORDERS;

                if (isLoading(urlToLoad)) {
                    // Duplicate invocation with same filters - ignore, and allow the first invocation to complete
                    console.log(`Ignoring duplicate attempt to load ${loadingUrl}`);
                    return;
                }

                setAllOrdersLoading(true, urlToLoad);

                API.get(urlToLoad)
                    .then((res: any) => {
                        ordersSource = mapOrders(res);
                        orders.set(ordersSource);

                        numAllRecords.set(res.page.items);

                        setAllOrdersLoading(false);
                    })
                    .catch((e) => {
                        setAllOrdersLoading(false);
                        if (e.response.status !== 401) {
                            notificationState.actions.create(
                                NotificationType.ERROR,
                                "Merchant orders get failed."
                            );
                        }
                    });
            },
            setOrderTab(selectedTab: string) {
                orderTab.set(selectedTab);
                orderPage.set(1);
            },
            setOrderPage(selectedPage: number) {
                orderPage.set(selectedPage);
            },
            setOrderSource(selectedSource: OrderType[]) {
                orderSource.set(selectedSource);
            },
            setSelectedOrderStatus(selectedStatus: string[]) {
                selectedOrderStatus.set(selectedStatus);
            },
            setAmountFrom(amountFrom: string) {
                orderAmountFrom.set(amountFrom);
            },
            setAmountTo(amountTo: string) {
                orderAmountTo.set(amountTo);
            },
            setStartDate(startD: Date) {
                startDate.set(startD);
            },
            setEndDate(endD: Date) {
                endDate.set(endD);
            },
            setSearchText(searchStr: string) {
                searchText.set(searchStr);
            },
            setSelectedActionRequired(selectedAction: string) {
                selectedActionRequired.set(selectedAction);
            },
            setSelectedRiskType(selectedRiskType: string) {
                riskType.set(selectedRiskType);
            },
            setPaymentStatuses(statuses: Array<string>) {
                paymentStatuses.set(statuses);
            },
            setSelectedApprovalType(selectedApprovalType: string) {
                approvalType.set(selectedApprovalType);
            },
            updatePaymentStatus: (status: string, orderId: string): void => {
                API.post(UPDATE_INVOICE_STATUS(orderId), {
                    payment_status: status
                })
                    .then(() => {
                        const index = orderList.findIndex((i: IAllOrders) => {
                            return i.id == orderId;
                        });
                        orderList[index].payment_status = status;
                        orders.set(orderList);
                    })
                    .catch(() => {
                        notificationState.actions.create(
                            NotificationType.ERROR,
                            "We could not update that status",
                            null,
                            `Refresh your page and try again. If the error persists then contact ${environment.branding.supportEmail}.`
                        );
                    });
            }
        }
    };
}

const getDisplayStatus = (order: any) => {
    const orderStatus =
        order.status && order.status === OrderState.CANCELLED ? order.status : order.order_state;

    if (
        (order.refunded_amount || order.last_refund_date) &&
        (orderStatus === OrderState.REFUNDED ||
            ((orderStatus === OrderState.VERIFIED || orderStatus === OrderState.CONFIRMED) &&
                order.order_status === "PARTIAL"))
    ) {
        return OrderState.REFUNDED;
    }

    if (order.fulfilled_amount || order.fulfill_date) {
        if (
            orderStatus === OrderState.FULFILLED ||
            orderStatus === OrderState.DELIVERED ||
            order.fulfilled_amount === order.amount
        ) {
            return OrderState.FULFILLED;
        }

        if (
            (order.order_status === "PARTIAL" &&
                order.fulfilled_amount &&
                order.fulfilled_amount < order.amount) ||
            (order.fulfilled_amount && !order.fulfill_date)
        ) {
            return OrderState.PART_FULFILLED;
        }
    }

    if (
        ((orderStatus === OrderState.UNVERIFIED && order.order_status !== "REJECTED") ||
            (order.order_state === "CONFIRMED" && order.order_status === "APPROVED") ||
            orderStatus === OrderState.VERIFIED ||
            (order.status && order.status == "QUOTED")) &&
        orderStatus !== OrderState.FULFILLED &&
        orderStatus !== OrderState.DELIVERED &&
        orderStatus !== OrderState.CANCELLED &&
        orderStatus !== OrderState.REFUNDED
    ) {
        return OrderState.PENDING;
    }

    if (
        ((orderStatus === OrderState.UNVERIFIED && order.order_status === "REJECTED") ||
            orderStatus === OrderState.CANCELLED ||
            (orderStatus === OrderState.UNVERIFIED &&
                order.order_type === OrderType.MERCHANT_INITIATED &&
                order.order_status === "REJECTED")) &&
        !order.fulfill_date
    ) {
        return OrderState.CANCELLED;
    }
};

const getPaymentStatus = (paymentStatus: string) => {
    if (paymentStatus) {
        return (paymentStatus[0].toUpperCase() + paymentStatus.slice(1).toLowerCase()).replace(
            /_/g,
            " "
        );
    } else {
        return "Payment pending";
    }
};

const mapOrders = (res) => {
    let orderList = res.orders.map((order: any) => {
        if (order.order_status !== "UNINITIATED") {
            return <IAllOrders>{
                id: order.order_id,
                amount: order.amount,
                buyer_company: order.buyer_company,
                buyer_country: order.buyer_country,
                buyer_email: order.buyer_email,
                buyer_phone_number: order.buyer_phone_number,
                buyer_representative: `${order.buyer_first_name} ${order.buyer_last_name}`,
                currency: order.currency,
                fulfill_date: order.fulfill_date ? new Date(order.fulfill_date) : null,
                invoice_number: order.invoice_number,
                invoice_type: order.invoice_type,
                last_refund_date: order.last_refund_date ? new Date(order.last_refund_date) : null,
                order_date: new Date(order.order_date),
                order_number: order.order_number,
                order_state:
                    order.status && order.status === "CANCELLED" ? order.status : order.order_state,
                order_status: order.order_status,
                order_type:
                    order.order_type !== null &&
                    order.order_state !== null &&
                    order.order_status !== null
                        ? order.order_type
                        : OrderType.MERCHANT_INITIATED,
                refunded_amount: order.refunded_amount,
                display_status: getDisplayStatus(order),
                is_latent: order.is_latent,
                action_required: order.action_required,
                payment_status: getPaymentStatus(order.payment_status),
                invoice_due_date: order.invoice_due_date,
                fulfilled_amount: order.fulfilled_amount,
                approved_on_recourse: order.approved_on_recourse
            };
        }
    });
    return orderList;
};

const orderState = createOrder();
export default orderState;
