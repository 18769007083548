<script lang="ts">
    import Fa from "svelte-fa";
    import { _ } from "svelte-i18n";
    import { faXmark } from "@fortawesome/free-solid-svg-icons";
    import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
    import { navigate } from "svelte-routing";
    import { Button as TwoUIButton } from "@two-ui/core";

    import modalState from "@/store/modals.store";

    export let fName: string = "";
    export let resetCreateOrderForm: () => void;

    let isCreateOrderStep = false;

    const handleNewOrderFromScratch = () => {
        modalState.actions.setModal(null, {});
        resetCreateOrderForm();
    };

    const handleBackToOrders = () => {
        modalState.actions.setModal(null, {});
        navigate("/merchant/orders");
    };

    const handleReplicateOrder = () => {
        modalState.actions.setModal(null, {});
    };
</script>

<div class="w-2xs p-8">
    <div class="w-full flex justify-between mb-4">
        <p id="order_created_model" class="text-2xl font-bold">
            {isCreateOrderStep ? $_("oc.creatingNew") : $_("order.orderCreated")}
        </p>
        <div
            class="cursor-pointer"
            role="button"
            tabindex="0"
            on:click={handleBackToOrders}
            on:keyup
        >
            <Fa icon={faXmark} size="lg" color="var(--gray-500)" />
        </div>
    </div>
    {#if isCreateOrderStep}
        <p class="my-4 text-Content-General-Secondary">
            {$_("oc.creatingNewText")}
        </p>
        <div class="flex p-2 mb-14 bg-Background-Semantic-Info-Ultra-Light items-center">
            <Fa icon={faLightbulb} size="lg" class="mr-4" />
            <span class="text-Content-Semantic-Info-Secondary font-medium"
                >{$_("oc.replicatingInfo")}</span
            >
        </div>

        <div class="flex justify-end">
            <TwoUIButton
                on:click={handleReplicateOrder}
                size="md"
                variant="secondaryGray"
                content={$_("order.details.replicate")}
                class="mr-4"
            />

            <TwoUIButton
                on:click={handleNewOrderFromScratch}
                size="md"
                variant="primary"
                content={$_("oc.startFromScratch")}
            />
        </div>
    {:else}
        <p class="font-bold text-lg mb-4">{$_("oc.whatHappens")}</p>
        <p class="mb-4 text-Content-General-Secondary">
            {$_("oc.emailNotification", { values: { fName } })}
        </p>
        <p class="mb-4 text-Content-General-Secondary">
            {$_("oc.orderProcessing")}
        </p>
        <p class="mb-4 text-Content-General-Secondary">
            {$_("oc.webhookNotification")}
            <a
                class="text-Content-Semantic-Highlight-Primary"
                href="https://docs.two.inc/openapi/webhooks-api/#operation/order_verifiedverified_post"
                target="_blank"
            >
                {$_("oc.webhookLinkText")}</a
            >
        </p>

        <div class="flex justify-end">
            <TwoUIButton
                on:click={handleBackToOrders}
                size="md"
                variant="secondaryGray"
                content={$_("oc.backToOrders")}
                class="mr-4"
            />

            <TwoUIButton
                on:click={() => (isCreateOrderStep = true)}
                size="md"
                variant="primary"
                content={$_("oc.createNew")}
            />
        </div>
    {/if}
</div>
