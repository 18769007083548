<script lang="ts">
    import { _ } from "svelte-i18n";
    import moment from "moment";
    import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
    import { faPhone, faBriefcase } from "@fortawesome/free-solid-svg-icons";

    import Table from "@/components/table/Table.svelte";
    import { loggedInAsMerchant } from "@/store/merchant/account.store";
    import {
        actions as insightActions,
        insight as creditInsights,
        insightsLoading
    } from "@/store/merchant/creditInsights.store";
    import { FRAUD_RULES_REMOVE, FRAUD_RULES_UNBLOCK } from "@/static/modals";

    let numDisplayedRecords = 0;
    let tableRows = [];
    const pageNum = 1;

    let dateSortOrder = "desc";

    const TABLE_HEADERS = [
        {
            title: "Value type",
            translation: $_("riskManagement.fraudRules.valueType"),
            clickable: false,
            type: "string"
        },
        {
            title: "Value",
            translation: $_("order.details.invoiceTable.value"),
            clickable: false,
            type: "string"
        },
        {
            title: "Status",
            translation: $_("account.document.status"),
            clickable: false,
            type: "string"
        },
        {
            title: "Set by",
            translation: $_("riskManagement.fraudRules.setBy"),
            clickable: false,
            type: "string"
        },
        {
            title: "Reasons",
            translation: $_("riskManagement.fraudRules.reasons"),
            clickable: false,
            type: "string"
        },
        {
            title: "Set at",
            translation: $_("riskManagement.fraudRules.setAt"),
            clickable: true,
            type: "string"
        },
        {
            title: "Actions",
            notext: true,
            clickable: false,
            type: "string",
            customClasses: "min-w-[80px]"
        }
    ];

    $: if ($loggedInAsMerchant) {
        insightActions.getInsights();
    }

    const fraudRulesData = [
        {
            id: 1,
            valueType: "Email",
            value: "dmatthews@bigspender.as",
            status: "Blocked",
            setBy: "C. Johansen",
            reason: {
                title: "First-party fraud",
                description: "Very suspicious website and social media."
            },
            setAt: "2024-12-19T12:30:00.123456"
        },
        {
            id: 2,
            valueType: "Phone",
            value: "+41 3498734543",
            status: "Blocked",
            setBy: "T. Lenney",
            reason: {
                title: "First-party fraud",
                description: "Very suspicious website and social media."
            },
            setAt: "2024-12-19T16:47:06.654321"
        },
        {
            id: 3,
            valueType: "Org. number",
            value: "1234567890",
            subValue: "Big Spender AS",
            status: "Blocked",
            setBy: "B. Mooney",
            reason: {
                title: "First-party fraud",
                description: "Very suspicious website and social media."
            },
            setAt: "2024-12-18T09:15:45.987654"
        },
        {
            id: 4,
            valueType: "Phone",
            value: "+41 7468731474",
            status: "Whitelisted",
            setBy: "C. Johansen",
            reason: {
                title: "Existing trusted customer",
                description: "A comment with reasonable length."
            },
            setAt: "2024-12-20T18:00:00.123789"
        },
        {
            id: 5,
            valueType: "Email",
            value: "emma.johansen@firma.no",
            status: "Blocked",
            setBy: "R. Ferreiro",
            reason: {
                title: "First-party fraud",
                description: "Very suspicious website and social media."
            },
            setAt: "2024-12-18T14:20:30.456123"
        },
        {
            id: 6,
            valueType: "Phone",
            value: "123456789",
            status: "Whitelisted",
            setBy: "C. Johansen",
            reason: {
                title: "Existing trusted customer",
                description: "A comment with reasonable length."
            },
            setAt: "2024-12-17T10:10:15.789456"
        },
        {
            id: 7,
            valueType: "Org. number",
            value: "78123456789",
            subValue: "North Star AS",
            status: "Blocked",
            setBy: "C. Johansen",
            reason: {
                title: "First-party fraud",
                description: "A comment with reasonable length."
            },
            setAt: "2024-12-21T08:45:30.123654"
        }
    ];

    function getValueTypeIcon(valueType) {
        switch (valueType) {
            case "Email":
                return faEnvelope;
            case "Phone":
                return faPhone;
            default:
                return faBriefcase;
        }
    }

    function buildFraudRulesTable($creditInsights) {
        const tableRows = [];

        for (const item of fraudRulesData) {
            const momentObj = moment(item.setAt);
            const date = momentObj.format("DD/MM/YYYY");
            const time = momentObj.format("HH:mm:ss");

            const isBlocked = item.status === "Blocked";

            const rowItem = {
                id: item.id,
                setAtOriginal: item.setAt,
                "Value type": {
                    tableItem: item.valueType,
                    faIcon: getValueTypeIcon(item.valueType)
                },
                Value: {
                    tableItem: item.value,
                    subItem: item.subValue || ""
                },
                Status: {
                    tags: [
                        isBlocked
                            ? {
                                  text: $_("riskManagement.fraudRules.blocked"),
                                  textColor: "var(--ctnSemanticErrorPrimary)",
                                  bgColor: "var(--bgSemanticErrorTertiary)"
                              }
                            : {
                                  text: $_("riskManagement.fraudRules.whitelisted"),
                                  textColor: "var(--ctnSemanticSuccessPrimary)",
                                  bgColor: "var(--bgSemanticSuccessTertiary)"
                              }
                    ]
                },
                "Set by": { tableItem: item.setBy },
                Reasons: {
                    tableItem: item.reason.title,
                    subItem: item.reason.description
                },
                "Set at": { tableItem: date, subItem: time },
                Actions: {
                    tableItem: isBlocked
                        ? $_("riskManagement.fraudRules.unblock")
                        : $_("components.remove"),
                    actionModal: isBlocked ? FRAUD_RULES_UNBLOCK : FRAUD_RULES_REMOVE,
                    actionProps: {
                        valueType: item.valueType,
                        value: item.value,
                        companyName: item.subValue || ""
                    },
                    itemTextColor: "var(--ctnSemanticHighlightPrimary)"
                }
            };
            tableRows.push(rowItem);
        }

        return tableRows;
    }

    $: {
        tableRows = buildFraudRulesTable($creditInsights).sort((a, b) => {
            const dateA = new Date(a.setAtOriginal).getTime();
            const dateB = new Date(b.setAtOriginal).getTime();
            return dateSortOrder === "asc" ? dateA - dateB : dateB - dateA;
        });
        numDisplayedRecords = tableRows.length;
    }

    function handleSort(event) {
        const sortItem = event.detail.sortItem;

        if (sortItem === "Set at") {
            dateSortOrder = dateSortOrder === "asc" ? "desc" : "asc";
        }
    }
</script>

<div class="fraud-rules-container">
    <Table
        numRows={25}
        numAllRecords={numDisplayedRecords}
        tableHeaders={TABLE_HEADERS}
        {tableRows}
        {pageNum}
        useBackendPagination={false}
        loading={$insightsLoading || !$loggedInAsMerchant}
        on:sorting={handleSort}
    />

    {#if $loggedInAsMerchant && !$insightsLoading && !numDisplayedRecords}
        <div class="w-full h-48 flex flex-col items-center pt-8">
            <img src="/pictures/mailboxstate.svg" alt="mailbox" />
            <p class="my-4 font-semibold text-black-dark text-center">
                {$_("riskManagement.noCustomers")}
            </p>
        </div>
    {/if}
</div>

<style>
    .fraud-rules-container {
        overflow: auto;
        display: flex;
        flex-direction: column;
        height: 80vh;
    }
</style>
