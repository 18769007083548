export type TranslationFunction = (
    key: string,
    options?: { values?: { amount: number } }
) => string;

export const STANDARD_TERMS = ($_: TranslationFunction) => $_("oc.standardTerms");
export const INSTALMENT_PLAN = ($_: TranslationFunction) => $_("oc.instalmentPlan");

export const paymentMethods = ($_: TranslationFunction): string[] => [
    STANDARD_TERMS($_),
    INSTALMENT_PLAN($_)
];

export const netTermsOptions = ($_: TranslationFunction): string[] => [
    $_("oc.xDays", { values: { amount: 14 } }),
    $_("oc.xDays", { values: { amount: 30 } }),
    $_("oc.xDays", { values: { amount: 45 } }),
    $_("oc.xDays", { values: { amount: 60 } }),
    $_("oc.xDays", { values: { amount: 90 } })
];
export const instalmentOptions = ($_: TranslationFunction): string[] => [
    $_("oc.xMonths", { values: { amount: 6 } }),
    $_("oc.xMonths", { values: { amount: 12 } }),
    $_("oc.xMonths", { values: { amount: 18 } }),
    $_("oc.xMonths", { values: { amount: 24 } }),
    $_("oc.xMonths", { values: { amount: 36 } })
];
