<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button as TwoUIButton } from "@two-ui/core";

    import Icon from "@/components/icons/Icon.svelte";
    import FiltersSidebar from "@/components/filters/FiltersSidebar.svelte";
    import CheckboxItem from "@/components/checkboxitem/CheckboxItem.svelte";
    import Radio from "@/components/Elements/Radio.svelte";

    export let updateTable;

    let filterCounter: number = 0;

    let showFilters: boolean = false;
    let filterParams: string = "";

    let eventTypes: string[] = ["ALL"];

    const orderValues = [
        {
            value: "highest",
            label: $_("riskManagement.fraud.highest")
        },
        {
            value: "lowest",
            label: $_("riskManagement.fraud.lowest")
        }
    ];

    const dateAndTime = [
        {
            value: "newest",
            label: $_("riskManagement.fraud.newest")
        },
        {
            value: "oldest",
            label: $_("riskManagement.fraud.oldest")
        }
    ];

    const eventTypeOptions = [
        {
            name: "all",
            value: $_("riskManagement.filters.all")
        },
        {
            name: "Order",
            value: $_("riskManagement.fraud.order")
        },
        {
            name: "Onboarding",
            value: $_("riskManagement.fraud.onboarding")
        }
    ];

    let orderValuesSelected = orderValues[0];
    let sortByTimeAndDate = dateAndTime[0];

    function clearFilters() {
        eventTypes = ["ALL"];
        orderValuesSelected = orderValues[0];
        sortByTimeAndDate = dateAndTime[0];
        filterCounter = 0;
        filterParams = "";

        updateTable(filterParams);
        showFilters = false;
    }

    function toggleFilters() {
        showFilters = !showFilters;
    }

    function filterLimits(event) {
        const checkedItem = event.detail.checkedItem.toUpperCase();
        const isChecked = event.detail.checked;

        if (isChecked) {
            if (checkedItem === "ALL") {
                // If "ALL" is checked, it replaces the list with just "ALL"
                eventTypes = ["ALL"];
            } else {
                // Add the checked item if "ALL" is not selected
                if (!eventTypes.includes("ALL")) {
                    if (!eventTypes.includes(checkedItem)) {
                        eventTypes.push(checkedItem);
                    }
                    // If both "Order" and "Onboarding" are present, set to "ALL"
                    if (eventTypes.includes("ORDER") && eventTypes.includes("ONBOARDING")) {
                        eventTypes = ["ALL"];
                    }
                }
            }
        } else {
            // If unchecked
            if (checkedItem === "ALL") {
                // Unchecking "ALL" clears the list
                eventTypes = [];
            } else {
                // Remove the unchecked item
                const index = eventTypes.indexOf(checkedItem);
                if (index !== -1) {
                    eventTypes.splice(index, 1);
                }
                // If "ALL" was replaced by individual items, restore default items
                if (eventTypes.length === 0 && eventTypes.includes("ALL")) {
                    eventTypes = ["ORDER", "ONBOARDING"];
                }
            }
        }
    }

    function applyFilters() {
        filterCounter = 0;
        filterParams = "";

        if (!eventTypes.includes("ALL") && eventTypes.length > 0) {
            if (eventTypes.includes("Onboarding")) {
                filterParams += "&event_type=onboarding";
            } else {
                filterParams += "&event_type=order";
            }
            filterCounter++;
        }

        if (orderValuesSelected.value !== "all") {
            filterParams += `&order_values=${orderValuesSelected.value}`;
            filterCounter++;
        }

        if (sortByTimeAndDate.value !== "all") {
            filterParams += `&time_and_date=${sortByTimeAndDate.value}`;
            filterCounter++;
        }
        updateTable(filterParams);

        showFilters = false;
    }
</script>

<div
    class="filter-button"
    class:filters-active={filterCounter > 0}
    on:click={toggleFilters}
    on:keyup
    tabindex="0"
    role="button"
>
    <Icon name="sliders" size={16} />
    <span>{$_("filters.filters")}</span>
    {#if filterCounter > 0}
        <div class="filter-counter">{filterCounter}</div>
    {/if}
</div>

{#if showFilters}
    <FiltersSidebar>
        <div slot="header" class="filter-header">
            <div>
                <span>{$_("filters.filtersFor")}</span>
                <span class="header-subtext">{$_("riskManagement.fraud.title")}</span>
            </div>
            <div class="close">
                <button on:click={toggleFilters}>
                    <Icon name="cross" size={32} />
                </button>
            </div>
        </div>
        <div slot="main">
            <div class="filter-item">
                <span class="filter-item-header-text"
                    >{$_("riskManagement.fraud.sortByOrderValue")}</span
                >
                <div class="filter-items-radio">
                    {#each orderValues as usageItem}
                        <Radio
                            bind:group={orderValuesSelected}
                            option={usageItem}
                            id={usageItem.label}
                        />
                    {/each}
                </div>
            </div>

            <div class="filter-item">
                <span class="filter-item-header-text"
                    >{$_("riskManagement.fraud.sortByDateAndTime")}</span
                >
                <div class="filter-items-radio">
                    {#each dateAndTime as usageItem}
                        <Radio
                            bind:group={sortByTimeAndDate}
                            option={usageItem}
                            id={usageItem.label}
                        />
                    {/each}
                </div>
            </div>

            <div class="filter-item last-item">
                <span class="filter-item-header-text">{$_("riskManagement.fraud.eventType")}</span>
                <div class="filter-items-options">
                    {#each eventTypeOptions as limitItem}
                        <CheckboxItem
                            statusItem={limitItem}
                            isChecked={eventTypes.includes(limitItem.name.toUpperCase()) ||
                                eventTypes.includes("ALL")}
                            on:checkedItem={filterLimits}
                        />
                    {/each}
                </div>
            </div>
        </div>
        <div slot="footer" class="filter-footer">
            <div class="flex gap-2">
                <TwoUIButton
                    on:click={applyFilters}
                    size="md"
                    variant="primary"
                    content={$_("filters.applyFilters")}
                />
                <TwoUIButton
                    on:click={clearFilters}
                    size="md"
                    variant="secondaryGray"
                    content={$_("filters.clearAll")}
                />
            </div>
        </div>
    </FiltersSidebar>
{/if}

<style>
</style>
