<script lang="ts">
    import { _ } from "svelte-i18n";

    import Icon from "../icons/Icon.svelte";
    import notificationState from "@/store/notifications.store";
    import { NotificationType } from "../../static/types";

    export let value: string = "";
    export let subText: string = "";
    export let id: string = "userinput";
    export let toolTipContent: string = "";

    const copyToClipboard = () => {
        const link = value;
        navigator.clipboard
            .writeText(link)
            .then(() => {
                notificationState.actions.create(NotificationType.SUCCESS, $_("components.copied"));
            })
            .catch((err) => {
                notificationState.actions.create(
                    NotificationType.ERROR,
                    $_("errors.somethingWrong")
                );
            });
    };
</script>

<div class="link-container flex items-center w-full min-h-[44px] border rounded-lg px-2">
    <p class="link-text text-sm break-words whitespace-normal flex-1 ml-2 hover:underline">
        {value}
    </p>

    <div
        class=" copy-box flex items-center space-x-1 cursor-pointer p-2 ml-4"
        on:click={copyToClipboard}
    >
        <Icon name="copy" fill="--ctnGeneralPrimary" size={20} />
        <p class="text-sm">{$_("components.addCustomerModal.copy")}</p>
    </div>
</div>

<div class="copy-sub-text text-sm font-normal leading-5 mt-1">{subText}</div>

<style>
    .link-container {
        color: var(--gray-300);
    }
    .link-text {
        color: var(--violet-400);
    }

    .copy-box {
        border-left: 1px solid var(--gray-300);
        color: var(--gray-500);
    }

    .copy-sub-text {
        color: var(--gray-500);
    }
</style>
