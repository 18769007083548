<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button as TwoUIButton } from "@two-ui/core";

    import Icon from "../../icons/Icon.svelte";
    import FiltersSidebar from "../FiltersSidebar.svelte";
    import CheckboxItem from "../../checkboxitem/CheckboxItem.svelte";

    export let updateTable;

    let filterCounter: number = 0;

    let showFilters: boolean = false;
    let filterParams: string = "";

    let statuses: string[] = [];
    let valueTypes: string[] = [];

    const fraudRuleStatus = [
        {
            value: "all",
            label: $_("riskManagement.filters.all")
        },
        {
            value: "whitelisted",
            label: $_("riskManagement.fraudRules.whitelisted")
        },
        {
            value: "blocked",
            label: $_("riskManagement.fraudRules.blocked")
        }
    ];

    const valueTypeOptions = [
        {
            value: "all-values",
            label: $_("riskManagement.filters.all")
        },
        {
            value: "organization",
            label: $_("riskManagement.fraud.organizationNumber")
        },
        {
            value: "email",
            label: $_("components.inputType.email")
        },
        {
            value: "phone",
            label: $_("components.inputType.phone")
        }
    ];

    function clearFilters() {
        statuses = ["ALL"];
        valueTypes = ["all-values"];
        filterCounter = 0;
        filterParams = "";

        updateTable(filterParams);
        showFilters = false;
    }

    function toggleFilters() {
        showFilters = !showFilters;
    }

    function filterStatus(event) {
        if (event.detail.checked) {
            if (event.detail.checkedItem.toUpperCase() === "ALL") {
                statuses = ["ALL"];
            } else {
                statuses.push(event.detail.checkedItem);
                if (statuses.includes("whitelisted") && statuses.includes("blocked")) {
                    statuses = ["ALL"];
                }
            }
        }

        if (!event.detail.checked) {
            if (event.detail.checkedItem.toUpperCase() === "ALL") {
                statuses = [];
            } else if (statuses.includes("ALL")) {
                statuses = ["whitelisted", "blocked"];
            }

            const index = statuses.indexOf(event.detail.checkedItem);
            if (index !== -1) {
                statuses.splice(index, 1);
            }
        }
    }

    function filterValueType(event) {
        if (event.detail.checked) {
            if (event.detail.checkedItem === "all-values") {
                valueTypes = ["all-values"];
            } else {
                valueTypes.push(event.detail.checkedItem);
                if (valueTypes.length === valueTypeOptions.length - 1) {
                    valueTypes = ["all-values"];
                }
            }
        }

        if (!event.detail.checked) {
            if (event.detail.checkedItem === "all-values") {
                valueTypes = [];
            } else if (valueTypes.includes("all-values")) {
                valueTypes = valueTypeOptions.slice(1).map((item) => item.value);
            }

            const index = valueTypes.indexOf(event.detail.checkedItem);
            if (index !== -1) {
                valueTypes.splice(index, 1);
            }
        }
    }

    function applyFilters() {
        filterCounter = 0;
        filterParams = "";

        if (!statuses.includes("ALL") && statuses.length > 0) {
            if (statuses.includes("whitelisted")) {
                filterParams += "&fraud_rule_status=whitelisted";
            } else {
                filterParams += "&fraud_rule_status=blocked";
            }
            filterCounter++;
        }

        if (!valueTypes.includes("all-values") && valueTypes.length > 0) {
            valueTypes.forEach((type) => {
                filterParams += `&fraud_type=${type}`;
            });
            filterCounter++;
        }

        updateTable(filterParams);

        showFilters = false;
    }
</script>

<div
    class="filter-button"
    class:filters-active={filterCounter > 0}
    on:click={toggleFilters}
    on:keyup
    tabindex="0"
    role="button"
>
    <Icon name="sliders" size={16} />
    <span>{$_("filters.filters")}</span>
    {#if filterCounter > 0}
        <div class="filter-counter">{filterCounter}</div>
    {/if}
</div>

{#if showFilters}
    <FiltersSidebar>
        <div slot="header" class="filter-header">
            <div>
                <span>{$_("filters.filtersFor")}</span>
                <span class="header-subtext">{$_("riskManagement.fraudRules.rulesTitle")}</span>
            </div>
            <div class="close">
                <button on:click={toggleFilters}>
                    <Icon name="cross" size={32} />
                </button>
            </div>
        </div>
        <div slot="main">
            <div class="filter-item">
                <span class="filter-item-header-text">{$_("account.document.status")}</span>
                <div class="filter-items-options">
                    {#each fraudRuleStatus as status}
                        <CheckboxItem
                            statusItem={status}
                            isChecked={statuses.includes(status.value) || statuses.includes("ALL")}
                            checkedEventName="fraudRuleChanged"
                            on:fraudRuleChanged={filterStatus}
                        />
                    {/each}
                </div>
            </div>
            <div class="filter-item last-item">
                <span class="filter-item-header-text"
                    >{$_("riskManagement.fraudRules.valueType")}</span
                >
                <div class="filter-items-options">
                    {#each valueTypeOptions as option}
                        <CheckboxItem
                            statusItem={option}
                            isChecked={valueTypes.includes(option.value) ||
                                valueTypes.includes("all-values")}
                            checkedEventName="valueTypeChanged"
                            on:valueTypeChanged={filterValueType}
                        />
                    {/each}
                </div>
            </div>
        </div>
        <div slot="footer" class="filter-footer">
            <div class="flex gap-2">
                <TwoUIButton
                    on:click={applyFilters}
                    size="md"
                    variant="primary"
                    content={$_("filters.applyFilters")}
                />
                <TwoUIButton
                    on:click={clearFilters}
                    size="md"
                    variant="secondaryGray"
                    content={$_("filters.clearAll")}
                />
            </div>
        </div>
    </FiltersSidebar>
{/if}
