import * as Sentry from "@sentry/svelte";

import { getEnvironmentName } from "./services/environment";
const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
const baseUrl = import.meta.env.VITE_BASE_URL;

// Initialize the Sentry SDK if not dev env
if (sentryDsn) {
    Sentry.init({
        dsn: sentryDsn,
        integrations: [Sentry.browserTracingIntegration()],
        // attach sentry-trace and baggage headers only for requests to __app.env.BASE_URL
        tracePropagationTargets: [baseUrl],
        environment: getEnvironmentName(),
        tracesSampler: (samplingContext) => {
            // always inherit the sampling decision from the previous system
            // e.g. If a portal-api request is traced and the involved request to portal-page will also be traced.
            // always inherit
            if (samplingContext.parentSampled !== undefined) {
                return samplingContext.parentSampled;
            }
            // return 30% of the transactions to sentry if no configurations is found above
            return 0.3;
        }
    });
}
