import AuthLayout from "../pages/authentication/AuthLayout.svelte";
import MerchantLayout from "../pages/merchant/Layout.svelte";
import BuyerLayout from "../pages/buyer/Layout.svelte";

const routes = [
    {
        path: "auth/*",
        component: AuthLayout
    },
    {
        path: "merchant/*",
        component: MerchantLayout
    },
    {
        path: "buyer/*",
        component: BuyerLayout
    }
];

export default routes;
