<script lang="ts">
    import { createEventDispatcher, onMount } from "svelte";

    export let value: any = "";
    export let type: string = "";
    export let disabled: boolean = false;
    export let name: string = "";
    export let isFocus: boolean = false;
    export let showBorder: boolean = true;
    export let id: string = "userinput";
    export let invalid: string = "";
    export let showInvalidError: boolean = false;
    export let placeholder: string = "";

    export let toolTipContent: string = "";

    let isOverflowing = false;

    onMount(calculateOverflow);

    $: {
        value;
        calculateOverflow();
    }

    function calculateOverflow() {
        const inputElement = document.getElementById(id);
        isOverflowing = inputElement?.scrollWidth > inputElement?.clientWidth;
    }

    const dispatch = createEventDispatcher();

    function handleChange(): any {
        if (value) {
            invalid = "";
        }
        dispatch("change", { value, id });
    }
    function topLabel(): any {
        isFocus = true;
    }
    function loseFocus(): any {
        isFocus = false;
    }
</script>

<div
    class="relative flex w-full mt-1 rounded-lg input-container"
    class:in-focus={isFocus}
    class:not-in-focus={!isFocus && !showBorder}
    class:show-border={!isFocus && showBorder}
    class:incorrect={invalid ? true : false}
>
    {#if (toolTipContent !== "" && !isFocus && toolTipContent === value && isOverflowing) || (toolTipContent && toolTipContent !== value)}
        <span class="tooltip-text">{toolTipContent}</span>
    {/if}
    {#if type === "textarea"}
        <textarea
            bind:value
            {name}
            {placeholder}
            {id}
            {disabled}
            class="w-full border-none outline-hidden"
            class:invalid-value={!isFocus && showInvalidError}
            on:focus={topLabel}
            on:focusout={loseFocus}
            on:input={handleChange}
        />
    {:else}
        <input
            bind:value
            type="text"
            {placeholder}
            {name}
            {disabled}
            {id}
            class="w-full pl-2 bg-white border-none outline-hidden sm:pl-4"
            class:disabled
            class:invalid-value={!isFocus && showInvalidError}
            on:focus={topLabel}
            on:focusout={loseFocus}
            on:input={handleChange}
            on:click
            autocomplete="off"
        />
    {/if}
    {#if showInvalidError}
        <p class="error">
            {invalid}
        </p>
    {/if}
</div>

<style lang="scss">
    .error {
        position: absolute;
        top: 48px;
        color: var(--error-600);
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }

    .in-focus {
        border-radius: 8px;
        border: 2px solid #8098f9;

        &:hover {
            border: 2px solid #8098f9;
        }
    }

    input {
        z-index: 1;
        height: 42px;
        border-radius: 8px;
        color: var(--ctnGeneralPrimary);
        padding-left: 14px;
        font-weight: normal;
    }

    textarea {
        z-index: 1;
        min-height: 100px;
        border-radius: 8px;
        background-color: white;
        padding: 10px 14px;

        &:disabled {
            color: var(--gray-500);
            background-color: var(--gray-50);
        }
    }

    input[type="text"].disabled {
        background: #f9fafb !important;
    }

    input:focus,
    textarea:focus {
        background-color: white;
    }

    .incorrect {
        border: 1px solid var(--error-600);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    :global(input[type="number"]) {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    .input-container {
        position: relative;

        &:hover {
            .tooltip-text {
                visibility: visible;
            }
        }

        .tooltip-text {
            position: absolute;
            visibility: hidden;
            min-width: 175px;
            background-color: white;
            color: black;
            text-align: center;
            padding: 12px;
            border-radius: 4px;
            z-index: 10;
            bottom: 40px;
            right: -30px;
            box-shadow:
                0px 4px 12px 6px rgba(78, 91, 166, 0.08),
                0px 8px 16px -6px rgba(78, 91, 166, 0.12);

            font-family: Aeonik;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .input-container > input {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:disabled {
            pointer-events: none;
            border-radius: 8px;
            border: 1px solid #d0d5dd;
            background: #f2f4f7 !important;
        }
    }

    .not-in-focus > input:hover,
    textarea:hover {
        border-radius: 8px;
        border: 1px solid #d0d5dd;
    }

    .show-border > input,
    textarea {
        border-radius: 8px;
        border: 1px solid #d0d5dd;
    }

    .show-border > input:hover,
    textarea:hover {
        border: 2px solid #d0d5dd;
    }

    .invalid-value {
        border: 1px solid #912018;
    }
</style>
