<script lang="ts">
    import { TextInput as TwoUIInput } from "@two-ui/core";

    import Icon from "../../components/icons/Icon.svelte";

    export let searchValue;
    export let placeholder: string = "Enter search query";
    export let setSearch: CallableFunction;
    export let disabled: boolean = false;
    export let id: string = "";
</script>

<div class="w-full relative pr-1">
    <TwoUIInput
        {id}
        {placeholder}
        bind:value={searchValue}
        on:input={(e) => setSearch(e)}
        {disabled}
    >
        <div slot="icon">
            <Icon name="search" size={24} fill="#B4B4C9" />
        </div>
    </TwoUIInput>
</div>
