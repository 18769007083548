<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button as TwoUIButton } from "@two-ui/core";

    import Icon from "../../icons/Icon.svelte";
    import FiltersSidebar from "../FiltersSidebar.svelte";
    import CheckboxItem from "../../../components/checkboxitem/CheckboxItem.svelte";
    import Radio from "../../Elements/Radio.svelte";

    export let updateTable;

    let filterCounter: number = 0;

    let showFilters: boolean = false;
    let filterParams: string = "";

    let limitSetBy: string[] = ["ALL"];
    let totalSpend: string[] = ["ALL"];

    const creditProviders = {
        KOMPLETT: "KOMPLETT",
        TWO: "TWO"
    };

    const creditUsageOptions = [
        {
            value: "all",
            label: $_("riskManagement.filters.all")
        },
        {
            value: "95",
            label: $_("riskManagement.filters.over95")
        },
        {
            value: "90",
            label: $_("riskManagement.filters.over90")
        },
        {
            value: "80",
            label: $_("riskManagement.filters.over80")
        }
    ];

    const limitSetByOptions = [
        {
            name: "all",
            value: $_("riskManagement.filters.all")
        },
        {
            name: "Komplett",
            value: $_("riskManagement.filters.komplett")
        },
        {
            name: "Two",
            value: $_("riskManagement.filters.two")
        }
    ];

    const totalSpendOptions = [
        {
            name: "all",
            value: $_("riskManagement.filters.all")
        },
        {
            name: "noSpend",
            value: $_("riskManagement.filters.customersWithNoSpend")
        },
        {
            name: "anySpend",
            value: $_("riskManagement.filters.customersWithAnySpend")
        }
    ];

    let usageTypeSelected = creditUsageOptions[0];

    function clearFilters() {
        limitSetBy = ["ALL"];
        totalSpend = ["ALL"];
        usageTypeSelected = creditUsageOptions[0];
        filterCounter = 0;
        filterParams = "";

        updateTable(filterParams);
        showFilters = false;
    }

    function toggleFilters() {
        showFilters = !showFilters;
    }

    function filterLimits(event) {
        if (event.detail.checked) {
            if (event.detail.checkedItem.toUpperCase() === "ALL") {
                limitSetBy = ["ALL"];
            } else {
                limitSetBy.push(event.detail.checkedItem.toUpperCase());
                if (
                    limitSetBy.includes(creditProviders.KOMPLETT) &&
                    limitSetBy.includes(creditProviders.TWO)
                ) {
                    limitSetBy = ["ALL"];
                }
            }
        }

        if (!event.detail.checked) {
            if (event.detail.checkedItem.toUpperCase() === "ALL") {
                limitSetBy = [];
            } else if (limitSetBy.includes("ALL")) {
                limitSetBy = [creditProviders.KOMPLETT, creditProviders.TWO];
            }

            const index = limitSetBy.indexOf(event.detail.checkedItem.toUpperCase());
            if (index !== -1) {
                limitSetBy.splice(index, 1);
            }
        }
    }

    function filterTotalSpend(event) {
        if (event.detail.checked) {
            if (event.detail.checkedItemKey.toUpperCase() === "ALL") {
                totalSpend = ["ALL"];
            } else {
                totalSpend.push(event.detail.checkedItemKey.toUpperCase());
                if (totalSpend.includes("NOSPEND") && totalSpend.includes("ANYSPEND")) {
                    totalSpend = ["ALL"];
                }
            }
        }

        if (!event.detail.checked) {
            if (event.detail.checkedItemKey.toUpperCase() === "ALL") {
                totalSpend = [];
            } else if (totalSpend.includes("ALL")) {
                totalSpend = ["NOSPEND", "ANYSPEND"];
            }

            const index = totalSpend.indexOf(event.detail.checkedItemKey.toUpperCase());
            if (index !== -1) {
                totalSpend.splice(index, 1);
            }
        }
    }

    function applyFilters() {
        filterCounter = 0;
        filterParams = "";

        if (!limitSetBy.includes("ALL") && limitSetBy.length > 0) {
            if (limitSetBy.includes(creditProviders.KOMPLETT)) {
                filterParams += "&credit_limit_provider=Komplett";
            } else {
                filterParams += "&credit_limit_provider=Two";
            }
            filterCounter++;
        }

        if (!totalSpend.includes("ALL") && totalSpend.length > 0) {
            if (totalSpend.includes("NOSPEND")) {
                filterParams += "&has_spend_in_past_30_days=false";
            } else {
                filterParams += "&has_spend_in_past_30_days=true";
            }
            filterCounter++;
        }

        if (usageTypeSelected.value !== "all") {
            filterParams += `&usage_percentage_above=${usageTypeSelected.value}`;
            filterCounter++;
        }
        updateTable(filterParams);

        showFilters = false;
    }
</script>

<div
    class="filter-button"
    class:filters-active={filterCounter > 0}
    on:click={toggleFilters}
    on:keyup
    tabindex="0"
    role="button"
>
    <Icon name="sliders" size={16} />
    <span>{$_("filters.filters")}</span>
    {#if filterCounter > 0}
        <div class="filter-counter">{filterCounter}</div>
    {/if}
</div>

{#if showFilters}
    <FiltersSidebar>
        <div slot="header" class="filter-header">
            <div>
                <span>{$_("filters.filtersFor")}</span>
                <span class="header-subtext">{$_("riskManagement.creditInsights")}</span>
            </div>
            <div class="close">
                <button on:click={toggleFilters}>
                    <Icon name="cross" size={32} />
                </button>
            </div>
        </div>
        <div slot="main">
            <div class="filter-item">
                <span class="filter-item-header-text"
                    >{$_("riskManagement.filters.creditUsage")}</span
                >
                <div class="filter-items-radio">
                    {#each creditUsageOptions as usageItem}
                        <Radio
                            bind:group={usageTypeSelected}
                            option={usageItem}
                            id={usageItem.label}
                        />
                    {/each}
                </div>
            </div>
            <div class="filter-item">
                <span class="filter-item-header-text"
                    >{$_("riskManagement.filters.limitSetBy")}</span
                >
                <div class="filter-items-options">
                    {#each limitSetByOptions as limitItem}
                        <CheckboxItem
                            statusItem={limitItem}
                            isChecked={limitSetBy.includes(limitItem.name.toUpperCase()) ||
                                limitSetBy.includes("ALL")}
                            on:checkedItem={filterLimits}
                        />
                    {/each}
                </div>
            </div>
            <div class="filter-item last-item">
                <span class="filter-item-header-text"
                    >{$_("riskManagement.creditInsightsTable.total_spend_last_30_days")}</span
                >
                <div class="filter-items-options">
                    {#each totalSpendOptions as totalSpendItem}
                        <CheckboxItem
                            statusItem={totalSpendItem}
                            isChecked={totalSpend.includes(totalSpendItem.name.toUpperCase()) ||
                                totalSpend.includes("ALL")}
                            on:checkedItem={filterTotalSpend}
                        />
                    {/each}
                </div>
            </div>
        </div>
        <div slot="footer" class="filter-footer">
            <div class="flex gap-2">
                <TwoUIButton
                    on:click={applyFilters}
                    size="md"
                    variant="primary"
                    content={$_("filters.applyFilters")}
                />
                <TwoUIButton
                    on:click={clearFilters}
                    size="md"
                    variant="secondaryGray"
                    content={$_("filters.clearAll")}
                />
            </div>
        </div>
    </FiltersSidebar>
{/if}
