<script lang="ts">
    import { _ } from "svelte-i18n";
    import Fa from "svelte-fa";
    import { Button, Tooltip } from "@two-ui/core";
    import { faXmark, faCheck, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
    import { faClone } from "@fortawesome/free-regular-svg-icons";

    import FiltersSidebar from "@/components/filters/FiltersSidebar.svelte";
    import environment from "@/services/environment";

    export let toggleHigherLimitSidebar;

    const CREDIT_BUILDER_LINK = "https://checkout.two.inc/credit_limit_change/credit_builder";
    let isCopiedTooltipOpened = false;

    function copyItem(item) {
        isCopiedTooltipOpened = true;
        navigator.clipboard.writeText(item);
        setTimeout(() => {
            isCopiedTooltipOpened = false;
        }, 3000);
    }
</script>

<FiltersSidebar>
    <div slot="header" class="filter-header">
        <div>
            {$_("customer.creditLimit.requestHigherLimit")}
        </div>
        <div class="close">
            <button on:click={toggleHigherLimitSidebar}>
                <Fa size="md" icon={faXmark} color={"var(--gray-400)"} />
            </button>
        </div>
    </div>
    <div slot="main">
        <p class="font-bold text-lg">{$_("customer.creditLimit.emailCustomer")}</p>
        <p class="mt-4">{$_("customer.creditLimit.description")}</p>
        <p class="flex mt-4">
            <Fa
                class="mr-3 mt-0.5"
                size="lg"
                icon={faCheck}
                color={"var(--ctnSemanticHighlightPrimary)"}
            />
            {$_("customer.creditLimit.accurateCreditLimits")}
        </p>
        <p class="flex mt-1">
            <Fa
                class="mr-3 mt-0.5"
                size="lg"
                icon={faCheck}
                color={"var(--ctnSemanticHighlightPrimary)"}
            />
            {$_("customer.creditLimit.speedyReview")}
        </p>
        <div class="mt-4 p-4 rounded-lg bg-Background-Semantic-Info-Ultra-Light">
            <span class="font-medium text-base">{$_("customer.creditLimit.sendLink")}</span>
            <div class="flex items-center">
                <a class="underline mr-2" target="_blank" href={CREDIT_BUILDER_LINK}
                    >{CREDIT_BUILDER_LINK}</a
                >
                <Tooltip
                    opened={isCopiedTooltipOpened}
                    label={$_("customer.creditLimit.linkCopied")}
                    position="bottom"
                >
                    <button
                        id="copy-credit-builder-link"
                        on:click={() => copyItem(CREDIT_BUILDER_LINK)}
                    >
                        <Fa class="cursor-pointer" icon={faClone} color="var(--gray-400)" />
                    </button>
                </Tooltip>
            </div>
        </div>

        <div class="mt-4 flex items-center">
            <Fa
                class="mr-4"
                size="lg"
                icon={faCircleInfo}
                color={"var(--ctnSemanticInfoPrimary)"}
            />
            <p>
                {$_("customer.creditLimit.disclaimer", {
                    values: { displayName: environment.branding.displayName }
                })}
            </p>
        </div>
    </div>
    <div slot="footer">
        <Button
            on:click={toggleHigherLimitSidebar}
            variant="primary"
            size="lg"
            content={$_("components.close")}
        />
    </div>
</FiltersSidebar>
