<script lang="ts">
    import { _ } from "svelte-i18n";
    import { capitalize } from "lodash";

    import { getBaseUrl } from "../../utils/functions";
    import { GET_ALL_ORDERS } from "../../static/endPoints";
    import API from "../../services/apiService";
    import modalState from "../../store/modals.store";
    import notificationState from "../../store/notifications.store";
    import { Button as TwoUIButton } from "@two-ui/core";
    import environment from "@/services/environment";
    import { NotificationType } from "../../static/types";

    export let valueType = "";
    export let value = "";
    export let companyName = "";

    let isLoading: boolean = false;

    const handleRemove = () => {
        const baseUrl = getBaseUrl();
        isLoading = true;

        API.get(`${baseUrl}${GET_ALL_ORDERS}`)
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("riskManagement.fraudRules.valueRemoved", {
                        values: { valueType: capitalize(valueType) }
                    }),
                    null,
                    $_("riskManagement.fraudRules.youHaveRemoved", {
                        values: { valueType, value }
                    })
                );

                handleCancelModal();
            })
            .catch((err) => {
                const errorMessage =
                    err.response?.data?.error_message || $_("errors.somethingWrong");
                notificationState.actions.create(NotificationType.ERROR, errorMessage);
            })
            .finally(() => {
                isLoading = false;
            });
    };

    const handleCancelModal = () => {
        modalState.actions.setModal(null, {});
    };

    $: {
        valueType = valueType.toLowerCase();
    }
    $: if (companyName) {
        value = `${value} - ${companyName}`;
    }
</script>

<div class="container">
    <div class="flex title">
        <div class="ctn-general-primary">
            {$_("riskManagement.fraudRules.removeThisFromWhitelist", { values: { valueType } })}
        </div>
    </div>
    <div class="body-container">
        <div class="body-text">
            {$_("riskManagement.fraudRules.removeThisFromWhitelistText", {
                values: { valueType, value, brandName: environment.branding.displayName }
            })}
        </div>
    </div>
    <div class="button-area">
        <div class="right-align">
            <TwoUIButton
                on:click={handleCancelModal}
                size="md"
                variant="secondaryGray"
                content={$_("components.cancel")}
                fullSize={true}
            />
        </div>
        <div>
            <TwoUIButton
                id="fraud-rule-remove-btn"
                on:click={handleRemove}
                size="md"
                variant="primary"
                content={$_("components.remove")}
                fullSize={true}
                disabled={isLoading}
            />
        </div>
    </div>
</div>

<style lang="scss">
    .container {
        display: flex;
        flex-direction: column;
        padding: 24px 32px 24px 32px;
        max-height: 450px;
        width: 544px;
    }

    .title {
        display: flex;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        padding-bottom: 16px;
    }

    .body-container {
        overflow: auto;
        margin-bottom: 1rem;
        flex-grow: 1;
    }

    .body-text {
        color: var(--ctnGeneralSecondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 24px;
    }

    .button-area {
        display: flex;
    }

    .right-align {
        margin-left: auto;
        margin-right: 8px;
    }
</style>
