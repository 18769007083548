<script lang="ts">
    import { createEventDispatcher, onMount } from "svelte";
    import { _ } from "svelte-i18n";

    import { COUNTRIES } from "../../static/constant";
    import type countryName from "../../static/countryName";
    import Selector from "../Elements/Selector.svelte";

    export let label: string = "Country";
    export let required: boolean = false;
    export let initialCountryCode: keyof typeof countryName | null = null;
    export let size: string = "48px";
    export let showNotOnList: boolean = false;
    export let excludeCountries: string[] = [];
    export let countries = COUNTRIES;
    export let notOnListText: string = $_("login.countryIsNotInList");
    export let notOnListTextColor: string = "black";

    const options = Object.values(countries)
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((country) => !excludeCountries.includes(country.country_code));

    const optionsItem = options.map(
        (option) =>
            `<div class="flex items-center" style="gap:0.5rem"><p style="font-size:20px">${getFlagEmoji(
                option.country_code
            )}</p><p>${option.name}</p></div>`
    );

    function getFlagEmoji(countryCode: string) {
        const codePoints = countryCode
            .toUpperCase()
            .split("")
            .map((char) => 127397 + char.charCodeAt(0));
        return String.fromCodePoint(...codePoints);
    }

    const dispatch = createEventDispatcher();

    let selectedCountry: string = initialCountryCode
        ? selectCountry(initialCountryCode)
        : optionsItem[0];

    export let country: any = options[optionsItem.indexOf(selectedCountry)] || options[0];

    export function selectCountry(countryCode) {
        const optionIndex = options.findIndex((o) => o.country_code === countryCode);
        let chosenCountry = {};
        if (optionIndex >= 0) {
            chosenCountry = optionsItem[optionIndex];
        } else {
            chosenCountry = optionsItem[0];
        }
        return chosenCountry as string;
    }

    $: country = options[optionsItem.indexOf(selectedCountry)];

    onMount(() => {
        let countryCode = initialCountryCode;
        if (countryCode === null) {
            fetch("https://api.db-ip.com/v2/free/self")
                .then((res) => res.json())
                .then((response) => {
                    countryCode = response.countryCode;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => (selectedCountry = selectCountry(countryCode)));
        }
        if (showNotOnList) {
            optionsItem.push(`<div style="color:${notOnListTextColor}">${notOnListText}</p></div>`);
        }
    });
</script>

<Selector
    id="country-selector"
    {label}
    options={optionsItem}
    {size}
    bind:value={selectedCountry}
    description=""
    invalid=""
    {required}
    showArrow={true}
    on:change={(e) => {
        dispatch("change", e);
    }}
/>
