<script lang="ts">
    import { _ } from "svelte-i18n";

    import Icon from "../../components/icons/Icon.svelte";

    export let numRows: number = 10;
    export let numAllRecords: number = 0;
    export let pageNum: number = 1;
    export let numPages: number = 1;

    function prevPage() {
        if (pageNum > 1) {
            pageNum--;
        }
    }

    function nextPage() {
        if (pageNum < numPages) {
            pageNum++;
        }
    }
</script>

<div class="py-4 flex justify-end page-counter justify-center sticky bottom-0 z-2 bg-white">
    <!-- Pagination Information -->
    <span class="mr-4">
        {`${numRows * pageNum - numRows + 1}-${Math.min(
            numRows * pageNum,
            numAllRecords
        )} of ${numAllRecords}`}
    </span>

    <!-- Previous Page -->
    <div
        class="cursor-pointer"
        class:disabled={pageNum === 1}
        on:click={() => prevPage()}
        on:keypress={() => prevPage()}
        tabindex="-2"
        role="button"
    >
        <Icon
            name="arrow-left"
            size={16}
            fill={pageNum === 1 ? "var(--gray-300)" : "var(--gray-500)"}
        />
    </div>

    <!-- Page Indicator -->
    <span class="px-1">
        {$_("components.table.pageNumOfPages", { values: { pageNum, amount: numPages } })}
    </span>

    <!-- Next Page -->
    <div
        class="cursor-pointer flip-icon"
        class:disabled={pageNum === numPages}
        on:click={() => nextPage()}
        on:keypress={() => nextPage()}
        tabindex="-2"
        role="button"
    >
        <Icon
            name="arrow-left"
            size={16}
            fill={pageNum === numPages ? "var(--gray-300)" : "var(--gray-500)"}
        />
    </div>
</div>

<style lang="scss">
    .page-counter {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--gray-700);
        user-select: none;
        box-shadow: 0px -5px 5px #eee;
        z-index: 10;
    }

    .cursor-pointer {
        cursor: pointer;
        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    .flip-icon {
        transform: scaleX(-1);
    }
</style>
