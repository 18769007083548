/* eslint-disable */
import { writable } from "svelte/store";
import { GET_DASHBOARD } from "../../static/endPoints";
import API from "../../services/apiService";
import { DateToFormat } from "../../utils/functions";
import type { IDashboardData } from "../../static/types";
import { dashboardData } from "../../static/signupDemoMockResponses";

export const dashboard = writable(<IDashboardData>{});
export const loading = writable(false);
export const dashboardMarkets = writable([]);

function createDashboard() {
    return {
        actions: {
            getDashboard: (
                start: Date,
                end: Date,
                signupComplete?: boolean,
                selectedCurrenciesQueryStr?: string | null,
                accountCurrency?: string
            ) => {
                loading.set(true);
                if (!signupComplete) {
                    let data = dashboardData(start, end, accountCurrency);
                    dashboard.set(data);
                    loading.set(false);
                } else {
                    let dashboard_path = `${GET_DASHBOARD}?start_date=${DateToFormat(
                        start
                    )}&end_date=${DateToFormat(end)}`;
                    if (selectedCurrenciesQueryStr) {
                        dashboard_path = dashboard_path + `&currency=${selectedCurrenciesQueryStr}`;
                    }

                    API.get(dashboard_path)
                        .then((res: IDashboardData) => {
                            dashboard.set(res);
                            loading.set(false);
                        })
                        .catch(() => {
                            loading.set(false);
                        });
                }
            }
        }
    };
}

const dashboardState: any = createDashboard();
export default dashboardState;
