<script lang="ts">
    import { _ } from "svelte-i18n";
    import Fa from "svelte-fa";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
    import { Button as TwoUIButton } from "@two-ui/core";

    import modalState from "../../../store/modals.store";
    import { ADD_CUSTOMER_MODAL } from "../../../static/modals";
    import CombinedCustomersTable from "./CombinedCustomersTable.svelte";
    import Button from "../../../components/Elements/Button.svelte";
    import {
        account,
        recourseLimitConfig,
        loggedInAsMerchant,
        signupComplete,
        recourseLoading,
        recourseEnabled
    } from "../../../store/merchant/account.store";
    import {
        customersLoading,
        customersTab,
        actions as customerActions
    } from "../../../store/merchant/customers.store";
    import Search from "../../../components/search/Search.svelte";
    import ToolTip from "../../../components/tooltip/ToolTip.svelte";
    import PageHeader from "../../../components/pageHeader/PageHeader.svelte";
    import utils, { type CurrencyFormatOptions } from "../../../utilsV2/currency";
    import { getInitialRecourseLimitsFromConfig } from "../../../utils/functions";
    import helpers from "../../../utilsV2/helpers";
    import environment from "@/services/environment";
    import SignupIncompleteModal from "../../../components/modals/AccountSetup/SignupIncompleteModal.svelte";
    import * as helpLinks from "../../../services/helpCentreService";

    const helpMenuLinks = {
        [$_("helpLinks.customerTabFaq")]: helpLinks.CUSTOMER_TAB_FAQ,
        [$_("helpLinks.creditCheckFaq")]: helpLinks.CREDIT_CHECK_FAQ,
        [$_("helpLinks.paymentTermsFaq")]: helpLinks.PAYMENT_TERMS_FAQ,
        [$_("helpLinks.customerRecourseFaq")]: helpLinks.CUSTOMER_RECOURSE_FAQ,
        [$_("helpLinks.fraudCheckFaq")]: helpLinks.FRAUD_CHECK_FAQ,
        [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
            environment.branding.aboutLink
    };

    const TABS = [
        {
            key: "All",
            value: $_("customers.all"),
            title: $_("customers.tabs.all.title"),
            description: $_("customers.tabs.all.summary")
        }
    ];
    if (!environment.branding.hideOnRecourse) {
        TABS.push({
            key: "On recourse",
            value: $_("customers.onRecourse"),
            title: $_("customers.tabs.recourse.title"),
            description: $_("customers.tabs.recourse.summary")
        });
    }

    let searchValue: string = "";
    let selectedTab: string = TABS[0].key;
    const recourseTabLabel: string = TABS[1]?.key;
    let recourseRelatedData: any;
    let countryCode: string = "";
    let pageNum = 1;
    const buttonColor = "var(--primary-700)";

    $: selectedTab, ((searchValue = ""), getCustomers());

    $: $loggedInAsMerchant, getCustomers();

    $: if ($recourseLimitConfig && !$recourseLoading) {
        // only if the recourseLimitConfig state is loaded
        recourseRelatedData = { ...getInitialRecourseLimitsFromConfig($recourseLimitConfig) };
    }

    $: selectedTab = $customersTab;

    $: if ($account?.countryCode) {
        countryCode = $account.countryCode;
    }

    const formatMoney = (money: string | number, options?: CurrencyFormatOptions) =>
        utils.formatCurrency(recourseRelatedData?.recourseCurrency)(money, options);

    const addNewCustomer = () => {
        modalState.actions.setModal(ADD_CUSTOMER_MODAL, {});
    };

    function setTab(tabItem) {
        !$customersLoading && customerActions.setCustomersTab(tabItem);
    }

    function setSearch(input: InputEvent | string): void {
        if (input instanceof InputEvent) {
            const inputE = input.target as HTMLInputElement;
            searchValue = inputE.value;
        } else {
            searchValue = input;
        }
        handleSearchChanged();
    }

    const handleSearchChanged = helpers.debounce(() => {
        getCustomers();
        pageNum = 1;
    }, 400);

    function pageUpdated(event) {
        getCustomers(event.detail.pageReference);
        pageNum = event.detail.pageNum;
    }

    function getCustomers(cursor: string = null): void {
        if ($loggedInAsMerchant) {
            customerActions.getCustomers(searchValue, selectedTab == recourseTabLabel, cursor);
            pageNum = 1;
        }
    }

    function handleClickPostAction(action) {
        if ($signupComplete) {
            return action();
        }
        modalState.actions.setModal("", {}, SignupIncompleteModal);
    }
</script>

<div class="page-container">
    <PageHeader
        title={$_("customers.pageTitle")}
        newUser={!$account?.flags?.has_made_order}
        {helpMenuLinks}
    >
        <div slot="cta" class="flex">
            {#if !environment.branding.hideCustomerConfig}
                <div class="w-40 ml-4">
                    <TwoUIButton
                        on:click={() => handleClickPostAction(addNewCustomer)}
                        size="md"
                        variant="primary"
                        content={$_("components.addCustomerModal.addCustomer")}
                        fullSize={true}
                    />
                </div>
            {/if}
        </div>
        <div slot="tabs" class="tab-headers">
            {#if $recourseEnabled}
                {#each TABS as tab}
                    <div
                        class="cursor-pointer px-6 mr-2 h-10 tab-header"
                        on:keydown={() => setTab(tab.key)}
                        on:click={() => setTab(tab.key)}
                        class:selected={selectedTab === tab.key}
                    >
                        {tab.value}
                    </div>
                {/each}
            {/if}
        </div>
    </PageHeader>
    <div class="content-container">
        {#if selectedTab == recourseTabLabel}
            <div class="rounded-lg bg-white mx-8 mt-6 p-6">
                <h3 class="text-md text-black font-bold">
                    {@html $_("customers.recourseFallbackDetailsTitle")}
                </h3>
                <div class="flex items-center justify-between mt-4 w-2/3 sm:gap-4">
                    <div class="flex flex-col">
                        <p class="text-lg font-bold">
                            {formatMoney(recourseRelatedData?.totalRecourseLimit ?? "")}
                        </p>
                        <p class="text-sm text-gray-100">
                            {$_("customers.totalRecourseLimit", {
                                values: { brandName: environment.branding.displayName }
                            })}
                        </p>
                    </div>
                    <div class="flex flex-col">
                        <p class="text-lg font-bold">
                            {formatMoney(recourseRelatedData?.currentRecourseExposure ?? "")}
                        </p>
                        <p class="text-sm text-gray-100">
                            {$_("customers.currentRecourseExposure")}
                        </p>
                    </div>
                    <div class="flex flex-col">
                        <p class="text-lg font-bold">
                            {formatMoney(recourseRelatedData?.remainingRecourseLimit ?? "")}
                        </p>
                        <p class="text-sm text-gray-100">
                            {$_("customers.remainingRecourseLimit")}
                        </p>
                    </div>
                </div>
            </div>
        {/if}

        <div class="rounded-lg bg-white customers-summary">
            <div class="flex items-center justify-between mx-8 pt-4 pb-6">
                <div class="flex w-1/3 max-w-7xl">
                    <p class="text-lg font-bold">
                        {TABS.find(({ key }) => key === selectedTab)?.title}
                    </p>
                    <span class="tooltip-container" style="margin-top: 5px;">
                        <ToolTip
                            width="250px"
                            position="right"
                            content={TABS.find(({ key }) => key === selectedTab)?.description}
                        >
                            <Fa size="md" icon={faCircleQuestion} color="var(--primary-500)" />
                        </ToolTip>
                    </span>
                </div>
                <div class="flex w-2/3">
                    <div class="flex w-full justify-end sm:gap-4">
                        <div class="flex w-2/3 items-end">
                            <Search
                                id="search-customers"
                                bind:searchValue
                                {setSearch}
                                placeholder={$_("customers.searchPlaceholder")}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CombinedCustomersTable
                {selectedTab}
                {recourseRelatedData}
                on:pagechanged={pageUpdated}
                bind:pageNum
            />
        </div>
    </div>
</div>

<style>
    .tooltip-container {
        margin: auto 0.5rem;
    }

    .customers-summary {
        display: flex;
        overflow: auto;
        flex-direction: column;
        margin: 1.5rem 2rem;
        max-height: 80vh;
    }

    .tab-headers {
        height: 2.5rem;
        display: flex;
        box-sizing: border-box;
    }

    .tab-header:hover {
        background-color: var(--bgInteractiveSemanticHighlightHoverSecondary);
        border-radius: 8px 8px 0px 0px;
    }

    .tab-header {
        line-height: 2.5rem;
        font-size: 14px;
    }

    .content-container {
        grid-row-gap: 0;
    }
</style>
