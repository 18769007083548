<script lang="ts">
    export let statusText: string;
    export let backgroundColor: string;
    export let textColor: string = null;
</script>

<div
    class="status-container"
    style="background-color: {backgroundColor};{textColor
        ? `color: ${textColor};`
        : ''};width: fit-content"
>
    {statusText}
</div>

<style lang="scss">
    .status-container {
        font-feature-settings:
            "clig" off,
            "liga" off;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.125rem;
        border-radius: 16px;
        padding: 2px 8px;
        justify-content: center;
        align-items: center;
    }
</style>
