<script lang="ts">
    import Fa from "svelte-fa";
    import { _ } from "svelte-i18n";
    import { faXmark } from "@fortawesome/free-solid-svg-icons";
    import { Button } from "@two-ui/core";

    import FiltersSidebar from "@/components/filters/FiltersSidebar.svelte";
    import type { ICustomer } from "@/static/types";
    import { titleCase } from "@/utilsV2/helpers";
    import environment from "@/services/environment";

    export let selectedCustomer: ICustomer;
    export let toggleMoreVolumeSidebar;

    const GOOGLE_FORM_LINK =
        "https://docs.google.com/forms/d/e/1FAIpQLScE9VfDdzN2QTJcKeiN8iHxng72lgHNXTCRrDfazgG2hZ-1qA/viewform?usp=header";
</script>

<FiltersSidebar>
    <div slot="header" class="filter-header">
        <div>
            <span>{$_("customer.creditLimit.unlockMoreVolume")}</span>
        </div>
        <div class="close pt-1">
            <button on:click={toggleMoreVolumeSidebar}>
                <Fa icon={faXmark} size="md" color="var(--gray-500)" class="cursor-pointer" />
            </button>
        </div>
    </div>
    <div slot="main">
        <!-- Check if merchant recourse fallback is enabled on BE -->
        <p class="text-lg font-bold mb-4">{$_("customers.combined.settings.requestToEnable")}</p>
        <div class="filter-item border-b-0 mb-0">
            <span>
                {@html $_("customers.combined.settings.moreVolumeText", {
                    values: {
                        customerName: titleCase([selectedCustomer?.buyer_company_name])[0],
                        brandName: environment.branding.displayName
                    }
                })}
            </span>
            <span class="mt-4">
                {@html $_("customers.combined.settings.sendRequestText")}
            </span>
        </div>
    </div>
    <div slot="footer" class="filter-footer">
        <Button
            size="lg"
            variant="primary"
            content={$_("customers.combined.settings.sendRequest")}
            on:click={() => window.open(GOOGLE_FORM_LINK, "_blank")}
        />

        <Button
            size="lg"
            on:click={toggleMoreVolumeSidebar}
            variant="secondaryGray"
            content={$_("components.cancel")}
        />
    </div>
</FiltersSidebar>
