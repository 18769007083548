<script lang="ts">
    import { _ } from "svelte-i18n";
    import type {
        CurrencyCode,
        ICountry,
        IMerchantRecourseLimit,
        IMerchantRemainingRecourseLimit
    } from "@/static/types";
    import { onMount, onDestroy } from "svelte";

    import type countryName from "@/static/countryName";
    import { account, recourseLimitConfig } from "@/store/merchant/account.store";
    import {
        actions,
        creditLimitState,
        creditLimitLoading
    } from "@/store/merchant/creditlimit.store";
    import modalState from "@/store/modals.store";
    import orderCreateStore, { selectedCompanyName } from "@/store/merchant/orderCreate.store";
    import { COUNTRIES } from "@/static/constant";
    import { buyerDetails, orderReplica } from "@/store/merchant/allOrders.store";
    import CompanySelection from "@/pages/merchant/orders/create/CompanySelection.svelte";
    import RepresentativeDetails from "@/pages/merchant/orders/create/RepresentativeDetails.svelte";
    import RemainingLimits from "./RemainingLimits.svelte";
    import RepaymentMethod from "./RepaymentMethod.svelte";

    export let companyID: string = "";
    export let companyExternalID = "";
    export let getLongTermLimits: boolean = false;
    export let availableCreditLimit: number;
    export let maxOrderValue: number;
    export let buyerFallbackEnabled: boolean;
    export let fallbackThreshold: string;
    export let merchantRecourseLimitValue: number;
    export let currency: CurrencyCode;
    export let payment_terms: string;

    let countryCode: keyof typeof countryName;
    let companySearchText: string = "";
    $: companySearchText = $selectedCompanyName;

    let merchantRecourseLimit: IMerchantRecourseLimit;
    let merchantRemainingRecourseLimits: IMerchantRemainingRecourseLimit[];

    function getCreditLimit() {
        actions.resetState();
        if (!$creditLimitLoading && companyID && countryCode) {
            actions.getLongTermCreditLimit(companyID, countryCode, getLongTermLimits);
        }
    }

    $: if ($recourseLimitConfig) {
        // only if the recourseLimitConfig state is loaded
        merchantRecourseLimit = $recourseLimitConfig?.merchant_recourse_limits?.[0];
        merchantRemainingRecourseLimits = $recourseLimitConfig?.merchant_remaining_recourse_limit;
    }

    $: merchantRecourseLimitValue = merchantRecourseLimit?.recourse_fallback_enabled
        ? parseFloat(
              (
                  merchantRemainingRecourseLimits.filter(
                      (x) => x.currency == merchantRecourseLimit?.currency
                  )[0]?.remaining_recourse_limit || 0
              ).toString()
          )
        : 0;

    $: if (companyID) {
        getCreditLimit();
    }

    $: if (!$creditLimitLoading && $creditLimitState?.success) {
        availableCreditLimit = $creditLimitState.creditLimit.availableCreditLimit;
        maxOrderValue = $creditLimitState.creditLimit.maxSingleOrderValue;
        buyerFallbackEnabled = $creditLimitState.creditLimit.buyerFallbackEnabled;
        fallbackThreshold = $creditLimitState.creditLimit.fallbackThreshold;
    }

    $: orderCreateStore.country.subscribe((country: ICountry) => {
        if (country) {
            currency = country.currency;
            countryCode = country.country_code;
        }
    });

    export let address: any = {};
    export let representative: {
        first_name: string;
        last_name: string;
        phone_number: string;
        email: string;
    } = {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: ""
    };
    export let invalid = {
        selectCompany: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        orderEmail: "",
        payment_terms: ""
    };

    export let invoiceType: string = "FUNDED_INVOICE";

    let phoneNumberWithCode: string = "+44";
    let phoneNumber: string = "";
    let phoneInput;

    $: if (phoneNumberWithCode) {
        phoneNumberChanged();
    }

    $: if ($account?.countryCode) {
        phoneInput?.setCountry($account.countryCode);
    }

    onMount(() => {
        document.getElementsByClassName("iti__selected-flag")[0].setAttribute("tabindex", "-1");
        // Apply replicated order details
        if ($orderReplica) {
            applyReplicatedOrder();
        } else if ($buyerDetails) {
            applyBuyerDetails();
        }
    });

    onDestroy(() => {
        buyerDetails.set(null);
    });

    function applyReplicatedOrder() {
        companySearchText = $orderReplica.companyName;
        // fill user representative details
        const [firstName, lastName] = $orderReplica.buyerName.split(" ");
        representative.first_name = firstName;
        representative.last_name = lastName;
        phoneNumberWithCode = $orderReplica.phone;
        representative.email = $orderReplica.email;
    }

    function applyBuyerDetails() {
        companySearchText = $buyerDetails.companyName;
    }

    function countryUpdated() {
        if (!$orderReplica) {
            phoneInput?.setCountry(countryCode);
            phoneNumberWithCode = COUNTRIES[countryCode]?.phoneCountryCode ?? "" + phoneNumber;
        }
        if (countryCode !== "NO" && $account.countryCode === "NO") {
            invoiceType = "DIRECT_INVOICE";
            modalState.actions.setModal("CROSS_BORDER_ORDER", {});
        }
    }

    function onCurrencyChange() {
        if (companyID) {
            getCreditLimit();
        }
    }

    function phoneNumberChanged() {
        representative.phone_number = phoneNumberWithCode;
    }
</script>

<div>
    <p class="text-base font-bold mr-3 mt-2">{$_("oc.orderTo")}:</p>
    <div>
        <CompanySelection
            {countryUpdated}
            {companySearchText}
            {onCurrencyChange}
            bind:countryCode
            bind:currency
            bind:address
            bind:companyID
            bind:companyExternalID
            invalidSelectCompany={invalid.selectCompany}
        />
        <!-- divider -->
        <div class="border-t border-Border-General-Primary my-4" />

        <div class="flex justify-between mb-8">
            <div class="flex mt-4">
                <RepaymentMethod invalidDueInDays={invalid.payment_terms} bind:payment_terms />
            </div>

            <div class="flex mt-4 justify-end">
                <RemainingLimits
                    companyInfoLoading={$creditLimitLoading}
                    companyInfo={$creditLimitState?.creditLimit}
                    {currency}
                />
            </div>
        </div>

        <!-- divider -->
        <div class="border-t border-Border-General-Primary my-4" />

        <RepresentativeDetails
            bind:representative
            bind:invalid
            bind:phoneNumberWithCode
            bind:phoneNumber
            {phoneInput}
        />
    </div>

    <div class="border-t border-Border-General-Primary my-4" />
</div>

<style>
    * :global(#company) {
        font-size: 16px;
    }
</style>
