<script lang="ts">
    import { createEventDispatcher, onMount } from "svelte";
    import Calender from "./Calender.svelte";
    import { getMonthName, isAfter2019 } from "../../utils/date-time";
    import { DateToFormat } from "../../utils/functions";
    import { onDestroy } from "svelte/internal";
    import { isValidDate } from "../../utils/validation";
    import Fa from "svelte-fa";
    import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

    export let isAllowed = () => true;
    export let selectedDate = new Date();
    export let minDate: Date | null = null;
    export let mode: string = "start";
    export let label: string = null;
    export let helperText = "";
    export let disable: boolean = false;
    export let topInPx = 45;

    const dispatch = createEventDispatcher();
    let calender: any;
    let picker: HTMLInputElement;
    let isDateCorrect: boolean = true;
    let month: number;
    let year: number;
    let showDatePicker: boolean;

    $: month = selectedDate.getMonth();
    $: year = selectedDate.getFullYear();

    // handlers
    const onFocus = () => {
        showDatePicker = true;
    };

    const next = () => {
        if (month === 11) {
            month = 0;
            year = year + 1;
            return;
        }
        month = month + 1;
    };

    const prev = () => {
        if (month === 0) {
            month = 11;
            year -= 1;
            return;
        }
        month -= 1;
    };

    const closeDown = (event) => {
        if (event.path && event.path[0] == picker) return;
        if (!calender?.contains(event.target)) {
            showDatePicker = false;
        }
    };

    onMount(() => {
        document.addEventListener("mousedown", closeDown);
    });

    onDestroy(() => {
        document.removeEventListener("mousedown", closeDown);
    });

    const onCalendarDateChange = (d) => {
        showDatePicker = false;
        isDateCorrect = isValidDate(d.detail) && isAfter2019(d.detail);
        dispatch("datechange", d.detail);
    };

    const onDateInputChange = (event) => {
        isDateCorrect = isValidDate(event.target.value) && isAfter2019(event.target.value);
        if (isDateCorrect) {
            dispatch("datechange", new Date(event.target.value));
        }
    };
</script>

<div class="relative" style="display:flex;flex-direction:column;gap:0.2rem">
    {#if label}
        <label
            for=""
            class="cursor-pointer text-gray-700 text-sm"
            class:label-focus={showDatePicker}>{label}</label
        >
    {/if}
    <div class="relative cursor-pointer">
        <input
            disabled={disable}
            style="width: 100%;"
            class:error={!isDateCorrect}
            bind:this={picker}
            type="date"
            on:focus={onFocus}
            value={DateToFormat(selectedDate)}
            on:change={onDateInputChange}
        />
        <img class="absolute calendar-icon" src="/pictures/calender.svg" alt="calender" />
    </div>
    {#if showDatePicker}
        <div
            class="absolute box z-50"
            style={`top: ${topInPx}px;`}
            bind:this={calender}
            class:right={mode === "end"}
        >
            <div class="flex items-center justify-between mx-8 mt-6">
                <div class="flex items-center justify-center my-0">
                    <button on:click={prev}>
                        <Fa
                            icon={faChevronLeft}
                            color={"var(--ctnInteractiveSemanticHighlightDefault)"}
                        />
                    </button>
                </div>
                <div class="font-bold center">{getMonthName(month)} {year}</div>
                <div class="center">
                    <button on:click={next}>
                        <Fa
                            icon={faChevronRight}
                            color={"var(--ctnInteractiveSemanticHighlightDefault)"}
                        />
                    </button>
                </div>
            </div>
            <Calender
                {month}
                {year}
                {minDate}
                date={selectedDate}
                {isAllowed}
                on:datechange={onCalendarDateChange}
            />
            {#if helperText}
                <p class="helper-text">{helperText}</p>
            {/if}
        </div>
    {/if}
</div>

<style>
    .label-focus {
        color: var(--primary);
    }
    .box {
        left: 0;
        display: inline-block;
        background-color: white;
        border-radius: 8px;
        border: 1px solid var(--gray-300);
    }
    .calendar-icon {
        top: 12px;
        left: 12px;
    }
    input {
        color: var(--gray-500);
        outline: none !important;
        background-color: white;
        border-radius: 8px;
        border: 1px solid;
        border-color: var(--gray-300);
        font-size: 14px;
        height: 44px;
        padding-left: 38px;
        cursor: pointer;
    }
    input:focus {
        border-color: var(--primary);
    }
    input.error {
        border-color: var(--semantic-error);
    }
    input:disabled {
        background-color: var(--bgInteractiveGeneralDisabled);
        border: 1px solid var(--ctnInteractiveGeneralDisabled);
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
    }
    @media only screen and (max-width: 600px) {
        input {
            width: 120px;
        }
        .right {
            right: 0;
            left: auto;
        }
    }
    .helper-text {
        text-align: center;
        color: var(--gray-500);
        font-size: 0.8rem;
        margin-bottom: 1rem;
    }
</style>
