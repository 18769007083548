import { writable } from "svelte/store";
import { NotificationType } from "../../static/types";
import notificationState from "../notifications.store";

import { GET_CREDIT_INSIGHTS } from "../../static/endPoints";
import API from "../../services/apiService";
import {
    adaptFraudDataToUI,
    type FraudListItem
} from "../../pages/merchant/risk-management/fraud/fraudDataAdaptor";

function createInsight() {
    const fraudInsightsList = writable<Array<FraudListItem>>([]);
    const isLoading = writable(false);

    const setInsightsLoading = (value: boolean): void => {
        isLoading.set(value);
    };

    return {
        fraudInsightsList,
        isLoading,
        actions: {
            getFraudInsights: (
                searchFilterQuery: string = null,
                filterQueryString: string = null,
                pageNum: number = 1
            ): void => {
                setInsightsLoading(true);
                const searchParams = new URLSearchParams();
                if (searchFilterQuery) {
                    searchParams.append("search_input", searchFilterQuery);
                }
                API.get(
                    GET_CREDIT_INSIGHTS +
                        "?" +
                        searchParams.toString() +
                        filterQueryString +
                        `&page=${pageNum}`
                )
                    .then((data) => {
                        fraudInsightsList.set(adaptFraudDataToUI());
                    })
                    .catch(() => {
                        notificationState.actions.create(
                            NotificationType.ERROR,
                            "Fraud insights get failed."
                        );
                    })
                    .finally(() => {
                        setInsightsLoading(false);
                    });
            }
        }
    };
}

export const { fraudInsightsList, isLoading, actions } = createInsight();
