<script lang="ts">
    import { _ } from "svelte-i18n";

    import Search from "@/components/search/Search.svelte";
    import FraudRulesTable from "./FraudRulesTable.svelte";
    import FraudRulesFilter from "@/components/filters/riskManagement/FraudRulesFilter.svelte";
    import { loggedInAsMerchant } from "@/store/merchant/account.store";
    import { actions as insightActions } from "@/store/merchant/creditInsights.store";

    let searchValue: string = "";
    let filterQueryString: string = "";

    function setSearch(input: InputEvent | string): void {
        if (input instanceof InputEvent) {
            const inputE = input.target as HTMLInputElement;
            searchValue = inputE.value;
        } else {
            searchValue = input;
        }
        getInsights();
    }

    const filtersUpdated = (filterQuery: string) => {
        filterQueryString = filterQuery;
        getInsights();
    };

    function getInsights() {
        if ($loggedInAsMerchant) {
            insightActions.getInsights(searchValue, filterQueryString);
        }
    }
</script>

<div id="credit-insights" class="rounded-lg bg-white mx-8 mt-6 mb-8">
    <div class="table-header-bar flex justify-between items-center content-center">
        <div>
            <p class="table-title text-lg font-bold">
                {$_("riskManagement.fraudRules.rulesTitle")}
            </p>
            <span>{$_("riskManagement.fraudRules.description")}</span>
        </div>
        <div class="w-[50%] flex gap-2">
            <Search
                id="search-fraud-rules"
                bind:searchValue
                {setSearch}
                placeholder={$_("riskManagement.fraudRules.searchRules")}
            />

            <FraudRulesFilter updateTable={filtersUpdated} />
        </div>
    </div>
    <FraudRulesTable />
</div>

<style lang="scss">
    .table-title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        color: var(--ctnGeneralPrimary);
    }
    .table-header-bar div {
        margin: 1.25rem 2rem 1.25rem 2rem;
    }
</style>
