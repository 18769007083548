<script lang="ts">
    import { _ } from "svelte-i18n";

    import { onDestroy, onMount, createEventDispatcher } from "svelte";
    import { focusTrap } from "../../utilsV2/focusTrap";
    import Icon from "../icons/Icon.svelte";
    import Button from "./Button.svelte";

    export let show = false;
    export let title = "";
    const dispatch = createEventDispatcher();
    let ref: HTMLDivElement;

    onMount(createModal);

    onDestroy(destroyModal);

    function createModal() {
        document.body.appendChild(ref);
    }

    function destroyModal() {
        document.body.removeChild(ref);
    }

    function closeModal() {
        show = false;
        dispatch("close");
    }
</script>

<div class="portal" bind:this={ref}>
    {#if show}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="background" on:click={closeModal} tabindex="-2">
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <div class="modal" use:focusTrap on:click|stopPropagation>
                <slot name="header">
                    <div class="header">
                        <p>{title}</p>
                        <button class="icon-button" on:click={closeModal}>
                            <Icon name="close2" size={24} fill="#2F384C" />
                        </button>
                    </div>
                </slot>
                <slot />
                <div class="footer">
                    <slot name="footer">
                        <button
                            class="button-secondary-gray button-sm cancel"
                            on:click={closeModal}
                        >
                            {$_("components.cancel")}
                        </button>
                        <Button label="confirm" size="medium" paddingX="16px" />
                    </slot>
                </div>
            </div>
        </div>
    {/if}
</div>

<!-- Prevent scroll in background to focus user attention on modal -->
<svelte:head>
    {#if show}
        <style>
            body {
                overflow: hidden;
            }
        </style>
    {/if}
</svelte:head>

<style lang="scss">
    * {
        box-sizing: border-box;
        --modal-bg-opacity: 1;
        --modal-offset-shadow: 0 0 #0000;
        --modal-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    }
    .background {
        background-color: rgba(0, 0, 0, 0.7);
        position: fixed;
        top: 0px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* z-index of SideNav and PageHeader */
        z-index: 110;
    }
    .modal {
        background-color: rgba(255, 255, 255, var(--modal-bg-opacity));
        padding-inline-start: 32px;
        padding-inline-end: 24px;
        padding-block: 24px;
        width: 100%;
        height: 100%;
        @media screen and (min-width: 650px) {
            height: unset;
            max-width: 544px;
            max-height: 100%;
            box-shadow: var(--modal-offset-shadow, 0 0 #0000), var(--modal-offset-shadow, 0 0 #0000),
                var(--modal-shadow);
            border-radius: 16px;
        }
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 1.5rem;
        line-height: 1.35;
        font-weight: 700;
        margin-block-end: 1rem;
    }
    .icon-button {
        margin-inline-start: 20px;
        padding: 5px;
        /* negative margins to align SVG without padding which gets added on hover */
        margin-block-start: -5px;
        margin-inline-end: -5px;
        border-radius: 100%;
        cursor: pointer;
    }
    .icon-button:hover {
        background-color: rgba(228, 231, 236, var(--modal-bg-opacity));
    }
    .footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 0.875rem;
        .cancel {
            margin-inline-end: 8px;
        }
    }
</style>
