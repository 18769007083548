<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Skeleton, ItemWithLabel } from "@two-ui/core";
    import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

    import utils from "@/utilsV2/currency";
    import SingleFraudInsightHeader from "./SingleFraudInsightHeader.svelte";

    export let isLoading = false;

    const ITEMS = [
        {
            label: $_("filters.orderValue"),
            value: utils.formatCurrency("NOK")(5010)
        },
        {
            label: $_("order.number"),
            value: "123-456"
        },
        {
            label: $_("account.document.status"),
            value: "Rejected"
        },
        {
            label: $_("date.dateAndTime"),
            value: "22/11/2024 12:41:05"
        }
    ];

    const REJECT_REASONS = [
        {
            title: "Suspicious email address contains numbers",
            description: "jamestrading460@hotmail.com"
        },
        {
            title: "The shipping address is a flagged address",
            description: "Storgata 15, 0161 Oslo, Norway"
        },
        {
            title: "Low online presence"
        }
    ];

    const VERIFICATION = [
        { label: $_("customer.accounts.verification"), value: "Vipps" },
        {
            label: $_("account.document.status"),
            value: "Verified",
            icon: faCircleCheck,
            iconColor: true
                ? "var(--ctnSemanticSuccessPrimary)"
                : "var(--ctnInteractiveGeneralDisabled)"
        },
        { label: $_("account.team.name"), value: "David Matthews" },
        { label: $_("customer.accounts.legalName"), value: "Not required" },
        { label: $_("order.details.phone_number"), value: "+41 3498734543" },
        { label: $_("components.inputType.email"), value: "dmatthews@bigspender.as" }
    ];

    const CUSTOMER_DETAILS = [
        {
            label: $_("order.details.representative"),
            value: "David Matthews"
        },
        { label: "", value: "" },
        {
            label: $_("components.input.phoneNumberLabel"),
            value: "+41 3498734543"
        },
        {
            label: $_("order.details.representative_email"),
            value: "dmatthews@bigspender.as"
        }
    ];
</script>

<section id="single-fraud-insight">
    {#if isLoading}
        <div class="px-6 py-4">
            {#each Array(3) as _}
                <Skeleton height={50} radius="md" class="mt-2" />
                <Skeleton height={100} radius="md" class="mt-2 mb-6" />
            {/each}
        </div>
    {:else}
        <SingleFraudInsightHeader />
        <!-- Order details -->
        <div class="mx-6 py-6 border-b border-Border-General-Primary">
            <div class="insight-header flex justify-between mb-4">
                <p class="text-lg font-bold">{$_("order.new.orderDetails")}</p>
                <span class="text-sm text-Content-Semantic-Highlight-Primary cursor-pointer mr-4"
                    >{$_("riskManagement.fraud.viewOrder")}</span
                >
            </div>
            <div class="order-details grid grid-cols-2 gap-4">
                {#each ITEMS as item}
                    <ItemWithLabel label={item.label} value={item.value} />
                {/each}
            </div>
        </div>
        <!-- Reject reasons -->
        <div class="rejected-reasons mx-6 py-6 border-b border-Border-General-Primary">
            <p class="text-lg font-bold mb-4">{$_("riskManagement.fraud.rejectReasons")}</p>
            <ul>
                {#each REJECT_REASONS as reason, index}
                    <li class={REJECT_REASONS.length === index + 1 ? "mb-0" : "mb-4"}>
                        <div class="font-medium">• {reason.title}</div>
                        {#if reason.description}
                            <div class="text-sm text-Content-General-Tertiary ml-3">
                                {reason.description}
                            </div>
                        {/if}
                    </li>
                {/each}
            </ul>
        </div>
        <!-- Verification -->
        <div class="verification mx-6 py-6 border-b border-Border-General-Primary">
            <p class="text-lg font-bold mb-4">{$_("customer.accounts.verification")}</p>
            <div class="verification-item grid grid-cols-2 gap-4">
                {#each VERIFICATION as item}
                    <ItemWithLabel {...item} />
                {/each}
            </div>
        </div>
        <!-- Customer details -->
        <div class="customer-details mx-6 py-6">
            <div class="flex justify-between mt-1 mb-4">
                <p class="text-lg font-bold">{$_("order.details.customer_details")}</p>
            </div>
            <div class="customer-details-items grid grid-cols-2 gap-4">
                {#each CUSTOMER_DETAILS as item}
                    <ItemWithLabel label={item.label} value={item.value} />
                {/each}
            </div>
        </div>
    {/if}
</section>
