<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";

    import { TextInput } from "@two-ui/core";
    import modalState from "../../../../store/modals.store";
    import { ORDER_MAIN, GET_LATENT_ORDER_DETAILS } from "../../../../static/endPoints";
    import { getBaseUrl } from "../../../../utils/functions";
    import API from "../../../../services/apiService";
    import notificationState from "../../../../store/notifications.store";
    import { NotificationType } from "../../../../static/types";
    import ToolTip from "../../../../components/tooltip/ToolTip.svelte";
    import { getTooltipDetailsForInvoiceEdit } from "../../../../utilsV2/tooltipHelpers";

    export let order: any = {};
    export let shippingAddress: {} = {};
    export let billingAddress: {} = {};
    export let enableEdit: boolean = false;
    export let orderJSON: {} = {};

    let editMode: boolean = false;
    let merchantOrderReference = order.merchantOrderReference;
    let purchaseOrderNumber = order.purchaseOrderNumber;
    let project = order.project;

    let tooltipContent: string = "";
    let tooltipWidth: string = "0px";

    const NONE_ADDED: string = "None added";
    let disabledEditClass: string = "-disabled";

    function toggleEditMode(saveEdits = false) {
        editMode = !editMode;
        if (!saveEdits) {
            merchantOrderReference = order.merchantOrderReference;
            purchaseOrderNumber = order.purchaseOrderNumber;
            project = order.project;
        }
    }

    onMount(() => {
        ({ tooltipContent, tooltipWidth } = getTooltipDetailsForInvoiceEdit(order, $_));
    });

    $: modalState.modals((values) => {
        if (values.props?.isEdited) {
            toggleEditMode(true);
        }
    });

    const prepareData = () => {
        const data: any = {
            merchant_reference: merchantOrderReference,
            buyer_purchase_order_number: purchaseOrderNumber,
            buyer_project: project,
            gross_amount: Number((+order.grossAmount).toFixed(2)),
            net_amount: Number(order.amount.toFixed(2)),
            currency: order.currency,
            tax_amount: Number((+order.tax_amount).toFixed(2)),
            line_items: order.lineItems,
            billing_address: billingAddress,
            shipping_address: shippingAddress
        };

        return data;
    };

    function sendEditedOrder() {
        const baseUrl = getBaseUrl();

        if (order?.status === "QUOTED") {
            const url = `${baseUrl}${GET_LATENT_ORDER_DETAILS(order.orderID)}`;
            const data = {
                ...orderJSON,
                merchant_reference: merchantOrderReference,
                buyer_purchase_order_number: purchaseOrderNumber,
                buyer_project: project
            };
            sendOrder("patch", url, data);
        } else {
            const data = prepareData();

            const url = `${baseUrl}${ORDER_MAIN(order.orderID)}`;
            sendOrder("put", url, data);
        }
    }

    function sendOrder(method, url, data) {
        disabledEditClass = "-disabled";
        let request;

        if (method === "put") {
            request = API.put(url, data);
        } else if (method === "patch") {
            request = API.patch(url, data);
        } else {
            throw new Error(`Invalid method: ${method}`);
        }

        request
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("order.details.notification_success")
                );
                toggleEditMode(true);
            })
            .catch(() => {
                notificationState.actions.create(
                    NotificationType.ERROR,
                    $_("order.details.notification_error")
                );
                disabledEditClass = "";
            });
    }

    function saveEdits() {
        if (["CONFIRMED", "VERIFIED", "QUOTED", "UNVERIFIED"].includes(order.state)) {
            sendEditedOrder();
        } else {
            modalState.actions.setModal("EDIT_INVOICE", {
                invoiceID: order?.invoice_details?.id,
                order,
                billingAddress,
                shippingAddress,
                merchantOrderReference,
                purchaseOrderNumber,
                project,
                orderNote: order?.order_note || ""
            });
        }
    }
</script>

<div class="main-bar">
    <span class="card-title">{$_("order.details.additional")}</span>
    {#if enableEdit}
        <div class="button-container">
            <ToolTip
                width={tooltipWidth}
                position="left-up"
                content={tooltipContent}
                hideTip={editMode || tooltipContent === ""}
            >
                <button
                    class="edit-button"
                    disabled={tooltipContent !== $_("order.details.tooltip_edit")}
                    on:click={() => toggleEditMode()}
                    >{editMode ? $_("components.cancel") : $_("components.edit")}</button
                >
            </ToolTip>
        </div>
    {/if}
</div>
<div class="card-content">
    <div class="card-column">
        <div class="flex-col">
            <div class="card-item-header">{$_("order.details.order_reference")}</div>
            {#if editMode}
                <TextInput
                    size="md"
                    placeholder={merchantOrderReference || NONE_ADDED}
                    bind:value={merchantOrderReference}
                    on:change={() => {
                        disabledEditClass = "";
                    }}
                />
            {:else}
                <div class="card-item-text">{merchantOrderReference || NONE_ADDED}</div>
            {/if}
        </div>
        <div class="flex-col">
            <div class="card-item-header">{$_("order.details.purchase_order_number")}</div>
            {#if editMode}
                <TextInput
                    size="md"
                    placeholder={purchaseOrderNumber || NONE_ADDED}
                    bind:value={purchaseOrderNumber}
                    on:change={() => {
                        disabledEditClass = "";
                    }}
                />
            {:else}
                <div class="card-item-text">{purchaseOrderNumber || NONE_ADDED}</div>
            {/if}
        </div>
    </div>
    <div class="flex-col">
        <div class="card-item-header">{$_("order.details.project")}</div>
        {#if editMode}
            <TextInput
                size="md"
                placeholder={project || NONE_ADDED}
                bind:value={project}
                on:change={() => {
                    disabledEditClass = "";
                }}
            />
        {:else}
            <div class="card-item-text">{project || NONE_ADDED}</div>
        {/if}
    </div>
</div>
{#if editMode}
    <div class="relative edit-options">
        <button
            class="button button--edit-full{disabledEditClass}"
            disabled={disabledEditClass !== ""}
            id="edit_order"
            on:click={saveEdits}>{$_("components.save")}</button
        >
    </div>
{/if}

<style lang="scss">
    .main-bar {
        display: flex;
        justify-content: space-between;
    }
    .button-container {
        display: flex;
        gap: 1rem;

        color: var(--primary-600);
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .button {
        display: flex;
        gap: 10px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 10px 20px;
        height: 40px;

        &--edit-full {
            color: var(--basic);
            background-color: var(--primary-600);
        }

        &--edit-full-disabled {
            color: var(--basic);
            background-color: var(--primary-200);
            pointer-events: none;
        }
    }

    .card-title {
        font-weight: 700;
        line-height: 24px;
        color: var(--ctnGeneralPrimary);
        font-family: Aeonik;
        font-size: 18px;
        font-style: normal;
    }

    .card-content {
        margin-top: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        flex: 1;
    }

    .card-column {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .card-item-header {
        color: var(--ctnGeneralTertiary);
        font-family: Aeonik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0.5rem;
    }

    .card-item-text {
        color: var(--ctnGeneralPrimary);
        font-family: Aeonik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .edit-options {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        display: flex;
        justify-content: end;
        gap: 0.5rem;
    }

    .edit-options::before {
        content: "";
        position: absolute;
        top: 1px;
        left: -24px;
        right: -22px;
        width: calc(100% + 48px);
        height: 1px;
        background-color: var(--gray-200);
    }

    .edit-button:disabled {
        color: var(--gray-400);
    }
</style>
