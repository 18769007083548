<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button, Statistic } from "@two-ui/core";
    import { navigate } from "svelte-routing";
    import { onMount } from "svelte";

    import RiskChart from "../common/RiskChart.svelte";
    import LoadingWrapper from "@/components/Elements/LoadingWrapper.svelte";
    import {
        getLossesPerMonthChart,
        getLostPercentageVolumeChart,
        getExposureChangeOverTimeChart
    } from "../common/dataUtils";
    import utils from "@/utilsV2/currency";
    import environment from "@/services/environment";
    import { overviewData, dataLoaded, fetchOverviewData } from "@/store/merchant/risk.store";

    onMount(fetchOverviewData);
</script>

{#if !$dataLoaded}
    <div class="gcard relative h-28">
        <LoadingWrapper loading={true} />
    </div>
{:else}
    <section class="top-cards">
        <Statistic
            id="total-volume"
            text={$_("riskManagement.overview.totalVolume")}
            value={utils.formatCurrency($overviewData?.currency)(
                $overviewData?.tpv?.last_0_to_30_days
            )}
            textClasses="ctn-general-primary font-semibold text-base"
        >
            {#if $overviewData?.tpv?.monthly_change !== "NA"}
                <span
                    class={`change-value text-Content-Semantic-${
                        $overviewData?.tpv?.monthly_change < 0
                            ? "Error-Primary"
                            : $overviewData?.tpv?.monthly_change === 0
                              ? "Info-Primary"
                              : "Success-Primary"
                    }`}
                >
                    {`${$overviewData?.tpv?.monthly_change * 100}% `}
                </span>
                {$_("riskManagement.overview.vsPrevious30Days")}
            {/if}
        </Statistic>
        <Statistic
            id="fraud-prevented"
            text={$_("riskManagement.overview.fraudPrevented")}
            value={utils.formatCurrency($overviewData?.currency)(
                $overviewData?.fraud_prevented?.last_0_to_30_days
            )}
            textClasses="ctn-general-primary font-semibold text-base"
        >
            {#if $overviewData?.fraud_prevented?.monthly_change !== "NA"}
                <span
                    class={`change-value text-Content-Semantic-${
                        $overviewData?.fraud_prevented?.monthly_change < 0
                            ? "Error-Primary"
                            : $overviewData?.fraud_prevented?.monthly_change === 0
                              ? "Info-Primary"
                              : "Success-Primary"
                    }`}
                >
                    {`${$overviewData?.fraud_prevented?.monthly_change * 100}% `}
                </span>
                {$_("riskManagement.overview.vsPrevious30Days")}
            {/if}
        </Statistic>
        <Statistic
            id="authenticated-customers"
            text={$_("riskManagement.overview.authenticatedCustomers")}
            value={$overviewData?.verified_customers?.last_0_to_30_days}
            textClasses="ctn-general-primary font-semibold text-base"
        >
            {#if $overviewData?.verified_customers?.monthly_change !== "NA"}
                <span
                    class={`change-value text-Content-Semantic-${
                        $overviewData?.verified_customers?.monthly_change < 0
                            ? "Error-Primary"
                            : $overviewData?.verified_customers?.monthly_change === 0
                              ? "Info-Primary"
                              : "Success-Primary"
                    }`}
                >
                    {`${$overviewData?.verified_customers?.monthly_change * 100}% `}
                </span>
                {$_("riskManagement.overview.vsPrevious30Days")}
            {/if}
        </Statistic>
    </section>

    <section id="losses-charts" class="mx-8 mt-4 grid grid-cols-1 bg-white rounded-lg">
        <RiskChart
            title={$_("riskManagement.exposure.title")}
            titleTooltip={$_("riskManagement.exposure.overviewExposureTooltip")}
            chartData={getExposureChangeOverTimeChart(
                $_("riskManagement.exposure.customersWithOverride"),
                $_("riskManagement.exposure.organicExposure", {
                    values: { brandName: environment.branding.displayName }
                }),
                $overviewData?.exposure
            )}
            linkLabel={$_("riskManagement.exposure.exploreExposure")}
        />
    </section>

    <section class="middle-cards">
        <Statistic
            id="customer-credit-limits"
            text={$_("riskManagement.overview.customerCreditLimits")}
            textClasses="ctn-general-primary font-semibold text-base"
        >
            <div class="card-body-text">
                {$_("riskManagement.overview.customerCreditLimitsText")}
            </div>
            <Button
                on:click={() => navigate("/merchant/customers")}
                size="md"
                variant="secondaryColor"
                content={$_("riskManagement.overview.manageCreditLimits")}
            />
        </Statistic>
        <Statistic
            id="fraud-prevented"
            text={$_("riskManagement.overview.rejectedOrders")}
            textClasses="ctn-general-primary font-semibold text-base"
        >
            <div class="card-body-text">{$_("riskManagement.overview.rejectedOrdersText")}</div>
            <Button
                on:click={() => navigate("/merchant/orders?tab=rejected")}
                size="md"
                variant="secondaryColor"
                content={$_("riskManagement.overview.seeOrders")}
            />
        </Statistic>
    </section>

    <section id="losses-charts" class="mx-8 mt-4 grid grid-cols-2 bg-white rounded-lg">
        <RiskChart
            title={$_("riskManagement.exposure.creditLoses")}
            subtitle={$_("riskManagement.exposure.losesPerMonth")}
            subtitleTooltip={$_("riskManagement.fraudInsights.lossesPerMonthTooltip")}
            chartData={getLossesPerMonthChart(
                "credit-losses-per-month",
                $_("riskManagement.exposure.customersWithOverride"),
                $_("riskManagement.exposure.organicExposure", {
                    values: { brandName: environment.branding.displayName }
                }),
                $overviewData?.credit_losses
            )}
            showCustomLegend={false}
            linkLabel={$_("riskManagement.exposure.exploreLossMetrics")}
        />

        <RiskChart
            title="&nbsp;"
            subtitle={$_("riskManagement.fraudInsights.percentageLostText")}
            subtitleTooltip={$_("riskManagement.fraudInsights.percentageLostTooltip")}
            chartData={getLostPercentageVolumeChart(
                "credit-lost-percentage-volume",
                $_("riskManagement.exposure.customersWithOverride"),
                $_("riskManagement.exposure.organicExposure", {
                    values: { brandName: environment.branding.displayName }
                }),
                $overviewData?.credit_losses
            )}
        />
    </section>

    <section id="fraud-losses" class="mx-8 mt-4 grid grid-cols-2 mb-4 bg-white rounded-lg">
        <RiskChart
            title={$_("riskManagement.fraudInsights.fraudLosses")}
            subtitle={$_("riskManagement.fraudInsights.lossesPerMonth")}
            subtitleTooltip={$_("riskManagement.fraudInsights.lossesPerMonthTooltip")}
            chartData={getLossesPerMonthChart(
                "fraud-losses-per-month",
                $_("riskManagement.exposure.customersWithOverride"),
                $_("riskManagement.exposure.organicExposure", {
                    values: { brandName: environment.branding.displayName }
                }),
                $overviewData?.fraud_losses
            )}
            showCustomLegend={false}
            linkLabel={$_("riskManagement.fraudInsights.exploreFraudInsights")}
        />

        <RiskChart
            title="&nbsp;"
            subtitle={$_("riskManagement.fraudInsights.percentageLostText")}
            subtitleTooltip={$_("riskManagement.fraudInsights.percentageLostTooltip")}
            chartData={getLostPercentageVolumeChart(
                "fraud-lost-percentage-volume",
                $_("riskManagement.exposure.customersWithOverride"),
                $_("riskManagement.exposure.organicExposure", {
                    values: { brandName: environment.branding.displayName }
                }),
                $overviewData?.fraud_losses
            )}
        />
    </section>
{/if}

<style lang="scss">
    .top-cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        margin: 1.5rem 2rem 0 2rem;
    }

    .change-value {
        font-family: Aeonik;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    .middle-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        margin: 1rem 2rem 0 2rem;
    }

    .card-body-text {
        color: var(--ctnGeneralPrimary);
        font-family: Aeonik;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 1rem;
    }
</style>
