<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button as TwoUIButton, ListCard } from "@two-ui/core";
    import Modal from "../../../../components/Elements/Modal.svelte";
    import { formatLocaleDateString, titleCase } from "../../../../utilsV2/helpers";
    import { actions as insightActions } from "../../../../store/merchant/creditInsights.store";

    export let showModal: boolean;
    export let alertDetail;
    export let selectedCustomer;
    export let isLoading;

    function closeModal() {
        showModal = false;
    }

    function sendAlert() {
        insightActions.sendAlert(selectedCustomer?.buyer_company_id);
        showModal = false;
    }
</script>

<Modal
    title={$_("riskManagement.alerts.sendAlertHeader")}
    bind:show={showModal}
    closeOnClickOutside={false}
>
    <div>
        <p>
            {$_("riskManagement.alerts.alertEmailMessage")}
        </p>
        <div class="email-list-container">
            <ListCard
                title={$_("riskManagement.alerts.emailsAssociatedWith", {
                    values: {
                        companyName: selectedCustomer?.buyer_company_name
                    }
                })}
                {isLoading}
                items={alertDetail?.email_addresses}
            />
        </div>
    </div>

    <div slot="footer" class="flex gap-2 w-full space-between">
        <div class="alert-details">
            {#if !isLoading}
                {#if alertDetail?.last_sent_at && alertDetail?.last_sent_by}
                    <div>
                        {$_("riskManagement.alerts.lastAlertSentBy", {
                            values: {
                                userName: alertDetail?.last_sent_by
                            }
                        })}
                    </div>
                    <div>{formatLocaleDateString(alertDetail?.last_sent_at, true)}</div>
                {:else}
                    <div>{$_("riskManagement.alerts.noAlertSent")}</div>
                {/if}
            {/if}
        </div>
        <div class="flex gap-2">
            <TwoUIButton
                on:click={closeModal}
                size="md"
                variant="secondaryGray"
                content={$_("components.cancel")}
            />
            <TwoUIButton
                on:click={sendAlert}
                size="md"
                variant="primary"
                content={$_("riskManagement.alerts.sendAlert")}
            />
        </div>
    </div>
</Modal>

<style>
    p {
        color: var(--ctnGeneralSecondary);
        font-family: Aeonik;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
    }

    .email-list-container {
        margin: 2rem 0 3rem 0;
        border-radius: 8px;
        border: 1px solid var(--borderGeneralPrimary);
    }

    .alert-details {
        flex-grow: 1;
        color: var(--ctnGeneralTertiary);
        font-family: Aeonik;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        align-content: center;
    }
</style>
