<script lang="ts">
    import Loading from "./Loading.svelte";
    import { createEventDispatcher, onMount } from "svelte";

    const dispatch = createEventDispatcher();
    const defaultColor = "var(--primary-500)";

    export let label: string = "";
    export let size: string = "large";
    export let color: string = "white";
    export let hBg: string = defaultColor;
    export let hoverBg: string = "var(--primary-600)";
    export let hoverColor: string = "white";
    export let loading: boolean = false;
    export let disable: boolean = false;
    export let bgColor: string = defaultColor;
    export let borderRadius: string = "10px";
    export let borderColor: string = bgColor;
    export let boxShadow: string = "";
    export let paddingX: string = "0";
    export let paddingY: string = "0";
    export let autoHeight: boolean = false;
    export let id = "";
    export let classes = "";

    let height: string;

    onMount(() => {
        switch (size) {
            case "small":
                height = "34px";
                break;
            case "xlarge":
                height = "56px";
                borderRadius = "16px";
                break;
            case "huge":
                height = "78px";
                borderRadius = "220px";
                break;
            case "medium":
                height = "40px";
                borderRadius = "8px";
                break;
            case "large":
            default: {
                height = "48px";
                break;
            }
        }
    });

    function onClick() {
        if (!loading) {
            dispatch("click");
        }
    }
</script>

<div
    class="relative"
    style="--bg-color: {bgColor}; --color:{color}; --hBg:{hBg}; --height:{height}; --hoverBg:{hoverBg}; --hoverColor:{hoverColor}; --borderRadius: {borderRadius}; --borderColor: {borderColor}; --boxShadow: {boxShadow}; --paddingY:{paddingY}; --paddingX: {paddingX}"
>
    <button
        {id}
        class="relative text-white userBtn focus:outline-hidden {classes}"
        class:disable
        class:autoHeight
        class:fixedHeight={!autoHeight}
        on:click={onClick}
        class:cursor-not-allowed={disable}
        class:btn-huge={size == "huge"}
        class:btn-small={size == "small"}
        disabled={disable}
    >
        {#if loading}
            <Loading />
        {:else}
            {label}
            <slot />
        {/if}
    </button>
</div>

<style>
    .userBtn {
        width: 100%;
        border-radius: var(--borderRadius);
        border: 1px solid var(--borderColor);
        background-color: var(--bg-color);
        color: var(--color);
        box-shadow: var(--boxShadow);
        padding: var(--paddingY) var(--paddingX);
    }
    .userBtn.fixedHeight {
        height: var(--height);
    }
    .userBtn.autoHeight {
        min-height: var(--height);
    }
    .userBtn:active {
        background-color: var(--hBg);
        color: var(--hoverColor);
    }
    .userBtn:hover {
        background-color: var(--hoverBg);
        color: var(--hoverColor);
    }
    .disable {
        opacity: 0.5;
        cursor: default;
    }
    .btn-huge {
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    .btn-small {
        font-size: 0.875rem;
    }
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }
</style>
