<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Circle } from "svelte-loading-spinners";
    import { Button as TwoUIButton } from "@two-ui/core";

    import { getChartGroupLabel } from "../../../utils/functions";
    import MixedChart from "../../../components/chart/MixedChart.svelte";
    import type {
        IDashboardChartValue,
        IGraphStackedBarData,
        IMerchantPeriodReportGraphData
    } from "@/static/types";
    import { loading } from "../../../store/merchant/dashboard.store";
    import environment from "@/services/environment";
    import { account } from "../../../store/merchant/account.store";

    export let graphData: IMerchantPeriodReportGraphData = {};
    export let currenciesUsed;

    type ChartType = "TOTAL_SALES" | "SALES_VOLUME";
    let chartType: ChartType = "TOTAL_SALES";
    let dataStacks: IGraphStackedBarData[] = [];

    let loadingChart: boolean = true;

    const charts = {
        TOTAL_SALES: {
            title: $_("dashboard.chart.totalSalesTitle"),
            dataStacks: [],
            label: [],
            hoverStrings: []
        },
        SALES_VOLUME: {
            title: $_("dashboard.chart.salesVolumeTitle"),
            lineData: [],
            label: [],
            hoverStrings: [$_("dashboard.chart.ordersPlaced")],
            colours: [environment.branding.chartMainColor]
        }
    };

    $: if (
        Object.keys(graphData)?.length &&
        window?.document &&
        $account?.merchantCountry?.currency
    ) {
        let salesVolume: number[] = [];
        let totalSalesLabel: string[] = [];
        let salesVolumeLabel: string[] = [];

        dataStacks.length = 0;
        // reverse order as they come date desc from checkout-api
        graphData.fulfilled_order_currency_totals.reverse();
        graphData.refunded_order_currency_totals.reverse();
        graphData.fulfilled_order_eur_totals.reverse();
        graphData.fulfilled_order_volumes.reverse();

        function getAmountForDate(totals, date, currency) {
            // ensures there is at least 0 amount per currency per data
            let x = totals.find((item) => item.currency === currency && item.start_time === date);
            return x ? Number(x.amount) : 0;
        }

        let style = window.getComputedStyle(window.document.documentElement);
        let stackColors = [
            // will cover 5 currencies
            ["--bgSemanticErrorPrimary", "--bgSemanticErrorSecondary"],
            ["--bgSemanticInfoSolid", "--bgSemanticInfoLight"],
            ["--bgSemanticWarningPrimary", "--bgSemanticWarningSecondary"],
            ["--bgSemanticSuccessPrimary", "--bgSemanticSuccessSecondary"],
            ["--bgSemanticHighlightPrimary", "--bgSemanticHighlightSecondary"]
        ];
        let primaryCurrencyColor = null;
        if (currenciesUsed.includes($account.merchantCountry.currency)) {
            primaryCurrencyColor = stackColors.pop();
        }
        currenciesUsed.forEach((currency: string) => {
            // assign primary colour to the merchant's country currency if in chart data
            let currencyColors =
                $account.merchantCountry.currency === currency
                    ? primaryCurrencyColor
                    : stackColors.pop();

            [
                [graphData.fulfilled_order_currency_totals, $_("status.fulfilled")],
                [graphData.refunded_order_currency_totals, $_("status.refunded")]
            ].forEach((dataType, index) => {
                dataStacks.push({
                    label: `${currency} - ${dataType[1]}`,
                    // use the eur totals as a base as there will be an entry for each date
                    data: graphData.fulfilled_order_eur_totals.map((volume) =>
                        getAmountForDate(dataType[0], volume.start_time, currency)
                    ), // Fulfilled amounts for GBP by date
                    backgroundColor: style.getPropertyValue(currencyColors[index]),
                    stack: currency, // Stack identifier for currency
                    fill: false,
                    borderRadius: 2
                });
            });
        });

        totalSalesLabel = getChartGroupLabel(graphData.fulfilled_order_eur_totals);
        salesVolumeLabel = getChartGroupLabel(graphData.fulfilled_order_volumes);
        salesVolume = graphData.fulfilled_order_volumes.map((value: IDashboardChartValue) => {
            return Number(value.amount);
        });

        charts.TOTAL_SALES.dataStacks = dataStacks;
        charts.TOTAL_SALES.label = totalSalesLabel;
        charts.SALES_VOLUME.lineData = [salesVolume];
        charts.SALES_VOLUME.label = salesVolumeLabel;
        reloadChart();
    }

    function reloadChart() {
        loadingChart = false;
        setTimeout(() => {
            loadingChart = true;
        }, 10);
    }
    function changeType(value: string) {
        chartType = value as ChartType;
        reloadChart();
    }
</script>

<div class="flex ctn-interactive-general-disabled font-bold" style="gap:1rem;">
    {#each Object.entries(charts) as [key, chart]}
        <TwoUIButton
            on:click={() => changeType(key)}
            size="sm"
            variant={chartType === key ? "tertiaryColor" : "tertiaryGrey"}
            fullSize={false}
            content={chart.title}
        />
    {/each}
</div>
<div class="mt-6 relative flex flex-1">
    {#if loadingChart}
        <MixedChart
            showTotal={false}
            showCustomLegend={false}
            showDefaultLegend={true}
            {...charts[chartType]}
        />
    {/if}
    {#if $loading}
        <div class="chart_loading absolute top-0 w-full h-full flex items-center">
            <div class="m-auto">
                <Circle color={environment.branding.chartMainColor} />
            </div>
        </div>
    {/if}
</div>
