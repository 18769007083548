<script lang="ts">
    import { _ } from "svelte-i18n";

    import CheckboxItem from "../../../components/checkboxitem/CheckboxItem.svelte";
    import FiltersSidebar from "../../../components/filters/FiltersSidebar.svelte";
    import Icon from "../../../components/icons/Icon.svelte";
    import { DateToString } from "../../../utils/functions";
    import { clickOutside } from "../../../utils/clickOutside";
    import DateSelector from "../../../components/invoices/filters/DateSelector.svelte";
    import dashboardState from "../../../store/merchant/dashboard.store";
    import { signupComplete, account } from "../../../store/merchant/account.store";

    let showDateSelector = false;

    let filterCounter: number = 0;

    let showFilters: boolean = false;
    let filterParams: string = "";

    let selectedCurrencies: string[] = ["ALL"];

    const defaultEndDate: Date = new Date();
    const defaultStartDate: Date = new Date(new Date().getTime() - 30 * 24 * 3600000);
    let currencyFilterDescription: string = "All currencies";

    export let startDate: Date = defaultStartDate;
    export let endDate: Date = defaultEndDate;
    let newStartDate: Date = startDate;
    let newEndDate: Date = endDate;
    $: if ($account?.merchantCountry) {
        dashboardState.actions.getDashboard(
            startDate,
            endDate,
            $signupComplete,
            "",
            $account.merchantCountry.currency
        );
    }

    let currencyOptions = [
        {
            value: "all",
            label: $_("riskManagement.filters.all")
        }
    ];

    let merchantCurrencies: Set<string> = new Set();
    $: if ($account?.countryCode) {
        $account.buyer_country_configurations.forEach((config) => {
            merchantCurrencies = merchantCurrencies.union(new Set(config.available_currencies));
        });
        merchantCurrencies.forEach((currency) => {
            currencyOptions.push({
                value: currency,
                label: currency
            });
        });
    }

    function clearFilters() {
        selectedCurrencies = ["ALL"];
        endDate = defaultEndDate;
        startDate = defaultStartDate;
        filterCounter = 0;
        showFilters = false;
        dashboardState.actions.getDashboard(
            startDate,
            endDate,
            $signupComplete,
            $account.merchantCountry.currency
        );
    }

    function toggleFilters() {
        showFilters = !showFilters;
    }

    function filterCurrencies(event) {
        if (event.detail.checked) {
            if (event.detail.checkedItem.toUpperCase() === "ALL") {
                selectedCurrencies = ["ALL"];
            } else {
                selectedCurrencies.push(event.detail.checkedItem.toUpperCase());
                if (
                    merchantCurrencies
                        .values()
                        .every((merchantCurrency) => selectedCurrencies.includes(merchantCurrency))
                ) {
                    selectedCurrencies = ["ALL"];
                }
            }
        }

        if (!event.detail.checked) {
            if (event.detail.checkedItem.toUpperCase() === "ALL") {
                selectedCurrencies = [];
            } else if (selectedCurrencies.includes("ALL")) {
                selectedCurrencies = merchantCurrencies.values().toArray();
            }

            const index = selectedCurrencies.indexOf(event.detail.checkedItem.toUpperCase());
            if (index !== -1) {
                selectedCurrencies.splice(index, 1);
            }
        }
    }

    function applyFilters() {
        filterCounter = 0;
        filterParams = "";
        let selectedCurrenciesQueryStr = null;

        if (!selectedCurrencies.includes("ALL") && selectedCurrencies.length > 0) {
            selectedCurrenciesQueryStr = selectedCurrencies.join("&currency=");
            filterCounter++;
            currencyFilterDescription = selectedCurrencies.join(", ");
        } else {
            currencyFilterDescription = "All currencies";
        }
        if (newStartDate !== defaultStartDate || newEndDate !== defaultEndDate) {
            filterCounter++;
            endDate = newEndDate;
            startDate = newStartDate;
        }
        dashboardState.actions.getDashboard(
            startDate,
            endDate,
            $signupComplete,
            selectedCurrenciesQueryStr,
            $account.merchantCountry.currency
        );

        showFilters = false;
    }
</script>

<div
    class="filter-button"
    class:filters-active={filterCounter > 0}
    on:click={toggleFilters}
    on:keyup
>
    <Icon name="sliders" size={16} />
    <span>{$_("filters.filters")}</span>
    {#if filterCounter > 0}
        <div class="filter-counter">{filterCounter}</div>
    {/if}
</div>
<div class="filter-card">{startDate.toLocaleDateString()}-{endDate.toLocaleDateString()}</div>
<div class="filter-card">{currencyFilterDescription}</div>

{#if showFilters}
    <FiltersSidebar>
        <div slot="header" class="filter-header">
            <div>
                <span>{$_("filters.filtersFor")}</span>
                <span class="header-subtext">{$_("dashboard.pageHeader.title")}</span>
            </div>
            <div class="close">
                <button on:click={toggleFilters}>
                    <Icon name="cross" size={32} />
                </button>
            </div>
        </div>
        <div slot="main">
            <div class="filter-item">
                <span class="filter-item-header-text">{$_("components.datePicker.dateRange")}</span>
                <div
                    on:click={() => (showDateSelector = true)}
                    on:keyup
                    class="data-range date-range"
                >
                    {`${DateToString(startDate, true)} - ${DateToString(endDate, true)}`}
                </div>
                {#if showDateSelector}
                    <div use:clickOutside on:click_outside={() => (showDateSelector = false)}>
                        <DateSelector
                            on:close={() => (showDateSelector = false)}
                            bind:startDate={newStartDate}
                            bind:endDate={newEndDate}
                            top="90px"
                        />
                    </div>
                {/if}
            </div>
            <div class="filter-item">
                <span class="filter-item-header-text">{$_("account.currency")}</span>
                <div class="filter-items-options">
                    {#each currencyOptions as currencyOption}
                        <CheckboxItem
                            statusItem={currencyOption}
                            isChecked={selectedCurrencies.includes(
                                currencyOption.value.toUpperCase()
                            ) || selectedCurrencies.includes("ALL")}
                            on:checkedItem={filterCurrencies}
                        />
                    {/each}
                </div>
            </div>
        </div>
        <div slot="footer" class="filter-footer">
            <div class="apply-button" on:click={applyFilters} on:keyup>
                {$_("filters.applyFilters")}
            </div>
            <div class="clear-button" on:click={clearFilters} on:keyup>
                {$_("filters.clearAll")}
            </div>
        </div>
    </FiltersSidebar>
{/if}

<style>
    .apply-button {
        color: white;
        width: 126px;
        height: 3rem;
        line-height: 3rem;
        background: var(--primary-600);
        border: 1px solid var(--primary-600);
        box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
        border-radius: 0.5rem;
        text-align: center;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: 0.5px;
        cursor: pointer;
    }

    .clear-button {
        width: 6.125rem;
        height: 3rem;
        line-height: 3rem;
        background: #ffffff;
        border: 1px solid var(--borderGeneralSecondary);
        box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
        border-radius: 0.5rem;
        text-align: center;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: var(--ctnInteractiveGeneralDefault);
        cursor: pointer;
    }

    .clear-button:hover {
        background: var(--bgInteractiveSemanticHighlightHoverSecondary);
    }

    .date-range {
        border-radius: 0.5rem;
        max-width: 15rem;
    }

    .data-range {
        display: flex;
        border: 1px solid var(--borderGeneralSecondary);
        padding: 0 1rem;
        height: 2.5rem;
        line-height: 2.5rem;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: var(--ctnGeneralPrimary);
    }
    .filter-card {
        background-color: var(--bgGeneralSecondary);
        padding: 12px 16px;
        border-radius: 0.5rem;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
</style>
