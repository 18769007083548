import { writable } from "svelte/store";

import type { ICountry } from "@/static/types";

export const selectedCompanyName = writable("");
export const selectedPaymentMethod = writable("");

function createOrderCreateState() {
    const country = writable<ICountry>({});
    return {
        country,
        actions: {
            setCountry(orderCountry: ICountry) {
                country.set(orderCountry);
            }
        }
    };
}

const orderCreateState = createOrderCreateState();
export default orderCreateState;
