<script lang="ts">
    export let label: string;
    export let margin = "0.5rem 0";
</script>

<div style="margin: {margin};">
    <span>{label}</span>
    <slot />
</div>

<style>
    div {
        display: flex;
        flex-direction: column;
        line-height: 1;
        font-size: 0.875rem;
    }
    span {
        color: var(--ctnGeneralTertiary);
        line-height: 1.25;
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
    }
</style>
