<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button as TwoUIButton } from "@two-ui/core";

    import { account } from "../../store/merchant/account.store";
    import {
        customersLoading,
        actions as customerActions
    } from "../../store/merchant/customers.store";
    import modalState from "../../store/modals.store";
    import notificationState from "@/store/notifications.store";
    import { COUNTRIES } from "../../static/constant";
    import { NotificationType } from "../../static/types";
    import type { ICountry } from "@/static/types";
    import { getBuyerCountries } from "../../static/customers";
    import { GET_BUSINESS_REGISTRATION_INVITE } from "../../static/endPoints";
    import API from "../../services/apiService";

    import CompanySelector from "../companySelector/CompanySelector.svelte";
    import Selector from "../Elements/Selector.svelte";
    import CountrySelector from "../countrySelector/CountrySelector.svelte";
    import CopyTextLabel from "../Elements/CopyTextLabel.svelte";
    import countryName from "../../static/countryName";
    import { SEND_BUSINESS_REGISTRATION_INVITE_MODAL } from "@/static/modals";

    let company: string = "";
    let companyID: string = "";
    let paymentTerm: string = "";
    let countryCode: keyof typeof countryName | null = $account?.countryCode;
    let selectedCountry = {};
    let isCrossBorderEnabled: boolean = true;
    let country: ICountry = COUNTRIES[countryCode] || null;
    let buyerCountries = {};
    let addSoleTrader: boolean = false;
    let addLimitedCompany: boolean = false;
    let showCustomerTypeButtons: boolean = true;
    let supportedSoleTraderCountryCodes = ["GB"];
    let enableSoleTrader: boolean =
        supportedSoleTraderCountryCodes.includes($account.countryCode.toUpperCase()) &&
        $account?.flags?.business_registration_button_enabled;
    let soleTraderRegistrationLink = "";

    $: if (selectedCountry) {
        resetCompany(selectedCountry);
    }

    $: if ($account?.countryCode) {
        isCrossBorderEnabled =
            $account.flags.cross_border_enabled && $account.buyer_country_configurations.length > 1;
        buyerCountries = getBuyerCountries($account.buyer_country_configurations || []);
        country = COUNTRIES[$account.countryCode] || null;
        countryCode = $account.countryCode;
    }

    function resetCompany(newCountry: ICountry) {
        company = "";
        companyID = "";
        countryCode = newCountry.country_code;
        country = selectedCountry;
    }

    const invalid = {
        selectCompany: "",
        paymentTerm: ""
    };

    const options = ["14 days", "21 days", "30 days", "45 days", "60 days", "90 days"];

    const handleClose = () => {
        modalState.actions.setModal(null, {});
    };

    function validateFields() {
        invalid.selectCompany = company ? "" : $_("customer.selectCompanyError");
        invalid.paymentTerm = paymentTerm ? "" : $_("customer.selectPaymentTermError");
        return !invalid.selectCompany && !invalid.paymentTerm;
    }

    function addCustomer() {
        if (validateFields()) {
            customerActions.addPaymentTerms({
                buyer_organization_number: companyID,
                due_in_days: Number(paymentTerm.split(" ")[0]),
                buyer_company_name: company,
                buyer_country_code: countryCode
            });
            handleClose();
        }
    }

    const handleAddSoleTrader = async () => {
        showCustomerTypeButtons = false;
        addSoleTrader = true;
        await generateSoleTraderRegistrationLink();
    };

    const handleAddLimitedCompany = () => {
        showCustomerTypeButtons = false;
        addLimitedCompany = true;
    };

    const handleBack = () => {
        addSoleTrader = false;
        addLimitedCompany = false;
        showCustomerTypeButtons = true;
    };

    const sendSoleTraderInviteModal = () => {
        modalState.actions.setModal(SEND_BUSINESS_REGISTRATION_INVITE_MODAL, {});
    };

    const generateSoleTraderRegistrationLink = async (): Promise<string | void> => {
        API.get(GET_BUSINESS_REGISTRATION_INVITE)
            .then((response: { url: string }) => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("components.addCustomerModal.linkGenerated")
                );
                const generatedLink = response.url;
                soleTraderRegistrationLink = generatedLink;
            })
            .catch(() => {
                notificationState.actions.create(
                    NotificationType.ERROR,
                    $_("components.addCustomerModal.error")
                );
            });
    };
</script>

<div
    class="add-customer-modal-outer-container flex flex-col justify-center items-center relative w-[544px] min-h-[264px] p-8 sm:w-xxs"
>
    <div class="w-full text-2xl font-bold flex justify-between items-center gap-4">
        {#if addSoleTrader}
            <p>{$_("components.addCustomerModal.addSoleTrader")}</p>
        {:else if addLimitedCompany}
            <p>{$_("components.addCustomerModal.addLimitedCompany")}</p>
        {:else}
            <p>{$_("components.addCustomerModal.addCustomer")}</p>
        {/if}
        <div
            class="rounded-full hover:bg-gray-200 cursor-pointer flex justify-center items-center w-7 h-7"
        >
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <img
                src="/pictures/close.svg"
                alt="close"
                on:keydown={handleClose}
                on:click={handleClose}
            />
        </div>
    </div>

    <div class="mt-4 font-normal text-base w-full">
        {#if addSoleTrader}
            <p>{$_("components.addCustomerModal.addSoleTraderDescription")}</p>
        {:else if addLimitedCompany}
            <p>{$_("components.addCustomerModal.addLimitedCompanyDescription")}</p>
        {:else}
            <p>{$_("components.addCustomerModal.configureDescription")}</p>
        {/if}
    </div>

    {#if addLimitedCompany}
        <div class="mt-4 w-full">
            <CountrySelector
                countries={buyerCountries}
                bind:country={selectedCountry}
                initialCountryCode={countryCode}
                label={$_("components.addCustomerModal.selectedCountryLabel")}
            />
            <CompanySelector
                {countryCode}
                showId={false}
                bind:company
                bind:companyID
                invalid={invalid.selectCompany}
                placeholderText={$_("customer.selectCompanyPlaceholder")}
            />
            <div class="mt-8">
                <p class="mb-4 text-lg font-bold">
                    {$_("components.addCustomerModal.companySettings")}
                </p>
                <Selector
                    label={$_("customer.paymentTermsLabel")}
                    description={$_("customer.paymentTermsDescription")}
                    invalid={invalid.paymentTerm}
                    bind:value={paymentTerm}
                    {options}
                    showArrow={true}
                />
            </div>
        </div>
    {/if}

    {#if addSoleTrader}
        <div class="mt-4 w-full">
            <CopyTextLabel
                value={soleTraderRegistrationLink}
                subText={$_("components.addCustomerModal.soleTraderLinkInfo")}
            />
        </div>
    {/if}

    <div class="flex justify-end w-full space-x-2 mt-9">
        {#if showCustomerTypeButtons}
            {#if enableSoleTrader}
                <TwoUIButton
                    size="md"
                    variant="secondaryGray"
                    content={$_("components.addCustomerModal.addSoleTrader")}
                    on:click={handleAddSoleTrader}
                />
            {/if}
            <TwoUIButton
                size="md"
                variant="primary"
                content={$_("components.addCustomerModal.addLimitedCompany")}
                on:click={handleAddLimitedCompany}
            />
        {:else}
            <TwoUIButton
                size="md"
                variant="secondaryGray"
                content={$_("components.back")}
                on:click={handleBack}
            />
        {/if}

        {#if addLimitedCompany}
            <TwoUIButton
                size="md"
                variant="primary"
                content={$_("components.addCustomerModal.addLimitedCompanyAlt")}
                on:click={addCustomer}
                loading={$customersLoading}
            />
        {/if}
        {#if addSoleTrader}
            <!-- Hiding for staging and release -->
            <TwoUIButton
                size="md"
                variant="primary"
                content={$_("customers.inviteSoleTrader")}
                on:click={() => sendSoleTraderInviteModal()}
                loading={$customersLoading}
            />
        {/if}
    </div>
</div>
