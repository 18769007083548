/* eslint-disable
@typescript-eslint/no-unsafe-member-access,
@typescript-eslint/no-unsafe-argument,
@typescript-eslint/no-unsafe-assignment,
@typescript-eslint/no-floating-promises */
import { writable } from "svelte/store";
import API from "../../services/apiService";
import {
    GET_CUSTOMER_BETA,
    ADD_PAYMENT_TERMS,
    GET_PAYMENT_TERMS,
    UPDATE_PAYMENT_TERMS,
    MERCHANT_BUYER_RECOURSE_LIMIT,
    MERCHANT_BUYER_RECOURSE_LIMIT_MAX_AMOUNT
} from "../../static/endPoints";

import {
    type ICustomerRecourseLimit,
    type ICursorPage,
    NotificationType,
    type ICustomersResponse,
    type ICustomerRecourseLimitMaxAllowed,
    type ICustomer
} from "../../static/types";
import notificationState from "../notifications.store";
import { signupComplete } from "../merchant/account.store";
import modalState from "../modals.store";
import { customerData } from "../../static/signupDemoMockResponses";

function createCustomer() {
    const customers = writable([]);
    const singleCustomer = writable<ICustomer>();
    const cursorPage = writable<ICursorPage>();
    const cursorLimit = writable(10);
    const paymentTerms = writable([]);
    const customersLoading = writable(false);
    const showCustomerSettings = writable(false);
    const customersTab = writable("All");
    const buyerRecourseLimit = writable(undefined);
    const buyerRecourseLimitMaxAllowed = writable(undefined);
    const buyerRecourseLimitLoading = writable(false);
    const buyerRecourseLimitMaxAllowedLoading = writable(false);
    let accountSignupComplete = true;

    signupComplete.subscribe((value: boolean) => (accountSignupComplete = value));

    const setCustomerLoading = (value: boolean): any => {
        customersLoading.set(value);
    };

    return {
        buyerRecourseLimitLoading,
        buyerRecourseLimitMaxAllowedLoading,
        paymentTerms,
        customersLoading,
        cursorPage,
        cursorLimit,
        showCustomerSettings,
        customersTab,
        buyerRecourseLimit,
        buyerRecourseLimitMaxAllowed,
        customers,
        singleCustomer,
        actions: {
            getCustomers: (
                searchFilterQuery: string = null,
                is_on_recourse = false,
                pageCursor: string = null
            ) => {
                let isLoading;
                customersLoading.subscribe((value) => (isLoading = value));
                if (isLoading) return;
                setCustomerLoading(true);
                if (!accountSignupComplete) {
                    customers.set(customerData.customers);
                    cursorPage.set(customerData.cursor_page_metadata);
                    setCustomerLoading(false);
                    return;
                }
                let limit: number | null = null;
                cursorLimit.subscribe((value) => (limit = value));
                const searchParams = new URLSearchParams();
                if (searchFilterQuery) {
                    searchParams.append("search_filter", searchFilterQuery);
                }
                if (is_on_recourse) {
                    searchParams.append("on_recourse", "true");
                }
                if (pageCursor) {
                    searchParams.append("page_cursor", pageCursor);
                }

                API.get(GET_CUSTOMER_BETA + `?limit=${limit}&` + searchParams.toString())
                    .then((res: ICustomersResponse) => {
                        customers.set(res.customers);
                        cursorPage.set(res.cursor_page_metadata);
                        setCustomerLoading(false);
                    })
                    .catch(() => {
                        setCustomerLoading(false);
                        notificationState.actions.create(
                            NotificationType.ERROR,
                            "Customers can't be fetched",
                            null,
                            "Please refresh the page. Or, use our live chat if the error persists."
                        );
                    });
            },
            getPaymentTerms: () => {
                API.get(GET_PAYMENT_TERMS)
                    .then((res: any) => paymentTerms.set(res.payment_terms))
                    .catch(() => {
                        notificationState.actions.create(
                            NotificationType.ERROR,
                            "Get merchant payment terms failed"
                        );
                    });
            },
            addPaymentTerms: ({
                buyer_company_name,
                buyer_organization_number,
                buyer_country_code,
                due_in_days
            }) => {
                customersLoading.set(true);
                API.post(ADD_PAYMENT_TERMS, {
                    buyer_company_name,
                    buyer_organization_number,
                    buyer_country_code,
                    due_in_days
                })
                    .then(() => {
                        customersLoading.set(false);
                        actions.getCustomers();
                        modalState.actions.setModal(null, {});
                        notificationState.actions.create(
                            NotificationType.SUCCESS,
                            "Successfully added new customer!"
                        );
                    })
                    .catch(() => {
                        customersLoading.set(false);
                        notificationState.actions.create(
                            NotificationType.ERROR,
                            "Failed add new customer!"
                        );
                    });
            },
            updateCustomerSettings: ({
                buyer_company_name,
                buyer_organization_number,
                buyer_country_code,
                due_in_days,
                direct_invoice_fallback,
                fallback_threshold
            }) => {
                customersLoading.set(true);
                API.patch(UPDATE_PAYMENT_TERMS, {
                    buyer_company_name,
                    buyer_organization_number,
                    buyer_country_code,
                    due_in_days,
                    direct_invoice_fallback,
                    fallback_threshold
                })
                    .then(() => {
                        actions.getCustomers();
                        notificationState.actions.create(
                            NotificationType.SUCCESS,
                            "Customer payment terms",
                            null,
                            "Successfully updated customer payment terms."
                        );
                    })
                    .catch(() => {
                        notificationState.actions.create(
                            NotificationType.ERROR,
                            "Customer payment terms failed",
                            null,
                            "Failed to update the customer payment terms!"
                        );
                    })
                    .finally(() => {
                        customersLoading.set(false);
                    });
            },
            getCustomerRecourseLimit: (
                buyer_organization_number: string,
                buyer_country_code: string,
                currency: string = null
            ) => {
                buyerRecourseLimitLoading.set(true);
                API.get(
                    `${MERCHANT_BUYER_RECOURSE_LIMIT}/${buyer_country_code}/${buyer_organization_number}?currency=${
                        currency ?? ""
                    }`
                )
                    .then((res: any) => {
                        buyerRecourseLimit.set(res);
                        buyerRecourseLimitLoading.set(false);
                    })
                    .catch((error) => {
                        try {
                            buyerRecourseLimit.set(error.response?.data);
                        } catch (e) {
                            console.error(e);
                        }
                    })
                    .finally(() => buyerRecourseLimitLoading.set(false));
            },
            updateCustomerRecouseLimit: (
                data: ICustomerRecourseLimit,
                buyer_company_name: string,
                is_exist,
                isDisabled: boolean,
                $_
            ) => {
                const error_msg_heading = $_("account.recourseCallFailedTitle");
                const error_msg_content = $_("account.recourseCallFailedText");
                const success_msg_heading = isDisabled
                    ? $_("account.recourseDisabledTitle")
                    : $_("account.recourseUpdatedTitle");
                const success_msg_content = isDisabled
                    ? $_("account.recourseDisabledText", {
                          values: { buyer_company: buyer_company_name }
                      })
                    : $_("account.recourseUpdatedText", {
                          values: { buyer_company: buyer_company_name }
                      });

                buyerRecourseLimitLoading.set(true);

                if (is_exist) {
                    API.put(
                        `${MERCHANT_BUYER_RECOURSE_LIMIT}/${data.buyer_country_code}/${data.buyer_organization_number}`,
                        data
                    )
                        .then(() => {
                            actions.getCustomerRecourseLimit(
                                data.buyer_organization_number,
                                data.buyer_country_code,
                                data.currency
                            );
                            showCustomerSettings.set(false);

                            let activeTab;
                            customersTab.subscribe((value) => (activeTab = value));
                            actions.getCustomers(null, activeTab == "On recourse", null);
                            notificationState.actions.create(
                                NotificationType.SUCCESS,
                                success_msg_heading,
                                null,
                                success_msg_content
                            );
                        })
                        .catch((err: any) => {
                            notificationState.actions.create(
                                NotificationType.ERROR,
                                error_msg_heading,
                                null,
                                err.response.data.error_message || error_msg_content
                            );
                        })
                        .finally(() => buyerRecourseLimitLoading.set(false));
                } else {
                    API.post(MERCHANT_BUYER_RECOURSE_LIMIT, data)
                        .then(() => {
                            showCustomerSettings.set(false);

                            let activeTab;
                            customersTab.subscribe((value) => (activeTab = value));
                            actions.getCustomers(null, activeTab == "On recourse", null);
                            notificationState.actions.create(
                                NotificationType.SUCCESS,
                                success_msg_heading,
                                null,
                                success_msg_content
                            );
                        })
                        .catch((err: any) => {
                            notificationState.actions.create(
                                NotificationType.ERROR,
                                error_msg_heading,
                                null,
                                err.response.data.error_message || error_msg_content
                            );
                        })
                        .finally(() => buyerRecourseLimitLoading.set(false));
                }
            },
            getCustomerMaxAllowedRecourseLimit: (
                buyer_organization_number: string,
                buyer_country_code: string
            ) => {
                const error_msg_heading = "Limits can't be fetched";
                const error_msg_content =
                    "Please refresh the page. Or, use our live chat if the error persists.";

                buyerRecourseLimitMaxAllowedLoading.set(true);
                API.get(
                    MERCHANT_BUYER_RECOURSE_LIMIT_MAX_AMOUNT(
                        buyer_country_code,
                        buyer_organization_number
                    )
                )
                    .then((res: ICustomerRecourseLimitMaxAllowed) => {
                        buyerRecourseLimitMaxAllowed.set(res?.buyer_max_allowed_recourse_limit);
                    })
                    .catch(() => {
                        // if it fails for some reason, such as network failure between checkout and RE, then
                        // sets the max allowed limit for validating the input for recourse amount as null
                        notificationState.actions.create(
                            NotificationType.ERROR,
                            error_msg_heading,
                            null,
                            error_msg_content
                        );
                        buyerRecourseLimitMaxAllowed.set(null);
                    })
                    .finally(() => {
                        buyerRecourseLimitMaxAllowedLoading.set(false);
                    });
            },
            setCustomersTab(selectedTab: string) {
                customersTab.set(selectedTab);
            },
            toggleSettings(value: boolean) {
                showCustomerSettings.set(value);
            }
        }
    };
}

export const {
    buyerRecourseLimitLoading,
    buyerRecourseLimitMaxAllowedLoading,
    paymentTerms,
    customers,
    singleCustomer,
    customersLoading,
    cursorPage,
    cursorLimit,
    showCustomerSettings,
    customersTab,
    buyerRecourseLimit,
    buyerRecourseLimitMaxAllowed,
    actions
} = createCustomer();
