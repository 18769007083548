<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";

    import Carousel from "../../../../components/carousel/Carousel.svelte";
    import { LOGIN_SIGNUP_IMAGES } from "../../../../static/constant";
    import API from "../../../../services/apiService";
    import { BASE_URL } from "@/static/endPoints";
    import Loading from "../../../../components/Elements/Loading.svelte";
    import {
        account,
        actions,
        loggedInAsMerchant,
        userMerchants
    } from "../../../../store/merchant/account.store";
    import DropDownSearch from "../../../../components/Elements/DropDownSearch.svelte";
    import { listSort, SORT_TYPE } from "../../../../utils/functions";
    import type { IAccount } from "../../../../static/types";
    import environment from "@/services/environment";

    let selectedMerchant: string = "";
    $: dropDownList = ($userMerchants ?? []).map((merchant) => merchant.display_name);
    $: loading = $userMerchants == null;

    const userMerchantsUrl = `${BASE_URL}/v1/portal/user/merchants`;
    onMount(() => {
        if (!$userMerchants) {
            actions.getMerchantPortalUserMerchants();
        }
    });

    $: if ($userMerchants?.length == 1) {
        selectedMerchant = $userMerchants[0].display_name;
        selectMerchant();
    }

    function selectMerchant() {
        const merchantDetails = $userMerchants.find(
            (merchant) => merchant.display_name == selectedMerchant
        );
        API.post(
            `${userMerchantsUrl}/${merchantDetails.id}/login?is_partial_signup=${merchantDetails.is_partial_signup}`
        ).then(() => {
            loggedInAsMerchant.set(true);
            // reset of merchant
            account.set(<IAccount>{});
            navigate("/merchant/dashboard");
        });
    }
</script>

<div class="login-container">
    <div class="login-form-container">
        <div class="login-form-main">
            <!-- keep loading while waiting for automatic login and redirect when 1 merchant returned-->
            {#if loading || $userMerchants?.length == 1}
                <Loading color="var(--gray-400)" />
            {:else if $userMerchants?.length > 0}
                <div id="theme-logo" class="brand-logo">
                    <img src={environment.branding.logo} alt="logo" />
                </div>
                <h2 class="ctn-general-primary">{$_("selectMerchant.selectYourCompany")}</h2>
                <p class="text-sm" style="margin-bottom:1.5rem;">
                    {$_("selectMerchant.selectCompanyDescription")}
                </p>
                <DropDownSearch
                    id="search-company"
                    label={$_("selectMerchant.companyLabel")}
                    placeholder={$_("selectMerchant.searchCompanyPlaceholder")}
                    itemList={listSort(dropDownList, true, SORT_TYPE.string)}
                    bind:value={selectedMerchant}
                />
                <div style="display: flex;justify-content: center;">
                    <button
                        id="continue-with-merchant"
                        class="button-primary button-lg my-8 w-full"
                        disabled={!Object.keys(selectedMerchant).length}
                        on:click={selectMerchant}
                    >
                        {$_("components.continue")}
                    </button>
                </div>
                <p class="text-sm text-center">{$_("selectMerchant.dontSeeCompany")}</p>
            {:else}
                <div id="theme-logo" class="brand-logo">
                    <img src={environment.branding.logo} alt="logo" />
                </div>
                <h2 class="ctn-general-primary">{$_("errors.somethingWrong")}</h2>
                <p class="text-sm" style="margin-bottom:1.5rem;">
                    {$_("selectMerchant.cannotConnectAccount")}
                </p>
                <button
                    on:click={() => navigate("/auth/merchant/signup")}
                    style="margin:1rem 0rem 1rem 0rem;"
                    class="my-4 button-primary button-sm w-full"
                >
                    {$_("selectMerchant.signUpButton", {
                        values: { productName: environment.branding.productName }
                    })}
                </button>
                <button
                    on:click={() => navigate("/auth/merchant/login")}
                    class="button-secondary button-sm w-full">{$_("components.tryAgain")}</button
                >
            {/if}
        </div>

        <div class="m-auto mt-10 text-xs gray mb-10 text-center">
            <a target="_blank" href={environment.branding.privacyPolicyLink}>
                {$_("pages.privacyPolicy")}
            </a>
            <p class="mt-2">&copy; {new Date().getFullYear()} {environment.branding.footerText}</p>
        </div>
    </div>
    {#if environment.branding.displayName !== "Achteraf betalen"}
        <div class="carousel-container">
            <Carousel imageSources={LOGIN_SIGNUP_IMAGES} rotationInterval={7000} />
        </div>
    {/if}
</div>

<style lang="scss">
    .login-container {
        display: flex;
        height: 100%;
        flex: 1;
    }

    .login-form-container {
        display: flex;
        flex-direction: column;
        margin: auto;
        min-width: 600px;
    }

    .brand-logo {
        margin-bottom: 3rem;
    }

    h2 {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        margin-bottom: 8px;
    }

    .login-form-main {
        width: 100%;
        padding: 1rem 2rem;
        max-width: 500px;
        margin: auto;
    }

    .carousel-container {
        flex: 1;
    }

    @media (max-width: 900px) {
        .carousel-container {
            display: none;
        }
    }
</style>
