<script lang="ts">
    import { _ } from "svelte-i18n";
    import Fa from "svelte-fa";
    import { ItemWithLabel, Button } from "@two-ui/core";
    import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

    import {
        actions as customerActions,
        buyerRecourseLimit,
        buyerRecourseLimitLoading,
        customersLoading
    } from "@/store/merchant/customers.store";
    import { account, recourseEnabled } from "@/store/merchant/account.store";
    import environment from "@/services/environment";
    import API from "@/services/apiService";
    import LoadingWrapper from "@/components/Elements/LoadingWrapper.svelte";
    import { GET_COMBINED_CREDIT_LIMIT } from "@/static/endPoints";
    import utils from "@/utilsV2/currency";
    import RequestHigherLimitSidebar from "./RequestHigherLimitSidebar.svelte";
    import UnlockMoreVolumeSidebar from "./UnlockMoreVolumeSidebar.svelte";
    import RequestRecourseSidebar from "./RequestRecourseSidebar.svelte";

    export let singleCustomer;
    export let loadingCreditLimit = false;

    let showHigherLimitSidebar = false;
    let showMoreVolumeSidebar = false;
    let creditlimitItems = [];
    let creditLimitData = null;

    // Fetch credit limit details when singleCustomer is set
    $: if (singleCustomer && $account.feeCurrency) {
        fetchCreditLimitDetails();
    }

    async function fetchCreditLimitDetails() {
        loadingCreditLimit = true;
        try {
            creditLimitData = await API.get(
                `${GET_COMBINED_CREDIT_LIMIT(
                    singleCustomer.buyer_country_code,
                    singleCustomer.buyer_organization_number
                )}`
            );
            updateCreditLimitItems();
        } catch (e) {
            console.error(`Failed to fetch credit limit details: ${e}`);
        } finally {
            loadingCreditLimit = false;
        }
    }

    function updateCreditLimitItems() {
        if (creditLimitData) {
            const declineReason = creditLimitData.decline_reason;
            creditlimitItems = [
                {
                    label: $_("customer.creditLimit.partnersLimit", {
                        values: { displayName: environment.branding.displayName }
                    }),
                    value: utils.formatCurrency(creditLimitData.currency)(
                        creditLimitData.combined_credit_limit
                    )
                    // value: `<span class='text-lg font-bold'>${utils.formatCurrency(
                    //     creditLimitData.currency
                    // )(creditLimitData.credit_limit)}
                    //     <span class='text-Content-Semantic-Error-Primary'>
                    //         (0.00%)
                    //     </span></span> `
                },
                // {
                //     label: $_("customer.creditLimit.dateOfAdjustment"),
                //     value: "-"
                // },
                // {
                //     label: $_("customer.creditLimit.previousLimit"),
                //     value: "-"
                // },
                {
                    label: $_("customer.creditLimit.remainingCredit"),
                    value: utils.formatCurrency(creditLimitData.currency)(
                        creditLimitData.combined_available_credit
                    )
                },
                {
                    label: $_("plans.table.columns.status"),
                    value: Boolean(creditLimitData.combined_available_credit)
                        ? $_("riskManagement.fraudRules.active")
                        : $_("riskManagement.fraudRules.blocked")
                },
                ...(declineReason && !Boolean(creditLimitData.combined_available_credit)
                    ? [
                          {
                              label: $_("customer.creditLimit.reasonForLimitAdjustment"),
                              value:
                                  declineReason && !creditLimitData.max_order_value
                                      ? declineReason
                                      : "-"
                          }
                      ]
                    : [])
            ];
        }
    }

    function toggleHigherLimitSidebar() {
        showHigherLimitSidebar = !showHigherLimitSidebar;
    }

    function toggleMoreVolumeSidebar() {
        showMoreVolumeSidebar = !showMoreVolumeSidebar;
    }

    $: {
        if ($recourseEnabled && singleCustomer) {
            // merchant recourse fallback enabled only
            customerActions.getCustomerRecourseLimit(
                singleCustomer?.buyer_organization_number,
                singleCustomer?.buyer_country_code,
                singleCustomer?.currency
            );
        }
    }
</script>

<div class="gcard p-6 relative">
    <div class="flex items-center justify-between mb-6">
        <div>
            <h1 class="page-title">{$_("customer.creditLimit.title")}</h1>
            <p class="text-sm text-Content-General-Tertiary">
                {$_("customer.creditLimit.lastRefreshed", {
                    values: {
                        date: creditLimitData?.combined_last_updated
                            ? new Date(creditLimitData.combined_last_updated).toLocaleString()
                            : ""
                    }
                })}
            </p>
        </div>
        <Button
            on:click={fetchCreditLimitDetails}
            size="sm"
            variant="secondaryGray"
            content={$_("customer.creditLimit.refresh")}
        >
            <Fa icon={faArrowsRotate} size="sm" slot="leftIcon" />
        </Button>
    </div>
    {#if loadingCreditLimit}
        <div><LoadingWrapper loading={true} /></div>
    {:else}
        <section class="flex flex-wrap items">
            {#each creditlimitItems as item}
                <div class="w-1/2 mb-4 item-wrap">
                    <ItemWithLabel label={item.label} value={item.value} />
                </div>
            {/each}
        </section>
    {/if}
</div>

<div class="grid grid-cols-2 gap-6">
    <div class="gcard p-6 relative mt-4 !mr-0">
        <h2 class="text-lg font-bold">{$_("customer.creditLimit.requestHigherLimit")}</h2>
        <p class="mt-3 mb-7">
            {$_("customer.creditLimit.requestHigherLimitText", {
                values: { displayName: environment.branding.displayName }
            })}
        </p>
        <Button
            id="open-higher-limit-modal"
            on:click={toggleHigherLimitSidebar}
            size="sm"
            variant="secondaryColor"
            content={$_("login.learnMore")}
        />
    </div>

    {#if $buyerRecourseLimitLoading || $customersLoading}
        <div class="gcard p-6 relative mt-4 !ml-0">
            <LoadingWrapper loading={true} />
        </div>
    {:else}
        <div
            class:blur-sm={$buyerRecourseLimitLoading || $customersLoading}
            class="gcard p-6 relative mt-4 !ml-0"
        >
            {#if +$buyerRecourseLimit?.buyer_available_recourse_limit}
                <div class="flex items-center">
                    <h2 class="text-lg grow">
                        <b>{$_("customers.combined.settings.recourseFallback")}</b>
                        <span
                            class="ml-1 py-0.5 px-2 rounded-sm text-sm font-normal text-Content-Semantic-Success-Secondary bg-Border-Semantic-Success-Tertiary"
                            >{$_("customers.combined.settings.enabled")}</span
                        >
                    </h2>
                    <Button
                        id="edit-recourse"
                        on:click={toggleMoreVolumeSidebar}
                        size="md"
                        variant="tertiaryColor"
                        content={$_("components.edit")}
                    />
                </div>
                <p class="mt-3 mb-7">
                    {$_("customers.combined.settings.useRecourseAutomatically", {
                        values: { displayName: environment.branding.displayName }
                    })}
                </p>
                <div class="grid grid-cols-2 gap-4">
                    <ItemWithLabel
                        label={$_("customers.combined.recourseLimit")}
                        value={utils.formatCurrency($buyerRecourseLimit?.currency)(
                            $buyerRecourseLimit?.buyer_available_recourse_limit
                        )}
                    />
                    <ItemWithLabel
                        label={$_("customers.combined.currentRecourseExposure")}
                        value={utils.formatCurrency($buyerRecourseLimit?.currency)(
                            $buyerRecourseLimit?.current_recourse_exposure
                        )}
                    />
                </div>
            {:else}
                <h2 class="text-lg font-bold">
                    {$_("customer.creditLimit.unlockMoreVolume")}
                </h2>
                <p class="mt-3 mb-7">
                    {$_("customer.creditLimit.unlockMoreVolumeText", {
                        values: { displayName: environment.branding.displayName }
                    })}
                </p>
                <Button
                    id="open-recourse-modal"
                    on:click={toggleMoreVolumeSidebar}
                    size="sm"
                    variant="secondaryColor"
                    content={$_("customer.creditLimit.enable")}
                />
            {/if}
        </div>
    {/if}

    {#if showHigherLimitSidebar}
        <RequestHigherLimitSidebar {toggleHigherLimitSidebar} />
    {/if}

    {#if singleCustomer && showMoreVolumeSidebar}
        {#if $recourseEnabled}
            <UnlockMoreVolumeSidebar
                {toggleMoreVolumeSidebar}
                isRecourseEnabledForBuyer={Boolean(
                    +$buyerRecourseLimit?.buyer_available_recourse_limit
                )}
                selectedCustomer={singleCustomer}
            />
        {:else}
            <RequestRecourseSidebar {toggleMoreVolumeSidebar} selectedCustomer={singleCustomer} />
        {/if}
    {/if}
</div>
