<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onDestroy, onMount } from "svelte";
    import { Skeleton } from "@two-ui/core";

    import { searchApi } from "../../services/apiService";
    import Input from "../Elements/InputBox.svelte";
    import { getCompanySearchUrl } from "@/utils/functions";
    import countryName from "../../static/countryName";
    import { GB } from "../../static/constant";
    import helpers from "../../utilsV2/helpers";
    import type { IAddress } from "../../static/types";
    import { buyerDetails, orderReplica } from "@/store/merchant/allOrders.store";

    export let countryCode: keyof typeof countryName | null = null;
    export let error: string = "";
    export let isChecked: boolean = false;
    export let invalid: string = "";
    export let currentOrg: any = {};
    export let companyID: string = "";
    export let companyExternalID: string = "";
    export let address: IAddress;
    export let showId: boolean = true;
    export let required: boolean = true;
    export let company: string = "";
    export let isGettingAddress: boolean = true;
    export let placeholderText: string = $_("components.input.companyPlaceholder");
    export let addressRequired: boolean = true;
    export let zipCode: string = "";
    export let customClasses = "mt-8";

    let showResultsDropdown: boolean = false;
    let orgs: any[] = [];
    let orgsAddresses: any[] = [];
    let showSkeleton = false;
    let isSearchTriggeredForReplicatedOrder = false;

    const resultRefs = [];
    const fallbackSearch = (country: string) => !["SE", "GB", "NO"].includes(country);
    const displayPostCode = (country: string) => ["US"].includes(country);

    // Html element handling
    let inputCom: any = null;
    let dropDown: any = null;

    let selectedOrgIdx = -1;

    let companyNotFound = false;

    function getAddress(org: any, condition: boolean) {
        if (isGettingAddress) return;

        showResultsDropdown = false;

        if (!addressRequired) {
            company = org.name;
            isChecked = true;
            currentOrg = org;
            companyID = org.id;
            return;
        }

        isChecked = false;
        error = "";
        if (!condition || company === "" || !org.id) return;
        currentOrg = org;
        company = org.name;
        companyID = org.id;
        // //for mock testing
        // currentOrg.id = "01111111";
        const queryParams = currentOrg.lookup_id
            ? `?lookup_id=${currentOrg.lookup_id}`
            : `?company_type=${currentOrg.code}`;

        isGettingAddress = true;
        searchApi
            .get(
                `/v1/${countryCode.toLowerCase()}/company/${currentOrg.id}/address${
                    fallbackSearch(countryCode) ? queryParams : ""
                }`
            )
            .then((res) => {
                error = "";
                isChecked = true;
                address = {
                    streetAddress: res.data?.address?.streetAddress ?? "",
                    postalCode: res.data?.address?.postalCode ?? "",
                    city: res.data?.address?.city ?? ""
                };
                companyExternalID = res.data?.externalCompanyId;
            })
            .catch(() => {
                // Hacky temp solution until we implement internationalization
                const prefixedCountryName =
                    countryCode === GB ? "the UK" : countryName[`${countryCode}`];
                error = $_("components.input.noCompanyInCountry", {
                    values: { company, country: prefixedCountryName }
                });
                isChecked = false;
            })
            .finally(() => (isGettingAddress = false));
    }

    const search = (company: string) => {
        if (isGettingAddress) return;
        const baseUrl = getCompanySearchUrl(countryCode);
        const searchable = orgs.filter((org) => org.name === company).length > 0 ? false : true;
        if (searchable) {
            orgs = [];
            showResultsDropdown = true;
            companyNotFound = false;
            if (company.length > 2) {
                if (fallbackSearch(countryCode)) {
                    fallbackCompanySearch(baseUrl, company);
                } else {
                    showSkeleton = true;
                    searchApi
                        .get(`${baseUrl}/search?q=${encodeURIComponent(company)}&offset=0&limit=10`)
                        .then((res) => {
                            companyNotFound = res.data.data.items.length === 0;
                            orgs = res.data.data.items;
                            selectedOrgIdx = 0;
                            dropDown.scrollTop = 0;
                        })
                        .catch((err) => console.error(err))
                        .finally(() => {
                            showSkeleton = false;
                        });
                }
            } else {
                orgs = [];
            }
        }
    };

    const fallbackCompanySearch = (baseUrl: string, companyName: string) => {
        const url = `${baseUrl}?name=${encodeURIComponent(companyName)}${
            zipCode ? "&post_code=" + encodeURIComponent(zipCode.trim()) : ""
        }`;
        showSkeleton = true;
        searchApi
            .get(url)
            .then((res) => {
                const forceIndex = null;
                const forceValue = {};
                orgs = res.data.map((company) => {
                    return company.data;
                });
                if (forceIndex !== null && forceIndex > 0) {
                    orgs.splice(forceIndex, 1);
                    orgs.unshift(forceValue);
                }
                orgsAddresses = res.data.map((company) => company.address);
                companyNotFound = orgs.length === 0;
                selectedOrgIdx = 0;
                dropDown.scrollTop = 0;
            })
            .catch((err) => console.error(err))
            .finally(() => (showSkeleton = false));
    };

    const handleCompanyChange = helpers.debounce((e) => {
        company = e.detail.value;
        address = {
            streetAddress: "",
            postalCode: "",
            city: ""
        };
        companyID = "";
        companyExternalID = "";
        isGettingAddress = false;
        search(company);
    }, 400);

    $: if (countryName[`${countryCode}`] && !showResultsDropdown && addressRequired)
        getAddress(currentOrg, true);

    // trigger search if replicating order or coming from buyer details
    $: if (company && ($orderReplica || $buyerDetails) && !isSearchTriggeredForReplicatedOrder) {
        isSearchTriggeredForReplicatedOrder = true;
        handleCompanyChange({ detail: { value: company } });
        // programmatically click the first business result
        setTimeout(() => {
            if (resultRefs.length > 0 && resultRefs[0]) {
                resultRefs[0].click();
            }
        }, 2000);
    }

    const closeDown = (event) => {
        if (!inputCom.contains(event.target) && !dropDown.contains(event.target))
            showResultsDropdown = false;
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const selectOrg = (event) => {
        if (showResultsDropdown) {
            if (event.key === "ArrowDown" && selectedOrgIdx < orgs.length - 1) {
                selectedOrgIdx++;
                if (dropDown.scrollTop < 60 * (selectedOrgIdx - 3)) {
                    dropDown.scrollTop += 100;
                }
            }
            if (event.key === "ArrowUp" && selectedOrgIdx > 0) {
                selectedOrgIdx--;
                if (dropDown.scrollTop > 60 * selectedOrgIdx) {
                    dropDown.scrollTop -= 100;
                }
            }
            if (event.key === "Enter" && selectedOrgIdx > -1) {
                getAddress(orgs[selectedOrgIdx], orgs[selectedOrgIdx].id !== currentOrg.id);
                showResultsDropdown = false;
            }
        }
    };
    onMount(() => {
        document.addEventListener("click", closeDown);
        document.addEventListener("keydown", selectOrg);
    });

    onDestroy(() => {
        document.removeEventListener("click", closeDown);
        document.removeEventListener("keydown", selectOrg);
    });
</script>

<div class={`relative ${customClasses} text-sm`}>
    {#if error && company && !showResultsDropdown}
        <p class="text-center text-semantic-error">{error}</p>
    {/if}
    <div class="relative mt-2" bind:this={inputCom}>
        <div class="mt-1 font-medium">
            <Input
                label={$_("components.input.companyLabel")}
                {required}
                placeholder={placeholderText}
                value={company}
                on:change={handleCompanyChange}
                bind:invalid
                type="text"
                id="company"
            />
            {#if showId && isChecked}
                <p class="absolute h-7 top-9 z-10 gray company-id">
                    {companyID}
                </p>
            {/if}
        </div>
    </div>
    <div
        class="absolute z-10 w-full bg-white shadow-md dropdown-list"
        class:hidden={!showResultsDropdown}
        bind:this={dropDown}
    >
        {#if showSkeleton && !companyNotFound}
            <Skeleton
                height={14}
                width={75}
                radius="md"
                override={{ margin: "10px 8px 8px 16px" }}
            />
            <Skeleton
                height={14}
                width={50}
                radius="md"
                override={{ margin: "8px 8px 10px 16px" }}
            />
            <!-- divider -->
            <div class="border-t border-Border-General-Primary" />

            <Skeleton
                height={14}
                width={75}
                radius="md"
                override={{ margin: "10px 8px 8px 16px" }}
            />
            <Skeleton
                height={14}
                width={50}
                radius="md"
                override={{ margin: "8px 8px 10px 16px" }}
            />
            <!-- divider -->
            <div class="border-t border-Border-General-Primary" />

            <Skeleton
                height={14}
                width={75}
                radius="md"
                override={{ margin: "10px 8px 8px 16px" }}
            />
            <Skeleton
                height={14}
                width={50}
                radius="md"
                override={{ margin: "8px 8px 10px 16px" }}
            />
            <!-- divider -->
            <div class="border-t border-Border-General-Primary" />

            <Skeleton
                height={14}
                width={75}
                radius="md"
                override={{ margin: "10px 8px 8px 16px" }}
            />
            <Skeleton
                height={14}
                width={50}
                radius="md"
                override={{ margin: "8px 8px 10px 16px" }}
            />
        {:else}
            {#each orgs as org, key}
                <div
                    class="flex items-center py-2 pl-5 cursor-pointer dropdown-item"
                    class:bg-gray-200={selectedOrgIdx === key}
                    on:click={() => {
                        getAddress(org, currentOrg.id !== org.id);
                    }}
                    on:keypress
                    bind:this={resultRefs[key]}
                >
                    <div class="result-container">
                        <div style="display: flex;flex-direction: column;">
                            <p>{org.name}</p>
                            <p class="gray">{org.id}</p>
                        </div>
                        {#if displayPostCode(countryCode)}
                            <p class="gray">
                                {orgsAddresses[key].city}: {orgsAddresses[key].postal_code}
                            </p>
                        {/if}
                    </div>
                </div>
            {/each}
        {/if}
        {#if company?.length > 2 && companyNotFound}
            <div class="flex items-center py-2 pl-5 dropdown-item">
                <div>
                    <p>{$_("components.input.companyNotFound")}</p>
                </div>
            </div>
        {/if}
    </div>
</div>

<style>
    .company-id {
        white-space: nowrap;
        right: 1rem;
    }
    .result-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;
        padding-right: 1rem;
        align-items: center;
    }
</style>
