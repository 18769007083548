<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button as TwoUIButton } from "@two-ui/core";
    import { onMount, onDestroy } from "svelte";
    import { navigate } from "svelte-routing";
    import Fa from "svelte-fa";
    import { faArrowCircleDown, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

    import {
        DATA_TYPE,
        getTableColumns,
        invoiceColumns,
        creditNoteColumns
    } from "./productTable.constants";
    import modalState from "@/store/modals.store";
    import {
        type IMorderSummary,
        type IOrderItem,
        type IInvoice,
        NotificationType,
        LanguageCode,
        OrderState,
        OrderType,
        OrderOrigination
    } from "@/static/types";
    import MetaWrapper from "@/components/metadata/MetaWrapper.svelte";
    import LoadingWrapper from "@/components/Elements/LoadingWrapper.svelte";
    import Icon from "@/components/icons/Icon.svelte";
    import DownloadInvoice from "@/components/invoices/DownloadInvoice.svelte";
    import notificationState from "@/store/notifications.store";
    import utils from "@/utilsV2/currency";
    import { COUNTRIES } from "@/static/constant";
    import API from "@/services/apiService";
    import {
        GET_ORDER_DETAILS,
        GET_LATENT_ORDER_DETAILS,
        GET_ALL_INVOICES,
        UPDATE_INVOICE_EMAIL
    } from "@/static/endPoints";
    import {
        mapLatentOrderDetails,
        mapOrderDetails,
        mapOrderInvoiceDetails,
        mapOrderCreditNotes,
        mapFulfilledLineItems
    } from "@/utils/orderHelpers";
    import Input from "@/components/Elements/InputBox.svelte";
    import Selector from "@/components/Elements/Selector.svelte";
    import InvoicesSection from "./orderdetails/InvoicesSection.svelte";
    import ProductsInvoicedSection from "./orderdetails/ProductsInvoicedSection.svelte";
    import EditOrderSection from "./orderdetails/EditOrder.svelte";
    import PartialFulfillSection from "./orderdetails/PartialFulfillSection.svelte";
    import RefundedItems from "./orderdetails/RefundedItems.svelte";
    import CreditNotesSection from "./orderdetails/CreditNotesSection.svelte";
    import { account, signupComplete } from "@/store/merchant/account.store";
    import ToolTip from "@/components/tooltip/ToolTip.svelte";
    import { orderDetailMap } from "@/static/signupDemoMockResponses";
    import SignupIncompleteModal from "@/components/modals/AccountSetup/SignupIncompleteModal.svelte";
    import AdditionalOrderDetails from "./orderdetails/AdditionalOrderDetails.svelte";
    import OrderNotes from "./orderdetails/OrderNotes.svelte";
    import { getTooltipDetailsForOrderEdit } from "@/utilsV2/tooltipHelpers";
    import {
        actions,
        creditLimitState,
        creditLimitLoading
    } from "@/store/merchant/creditlimit.store";
    import { orderReplica, isCancelledRemainingOrder } from "@/store/merchant/allOrders.store";
    import CustomerAndPaymentDetails from "./CustomerAndPaymentDetails.svelte";
    import PageHeader from "@/components/pageHeader/PageHeader.svelte";
    import { CREATE_ORDER_PATH } from "./variables";

    export let id: string = window.location.pathname.split("/")[3];
    const isLatent = new URLSearchParams(window.location.search).has("latent");

    const modalId = {
        cancel: "CANCEL_ORDER",
        cancelRemaining: "CANCEL_REMAINING_ORDER",
        fulfill: "FULFILL_ORDER",
        refund: "REFUND_ORDER"
    };

    const countryLocale = {
        GB: "en-GB",
        NO: "nb-NO",
        SE: "sv-SE"
    };

    let tableColumns = getTableColumns($_("order.details.table.vat"));
    $: colspan = tableColumns.length - 3;
    let order: IMorderSummary | undefined;
    let state: OrderState | undefined;
    let loading: boolean = false;
    let isRefundProcess: boolean = false;
    let disabledRefundClass: string = "";
    let refundAmount: number = 0;
    let totalOrderRefundedAmount: number = 0;
    let totalOrderRefundedVAT: number = 0;
    let refundGroup: number[] = [];
    let totalRefundedItems: number = 0;
    let allProductsChecked: boolean = true;
    let customRefundChecked: boolean = false;
    let isPartialRefundProcess: boolean = false;
    let invalidRefund: boolean = false;
    const refundedIndexes: number[] = [];
    let noItemName: boolean = false;
    let orderInvoices: IInvoice[] = [];
    let orderCreditNotes: {}[] = [];
    let productsInvoiced: IOrderItem[] = [];
    let cancelledPartialOrders: IOrderItem[] = [];

    let isPartialFulfillmentProcess: boolean = false;
    let toBeInvoiced: IOrderItem[] = [];
    let invalidInvoiceEmail: boolean = false;
    let fulfilledAmount: number = 0;
    let editEmail: boolean = false;
    let isInstalmentOrder: boolean = false;
    let initialCreditNoteAmount = 0; // used in case of partial refund
    let taxRateForCustomRefundItem;

    const taxNaming = $_("order.details.table.vat");
    $: if ($account.countryCode == "US") {
        tableColumns = getTableColumns($_("order.details.table.tax"));
    }

    function translateColumnHeader(key, taxNaming) {
        switch (key) {
            case "item":
                return $_("order.details.table.name");
            case "unit_price":
                return $_("order.details.table.unit_price");
            case "quantity":
                return $_("order.details.table.quantity");
            case "discount_amount":
                return $_("order.details.table.discount_amount");
            case "net_amount":
                return $_("order.details.table.net_amount", { values: { taxNaming } });
            case "gross_amount":
                return $_("order.details.table.gross_amount", { values: { taxNaming } });
            default:
                return null;
        }
    }

    tableColumns = tableColumns.map((col) => ({
        ...col,
        label: translateColumnHeader(col.propertyName, taxNaming) || col.label
    }));

    function translateInvoiceColumnHeader(key) {
        switch (key) {
            case "number":
                return $_("order.details.invoiceTable.number");
            case "date_issued":
                return $_("order.details.invoiceTable.date_issued");
            case "due_date":
                return $_("order.details.invoiceTable.due_date");
            case "value":
                return $_("order.details.invoiceTable.value");
            case "customer_payment":
                return $_("order.details.invoiceTable.customer_payment");
            default:
                return "";
        }
    }

    const invoiceColumnsTranslated = invoiceColumns.map((col) => ({
        ...col,
        label: translateInvoiceColumnHeader(col.propertyName) || col.label
    }));

    const customRefundItem = {
        name: "",
        unit_price: null,
        quantity: null,
        tax_rate: null,
        tax_amount: 0,
        discount_amount: 0,
        gross_amount: null,
        net_amount: null,
        index: 0,
        tax_class_name: "",
        quantity_unit: "pcs",
        type: "PHYSICAL",
        description: ""
    };
    let filterItems: string[] = [];
    const refundOptions: string[] = [$_("order.fullOrder"), $_("order.partOfOrder")];
    let fulfilOptions: string[] = [$_("order.fullOrder"), $_("order.partOfOrder")];
    let selectedRefundType: string = "";
    let selectedFulfillType: string = "";
    let scrollBox;
    let scrollPos = 0;

    let enableFEPartialFulfillment = false;
    let enableInvoiceEditing = false;

    let shippingAddress: {} = {};
    let billingAddress: {} = {};
    let orderJSON: {} = {};

    let isEditMode: boolean = false;
    let tooltipContent: string = "";
    let tooltipWidth: string = "0px";
    let buyerCompany;
    let isOverdue: boolean = false;
    let maxOrderValue: number = -1;
    let lineItemsCopyForPartialRefunds = [];
    let currentLineItems = [];
    let isRefundedPartially = false;

    onMount(async () => {
        await initialiseOrderData();
    });

    onDestroy(() => {
        isCancelledRemainingOrder.set(false);
    });

    $: {
        lineItemsCopyForPartialRefunds = order?.lineItems
            ? JSON.parse(JSON.stringify(order?.lineItems))
            : [];
    }

    $: isInstalmentOrder = order?.features?.indexOf("instalment_invoice") > -1;

    $: {
        if (buyerCompany) {
            getCreditLimit();
        }
    }

    $: if (!$creditLimitLoading && $creditLimitState?.success) {
        isOverdue = $creditLimitState.creditLimit.isBuyerInArrears;
        maxOrderValue = $creditLimitState.creditLimit.maxSingleOrderValue;
    }

    function getCreditLimit() {
        const company = buyerCompany;
        actions.getCreditLimit(
            company.organization_number,
            company.country_prefix,
            company.company_name,
            ""
        );
    }

    $: {
        enableFEPartialFulfillment = $account?.flags?.enable_fe_partial_fulfillment === true;
        enableInvoiceEditing = $account?.flags?.enable_invoice_editing === true;
    }

    $: modalState.modals((values) => {
        if (values.props?.isRefunded) {
            resetRefundForm();
        }
        if (values.props?.isFulfilled) {
            initialiseOrderData();
        }
    });

    $: {
        if (!isOverdue) {
            ({ tooltipContent, tooltipWidth } = getTooltipDetailsForOrderEdit(order, $_));
        }
    }

    function setFulfilledAmount(res: any) {
        fulfilledAmount = res.fulfilled_amounts.reduce((accumulator, currentValue) => {
            const number = parseFloat(currentValue);
            if (!isNaN(number)) {
                return accumulator + number;
            } else {
                return accumulator;
            }
        }, 0);
    }

    async function initialiseOrderData() {
        loading = true;
        if (!$signupComplete) {
            setDemoData();
            return;
        }
        API.get(isLatent ? GET_LATENT_ORDER_DETAILS(id) : GET_ORDER_DETAILS(id))
            .then((res: any) => {
                if (isLatent) {
                    order = mapLatentOrderDetails(res);
                    shippingAddress = res?.order_json.shipping_address;
                    billingAddress = res?.order_json.billing_address;
                    buyerCompany = res?.order_json.buyer.company;
                    orderJSON = res?.order_json;
                } else {
                    order = mapOrderDetails(res);
                    shippingAddress = res.shipping_address;
                    billingAddress = res.billing_address;
                    buyerCompany = res.buyer.company;
                }
                if (order) {
                    if (res.fulfilled_amounts) {
                        setFulfilledAmount(res);
                    }
                    state =
                        order.status === "PARTIAL"
                            ? resolvePartialOrderState(res)
                            : resolveState(order.state);
                    getTotalOrderRefundedAmount();
                    if (order.status === "PARTIAL" || getIsRefundedPartially(order)) {
                        API.get(GET_ALL_INVOICES(order.orderID)).then((resp: any) => {
                            orderInvoices = mapOrderInvoiceDetails(resp.fulfilled_orders);
                            orderCreditNotes = mapOrderCreditNotes(resp.refunds);
                            productsInvoiced = mapFulfilledLineItems(resp.fulfilled_orders);
                            cancelledPartialOrders = resp.cancelled_partial_orders;
                            generatePendingFulfillmentList();
                            fulfilOptions = [$_("order.partOfOrder")];
                        });
                    } else if (state === "FULFILLED" || state === "REFUNDED") {
                        orderInvoices = mapOrderInvoiceDetails([res]);
                    } else {
                        generatePendingFulfillmentList();
                    }

                    if (order.creditNotes) {
                        initialCreditNoteAmount = order.creditNotes.reduce(
                            (acc, item) => (acc += +item.amount),
                            0
                        );
                    }
                }
                loading = false;
                currencyUpdated();
            })
            .catch((err) => {
                console.error(err);
                order = undefined;
                loading = false;
            });
    }

    function setDemoData() {
        const demoOrder = orderDetailMap[id];
        order =
            "order_json" in demoOrder
                ? mapLatentOrderDetails(demoOrder)
                : mapOrderDetails(demoOrder);
        setFulfilledAmount(demoOrder);
        state =
            order.status === "PARTIAL"
                ? resolvePartialOrderState(demoOrder)
                : resolveState(order.state);
    }

    function currencyUpdated() {
        switch (order.currency) {
            case "GBP":
                filterItems = ["0", "5", "20"];
                break;
            case "SEK":
                filterItems = ["0", "6", "12", "25"];
                break;
            case "NOK":
                filterItems = ["0", "12", "15", "25"];
                break;
            default:
                filterItems = ["0", "12", "15", "25"];
                break;
        }
    }

    function nameAdded() {
        noItemName = customRefundItem.name == "";
    }

    function handleRefundProcess() {
        startRefundProcess();
        if (selectedRefundType === $_("order.partOfOrder")) {
            customRefundOrder();
        }
    }

    function calculateAbsNetAmount(lineItem): number {
        return Math.abs(calculateNetAmount(lineItem));
    }

    function calculateNetAmount(lineItem): number {
        const value = lineItem.unit_price * lineItem.quantity - lineItem.discount_amount;
        return Number(value.toFixed(2));
    }

    function calculateAbsGrossAmount(lineItem): number {
        return Math.abs(calculateGrossAmount(lineItem));
    }

    function calculateGrossAmount(lineItem): number {
        if (!lineItem) return 0;
        const netAmount = calculateNetAmount(lineItem);
        const taxAmount = netAmount * lineItem.tax_rate;
        const value = netAmount + taxAmount;
        return Number(value.toFixed(2));
    }

    function handleCustomRefundChange() {
        refundAmount = 0;
        if (refundGroup.length > 0) {
            refundGroup.forEach((index) => {
                refundAmount += calculateGrossAmount(currentLineItems[index]);
            });
        }
        if (!taxRateForCustomRefundItem || taxRateForCustomRefundItem === "0") {
            customRefundItem.tax_rate = 0;
        } else {
            customRefundItem.tax_rate = Number(taxRateForCustomRefundItem) / 100;
        }
        customRefundItem.net_amount = calculateAbsNetAmount(customRefundItem);

        if (customRefundItem.net_amount > 0) {
            customRefundItem.tax_amount = Number(
                (customRefundItem.net_amount * customRefundItem.tax_rate).toFixed(2)
            );
            customRefundItem.gross_amount = Number(
                customRefundItem.net_amount + customRefundItem.tax_amount
            ).toFixed(2);
            refundAmount += Number(customRefundItem.gross_amount);
        }

        enableRefundButton();
    }

    $: modalState.modals((values) => {
        if (values.props?.isRefunded) {
            resetRefundForm();
        }
    });

    function resolveState(orderState: OrderState) {
        if (!orderState) {
            return OrderState.PENDING;
        }

        if (orderState === OrderState.UNVERIFIED && order.status === "REJECTED") {
            return OrderState.CANCELLED;
        }
        if (
            (orderState === OrderState.CONFIRMED || orderState === OrderState.VERIFIED) &&
            order.status === "APPROVED"
        ) {
            return OrderState.READY_TO_FULFILL;
        }

        return orderState;
    }

    function resolvePartialOrderState(response: any): OrderState {
        if (response.refunded_amounts.length > 0) {
            return OrderState.REFUNDED;
        }

        if (response.fulfilled_amounts.length === 0) {
            return OrderState.FULFILLING;
        }

        if (response.fulfilled_amounts.length > 0 && fulfilledAmount < Number(order.grossAmount)) {
            return OrderState.PART_FULFILLED;
        }

        if (fulfilledAmount === Number(order.grossAmount)) {
            return OrderState.FULFILLED;
        }

        return OrderState.PENDING;
    }

    function backToList() {
        navigate("/merchant/orders");
    }

    function cancelOrder() {
        modalState.actions.setModal(modalId.cancel, {
            orderID: order.orderID,
            merchantOrderID: order.merchantOrderID,
            isLatent
        });
    }

    function cancelRemainingOrder() {
        modalState.actions.setModal(modalId.cancelRemaining, {
            orderID: order.orderID,
            merchantOrderID: order.merchantOrderID,
            buyerCompanyName: order.companyName
        });
    }

    function handleFulfillProcess() {
        if (selectedFulfillType === $_("order.partOfOrder")) {
            startPartialFulfillProcess();
        } else {
            fulfillOrder();
        }
    }

    function fulfillOrder() {
        modalState.actions.setModal(modalId.fulfill, {
            orderID: order.orderID,
            merchantOrderID: order.merchantOrderID,
            buyerName: order.buyerName,
            lang: COUNTRIES[order.country]?.languageCode || LanguageCode.EN
        });
    }

    function startPartialFulfillProcess() {
        isPartialFulfillmentProcess = true;
        setTimeout(() => {
            document
                .getElementById("fulfillment-area")
                ?.scrollIntoView({ behavior: "smooth", block: "start" });
        });
    }

    function resetPartialFulfillProcess() {
        isPartialFulfillmentProcess = false;
        selectedFulfillType = "";
        document
            .getElementById("page-head")
            ?.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    function editInvoiceEmail() {
        editEmail = true;
        invalidInvoiceEmail = false;
    }

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function updateInvoiceEmail() {
        const emailArray = order.invoiceEmails
            .split(",")
            .map((email) => email.trim())
            .filter((email) => email !== "");
        invalidInvoiceEmail = emailArray.some((email) => !isValidEmail(email));

        if (!invalidInvoiceEmail) {
            API.post(UPDATE_INVOICE_EMAIL(order.orderID), emailArray)
                .then(() => {
                    notificationState.actions.create(
                        NotificationType.SUCCESS,
                        $_("order.details.email_update_success", {
                            values: { companyName: order.companyName }
                        }),
                        $_("order.details.email_update_notification")
                    );
                    editEmail = false;
                })
                .catch(() => {
                    notificationState.actions.create(
                        NotificationType.ERROR,
                        $_("order.details.email_update_error")
                    );
                });
        } else {
            invalidInvoiceEmail = true;
        }
    }

    function generatePendingFulfillmentList() {
        toBeInvoiced = order.lineItems.map((order) => ({ ...order }));

        if (productsInvoiced.length > 0) {
            productsInvoiced.forEach((invoicedItem) => {
                const correspondingOrder = toBeInvoiced.find(
                    (order) =>
                        order.name === invoicedItem.name &&
                        order.unit_price === invoicedItem.unit_price
                );
                if (correspondingOrder) {
                    if (correspondingOrder.quantity > invoicedItem.quantity) {
                        correspondingOrder.quantity -= invoicedItem.quantity;
                        correspondingOrder.net_amount = Number(
                            (correspondingOrder.net_amount - invoicedItem.net_amount).toFixed(2)
                        );
                        correspondingOrder.gross_amount = Number(
                            (correspondingOrder.gross_amount - invoicedItem.gross_amount).toFixed(2)
                        );
                        correspondingOrder.tax_amount = Number(
                            (correspondingOrder.tax_amount - invoicedItem.tax_amount).toFixed(2)
                        );
                        correspondingOrder.discount_amount = Number(
                            (
                                correspondingOrder.discount_amount - invoicedItem.discount_amount
                            ).toFixed(2)
                        );
                    } else {
                        const indexToRemove = toBeInvoiced.findIndex(
                            (order) =>
                                order.name === invoicedItem.name &&
                                order.unit_price === invoicedItem.unit_price
                        );
                        if (indexToRemove !== -1) {
                            toBeInvoiced.splice(indexToRemove, 1);
                        }
                    }
                }
            });
        }
    }

    //
    // Possible lineItems:
    //
    // 1. Full refund: null and full amount passed
    // 2. Custom refund: null and any amount
    // 3. Partial refund: order.lineItems filter out unchecked indexes
    //
    function refundOrder() {
        if (customRefundChecked && isPartialRefundProcess && customRefundItem.name == "") {
            noItemName = true;
            return;
        }

        let itemsToRefund = [];
        let taxSubtotals = [];

        itemsToRefund = refundGroup.length
            ? currentLineItems
                  .filter((_, index) => refundGroup.includes(index))
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  .map(({ id, ...itemWithoutId }) => itemWithoutId)
            : [];

        if (isPartialRefundProcess && customRefundChecked) {
            const tempRefundItem = { ...customRefundItem };
            tempRefundItem.tax_rate = Number((+customRefundItem.tax_rate).toFixed(2));
            tempRefundItem.tax_amount = Number(customRefundItem.tax_amount.toFixed(2));
            tempRefundItem.net_amount = calculateAbsNetAmount(customRefundItem);
            tempRefundItem.gross_amount = calculateAbsGrossAmount(customRefundItem);
            itemsToRefund.push(tempRefundItem);
        }

        const taxSubtotalMap = new Map();

        itemsToRefund.forEach((item) => {
            const tax_rate = item.tax_rate.toString();
            if (!taxSubtotalMap.has(tax_rate)) {
                taxSubtotalMap.set(tax_rate, {
                    tax_amount: 0,
                    taxable_amount: 0,
                    tax_rate
                });
            }

            const taxSubtotal = taxSubtotalMap.get(tax_rate);
            taxSubtotal.tax_amount += parseFloat(item.tax_amount);
            taxSubtotal.taxable_amount += parseFloat(item.net_amount);
        });

        taxSubtotals = Array.from(taxSubtotalMap.values()).map((taxSubtotal) => ({
            ...taxSubtotal,
            tax_amount: taxSubtotal.tax_amount.toFixed(2),
            taxable_amount: taxSubtotal.taxable_amount.toFixed(2)
        }));

        modalState.actions.setModal(modalId.refund, {
            orderID: order.orderID,
            merchantOrderID: order.merchantOrderID,
            refundAmount: refundAmount.toFixed(2),
            reason: "",
            currency: order.currency,
            lang: COUNTRIES[order.country]?.languageCode || LanguageCode.EN,
            lineItems: itemsToRefund,
            taxSubtotals
        });
    }

    function getTotalOrderRefundedAmount() {
        totalOrderRefundedAmount = 0;
        totalOrderRefundedVAT = 0;
        totalRefundedItems = 0;
        order.refunds?.forEach((refund) => {
            refund.buyer_refund_surcharges?.forEach((surcharge) => {
                const surchargeTaxAmount = Number(surcharge.amount) * Number(surcharge.tax_rate);
                totalOrderRefundedAmount += Number(surcharge.amount) + surchargeTaxAmount;
                totalOrderRefundedVAT += surchargeTaxAmount;
            });

            // Individually refunded items will appear in refunds lineItems
            if (refund.line_items.length) {
                refund.line_items.forEach((refundItem) => {
                    totalOrderRefundedAmount += Number(refundItem.gross_amount);
                    totalOrderRefundedVAT += Number(refundItem.tax_amount);
                    totalRefundedItems += refund.line_items.length;
                });
            }
            // Custom refunds don't show in lineitems
            // For VAT we need to use the tax_subtotals (not currently being returned from the BE)
            else {
                totalOrderRefundedAmount += Number(refund.amount);
                refund?.tax_subtotals?.forEach((subtotal) => {
                    totalOrderRefundedVAT += Number(subtotal.tax_amount);
                });
                totalRefundedItems += 1;
            }
        });

        // Taking the absolute value at the end of the refund loop
        totalOrderRefundedAmount = Math.abs(totalOrderRefundedAmount);
        totalOrderRefundedVAT = Math.abs(totalOrderRefundedVAT);
    }

    function startRefundProcess() {
        isRefundProcess = true;
        colspan = tableColumns.length - 2;
        resetProductItemsModel();
        checkPartialRefunds();
        setPartialRefundAmount(); // to calculate refundAmount
        setTimeout(() => {
            document
                .getElementById("refund-area")
                ?.scrollIntoView({ behavior: "smooth", block: "start" });
        });
    }

    //
    // Populates an array with the indexes of previously refunded order items (refundedIndexes)
    //
    function checkPartialRefunds() {
        if (
            (order.payment_status === "PARTIAL_REFUND" || state === OrderState.REFUNDED) &&
            totalOrderRefundedAmount
        ) {
            currentLineItems.forEach((item, index) => {
                item.refunds?.forEach((refund) => {
                    refund.line_items.forEach((refundItem) => {
                        if (
                            item.name === refundItem.name &&
                            item.gross_amount === Math.abs(refundItem.gross_amount) &&
                            item.quantity === Math.abs(refundItem.quantity)
                        ) {
                            refundedIndexes.push(index);
                        }
                    });
                });
            });
        }
    }

    //
    // Resets the model for the product items checkboxes (refundGroup)
    // Loops through order items and adds the index of each position in the array,
    // filtering out any item that might have been previously refunded (refundedIndexes)
    //
    // This is call at
    // - start of the refund process prior to checkboxes being displayed (startRefundProcess)
    // - at main checkbox toggle (toggleAllProductItems)
    // - when custon refund checkbox unticked (toggleCustomRefund)
    // - when refund process is cancelled (resetRefundForm)
    //
    function resetProductItemsModel() {
        refundGroup = [];

        currentLineItems.forEach((_, index) => {
            if (refundedIndexes.indexOf(index) === -1) {
                refundGroup.push(index);
            }
        });
        allProductsChecked = true;
        refundGroup = refundGroup;
        refundAmount = 0;
    }

    //
    // Toggles on / off all individual product item checkboxes
    //
    function toggleAllProductItems() {
        allProductsChecked = !allProductsChecked;
        if (!allProductsChecked) {
            refundGroup = [];
            if (isPartialRefundProcess) {
                customRefundChecked = false;
            }
        } else {
            if (order) order.lineItems = lineItemsCopyForPartialRefunds;
            resetProductItemsModel();
            if (isPartialRefundProcess) {
                customRefundChecked = true;
            }
        }
        setPartialRefundAmount();
    }

    //
    // Recalculates partial refund amount when  individual product items are checked on / off
    //
    function setPartialRefundAmount(index = -1) {
        setTimeout(() => {
            // act just when checkbox unselected
            if (index >= 0 && !refundGroup.includes(index)) {
                order.lineItems[index] = lineItemsCopyForPartialRefunds[index];
            }
            refundAmount = 0;
            refundGroup.forEach((index) => {
                const currentLineItem = currentLineItems[index];
                refundAmount += calculateGrossAmount(currentLineItem);
            });
            refundAmount += customRefundItem.gross_amount;
            enableRefundButton();
            allProductsChecked =
                refundGroup.length === currentLineItems.length - refundedIndexes.length;
        });
    }

    //
    // Enables custom refund process & displays the custom numeric input
    //
    function customRefundOrder() {
        isPartialRefundProcess = true;
        refundAmount = 0;
        refundGroup = [];
        enableRefundButton();
        setTimeout(() => {
            document
                .getElementById("refund-area")
                ?.scrollIntoView({ behavior: "smooth", block: "start" });
        });
        allProductsChecked = isPartialRefundProcess ? false : true;
    }

    //
    // Resets the form to standard full / partial refund settings
    //
    function toggleCustomRefund() {
        if (!customRefundChecked) {
            customRefundChecked = true;
            refundAmount += customRefundItem.gross_amount;
            noItemName = false;
        } else if (customRefundChecked) {
            refundAmount -= customRefundItem.gross_amount;
        }
    }

    function resetRefundForm() {
        isRefundProcess = false;
        isPartialRefundProcess = false;
        selectedRefundType = "";
        colspan = tableColumns.length - 3;
        resetProductItemsModel();
        disabledRefundClass = "";
        refundAmount = 0;
        if (order) order.lineItems = lineItemsCopyForPartialRefunds;
        document
            .getElementById("page-head")
            ?.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    function enableRefundButton() {
        invalidRefund =
            Number(refundAmount.toFixed(2)) >
                Number((Number(order.grossAmount) - totalOrderRefundedAmount).toFixed(2)) ||
            refundAmount < 0;
        if (refundAmount < 0) {
            refundAmount = 0;
        }
        disabledRefundClass = refundAmount === 0 || invalidRefund ? "-disabled" : "";
    }

    //
    // During refund process start, it foces product item checkbox to be disabled if it is a previously refunded item
    //
    function isDisabledProductItem(index) {
        if (isPartialRefundProcess) {
            return false;
        }
        return refundGroup.indexOf(index) === -1;
    }

    function formatProductValue(product: IOrderItem, index, column) {
        const rawValue = column.propertyName ? product[column.propertyName] : index + 1;
        switch (column.type) {
            case DATA_TYPE.AMOUNT:
                return formatAmount(rawValue);
            case DATA_TYPE.PERCENT:
                return formatPercent(rawValue);
            default:
                return rawValue;
        }
    }

    function calculateRefundAmount(product: IOrderItem, column, event) {
        const { value } = event.detail;
        if (column.label === $_("order.details.table.quantity")) {
            product.quantity = Number(value);
        } else if (column.label === $_("order.details.table.discount_amount")) {
            product.discount_amount = value || 0;
        } else {
            product.unit_price = value;
        }
        product.net_amount = calculateAbsNetAmount(product);
        product.tax_amount = parseFloat((product.net_amount * product.tax_rate).toFixed(2));
        product.gross_amount = calculateAbsGrossAmount(product);

        setPartialRefundAmount();
    }

    function formatAmount(amount: number | string) {
        const locale = countryLocale[order.country];
        return utils.formatCurrency(order.currency)(amount, { locale });
    }

    function formatPercent(aNumber: number | string) {
        return (typeof aNumber === "string" ? parseFloat(aNumber) : aNumber).toLocaleString("en", {
            style: "percent"
        });
    }

    function formatOrderDate(dateString: string) {
        const date = new Date(dateString);
        return (
            `${date.getDate() < 10 ? "0" : ""}${date.getDate()}` +
            ` ${date.toLocaleString("default", { month: "short" })}`
        );
    }

    function formatPurchaseDate(dateString: string) {
        const date = new Date(dateString);
        return (
            `${date.getDate() < 10 ? "0" : ""}${date.getDate()}` +
            `/${date.getMonth() < 9 ? "0" : ""}${date.getMonth() + 1}` +
            `/${date.getFullYear()}`
        );
    }

    function parseScroll() {
        scrollPos = scrollBox.scrollTop;
    }

    function onClickPostHandler(action) {
        if ($signupComplete) {
            return action();
        }
        return modalState.actions.setModal("", {}, SignupIncompleteModal);
    }

    function changeEditMode(event) {
        if (event.detail.subtotal) {
            order.amount = event.detail.subtotal;
            order.tax_amount = event.detail.tax;
            order.grossAmount = event.detail.total;
        }
        isEditMode = !isEditMode;
    }

    function onReplicateOrder() {
        orderReplica.set(order);
        navigate(`${CREATE_ORDER_PATH}/`);
    }

    function getIsRefundedPartially(order) {
        if (order && order.lineItems && order.refunds) {
            const lineItemsTotalGrossAmount = order.lineItems.reduce(
                (total, item) => total + Number(item.gross_amount),
                0
            );
            let refundsTotalGrossAmount = order.refunds.reduce(
                (total, item) => total + Number(item.amount),
                0
            );
            refundsTotalGrossAmount = Math.abs(refundsTotalGrossAmount);
            return (
                state === OrderState.REFUNDED &&
                lineItemsTotalGrossAmount !== refundsTotalGrossAmount
            );
        }
        return false;
    }

    $: {
        // take into account invoiced and refunded line items
        if (isRefundedPartially && isRefundProcess) {
            const refundedLineItems = [];
            order?.refunds.forEach((refund) => {
                refund.line_items.forEach((lineItem) => {
                    refundedLineItems.push(lineItem);
                });
            });

            const isNotFullyRefunded = (item) => {
                return !refundedLineItems.find(
                    ({ name, quantity }) => name === item.name && quantity === item.quantity
                );
            };
            currentLineItems = productsInvoiced.filter(isNotFullyRefunded) || [];
            // align quantity with refunded
            currentLineItems = currentLineItems.map((item) => {
                const refundedLineItem = refundedLineItems.find(({ name }) => name === item.name);
                if (refundedLineItem) {
                    const nextItem = {
                        ...item,
                        quantity: item.quantity - refundedLineItem.quantity
                    };
                    nextItem.net_amount = calculateAbsNetAmount(nextItem);
                    nextItem.tax_amount = Number(nextItem.net_amount * nextItem.tax_rate).toFixed(
                        2
                    );
                    nextItem.gross_amount = calculateAbsGrossAmount(nextItem);
                    return nextItem;
                } else {
                    return item;
                }
            });
        } else {
            currentLineItems =
                (isPartialFulfillmentProcess || isRefundProcess) &&
                ![
                    OrderState.READY_TO_FULFILL,
                    OrderState.FULFILLED,
                    OrderState.FULFILLING,
                    OrderState.REFUNDED
                ].includes(state)
                    ? productsInvoiced
                    : order?.lineItems || [];
        }
    }

    $: {
        isRefundedPartially = getIsRefundedPartially(order);
    }

    function translateStatus(status) {
        switch (status) {
            case "ready to fulfill":
                return $_("status.readyToFulfill");
            case "part-fulfilled":
                return $_("status.part_fulfilled");
            default:
                return $_(`status.${state.toLowerCase()}`);
        }
    }

    function getPageHeaderTitle(order): string {
        var title;
        if (order.merchantOrderID) {
            title =
                $_("order.details.orderNumber") +
                `${
                    order.merchantOrderID.substring(0, 50) +
                    (order.merchantOrderID.length > 50 ? " ..." : "")
                }`;
        } else {
            title =
                $_("order.details.order") +
                `${formatOrderDate(order.createDate)}, ${order.buyerName}`;
        }
        return title;
    }
</script>

<MetaWrapper title={$_("order.details.order_details_title", { values: { id } })}>
    <article class="order-details">
        {#if order}
            <PageHeader
                title={getPageHeaderTitle(order)}
                backLink="/merchant/orders"
                showSummaryInfo={scrollPos === 0}
            >
                <div slot="cta">
                    <div class="flex">
                        <div class="buttons-area-container">
                            {#if !isRefundProcess && !isPartialRefundProcess}
                                <TwoUIButton
                                    on:click={onReplicateOrder}
                                    size="sm"
                                    variant="secondaryGray"
                                    content={$_("order.details.replicate")}
                                />
                            {/if}

                            {#if !isRefundProcess && !isPartialFulfillmentProcess}
                                {#if state === OrderState.FULFILLED || isRefundedPartially || (order.status !== "CANCELLED" && order.status !== "QUOTED" && Number(order.grossAmount) > totalOrderRefundedAmount && order.payable_amount > 0)}
                                    <div class="select-container">
                                        <Selector
                                            required
                                            options={refundOptions}
                                            bind:value={selectedRefundType}
                                            placeholder={$_("order.details.refund")}
                                            on:change={handleRefundProcess}
                                            size="38px"
                                            backgroundColor="white"
                                            labelType="BUTTON"
                                            showInvalidError={false}
                                            customStyles="color: var(--gray-700)"
                                        />
                                    </div>
                                {/if}
                                {#if order.payment_status === "PARTIAL_REFUND" || state === OrderState.REFUNDED}
                                    <div class="button button--creditnote">
                                        <Fa
                                            icon={faArrowCircleDown}
                                            size="lg"
                                            color="var(--ctnInteractiveSemanticHighlightDefault)"
                                        />
                                        <a
                                            href={order.credit_note_download_url}
                                            class="download-creditnote-button"
                                            target="_blank"
                                            >{$_("order.details.download_credit_note")}</a
                                        >
                                    </div>
                                {/if}
                                {#if state === OrderState.FULFILLED || order.payment_status === "PARTIAL_REFUND" || state === OrderState.REFUNDED}
                                    <DownloadInvoice
                                        bind:order
                                        bind:isRefundProcess
                                        bind:isInstalmentOrder
                                    />
                                {/if}
                                {#if state === OrderState.PENDING || state === OrderState.UNVERIFIED || state === OrderState.READY_TO_FULFILL}
                                    <TwoUIButton
                                        on:click={() => onClickPostHandler(cancelOrder)}
                                        size="sm"
                                        variant="destructivePrimary"
                                        content={$_("order.details.cancel_order")}
                                    />
                                {/if}

                                {#if state === OrderState.PART_FULFILLED && !cancelledPartialOrders.length && !$isCancelledRemainingOrder}
                                    <TwoUIButton
                                        variant="destructiveSecondary"
                                        on:click={() => onClickPostHandler(cancelRemainingOrder)}
                                        content={$_("order.details.cancel_remaining_order")}
                                    />
                                {/if}

                                {#if !cancelledPartialOrders.length && !$isCancelledRemainingOrder && (((state === OrderState.PENDING || state === OrderState.READY_TO_FULFILL) && order.status !== "QUOTED") || (order.status === "PARTIAL" && (state === OrderState.PART_FULFILLED || state === OrderState.REFUNDED || state === OrderState.FULFILLING) && fulfilledAmount < Number(order.grossAmount)))}
                                    {#if enableFEPartialFulfillment}
                                        <div class="select-container select-container--blue">
                                            <Selector
                                                required
                                                options={fulfilOptions}
                                                id="fulfil_order"
                                                bind:value={selectedFulfillType}
                                                placeholder={$_("order.details.fulfil")}
                                                on:change={handleFulfillProcess}
                                                size="38px"
                                                backgroundColor="var(--primary-600)"
                                                labelType="BUTTON"
                                                showInvalidError={false}
                                            />
                                        </div>
                                    {:else}
                                        <TwoUIButton
                                            on:click={() => onClickPostHandler(fulfillOrder)}
                                            size="sm"
                                            variant="primary"
                                            content={$_("order.details.mark_as_fulfilled")}
                                        >
                                            <Fa
                                                icon={faCircleCheck}
                                                size="md"
                                                color="white"
                                                slot="leftIcon"
                                            />
                                        </TwoUIButton>
                                    {/if}
                                {/if}
                            {:else if isRefundProcess}
                                <button
                                    class="button button--refund-cancel"
                                    on:click={resetRefundForm}
                                >
                                    {$_("components.cancel")}
                                </button>
                                {#if !isPartialRefundProcess}
                                    <button
                                        class="button button--refund-custom"
                                        on:click={() => onClickPostHandler(customRefundOrder)}
                                        >{$_("order.details.refund_custom_amount")}</button
                                    >
                                {/if}
                                <button
                                    class="button button--refund-full{disabledRefundClass}"
                                    on:click={() => onClickPostHandler(refundOrder)}
                                    disabled={disabledRefundClass !== ""}
                                >
                                    {$_("order.refund")}
                                    {formatAmount(refundAmount)}
                                </button>
                            {/if}
                        </div>
                    </div>
                </div>
                <div slot="summary-info">
                    {#if scrollPos === 0}
                        <div class="flex">
                            <div class="badges">
                                <div
                                    class="header-order-state header-order-state--{state
                                        ?.toLowerCase()
                                        .replace(/\s+/g, '-')}"
                                >
                                    {translateStatus(state.toLowerCase())}
                                </div>
                                {#if order.approvedOnRecourse}
                                    <div class="recourse-tag">{$_("customers.onRecourse")}</div>
                                {/if}
                            </div>
                            <div class="header-order-details-item">
                                <div class="header-order-details-label">
                                    {$_("order.details.purchased")}
                                </div>
                                <div class="header-order-details-value">
                                    {formatPurchaseDate(order.createDate)}
                                </div>
                            </div>
                            <div class="header-order-details-item">
                                <div class="header-order-details-label">
                                    {$_("order.details.createdIn")}
                                </div>
                                {#if order.type === OrderType.MERCHANT_INITIATED || order.status === "QUOTED"}
                                    <div class="header-order-details-value">
                                        {$_("order.details.merchant_portal")}
                                    </div>
                                {:else if order.type === OrderType.BUYER_INITIATED}
                                    {#if order.origination === OrderOrigination.IN_STORE}
                                        <div class="header-order-details-value">
                                            {$_("order.details.your_store")}
                                        </div>
                                    {:else}
                                        <div class="header-order-details-value">
                                            {$_("order.details.your_website")}
                                        </div>
                                    {/if}
                                {:else}
                                    <div class="header-order-details-value">N/A</div>
                                {/if}
                            </div>
                        </div>
                    {/if}
                </div>
            </PageHeader>

            {#if isOverdue && state !== OrderState.CANCELLED}
                <div class="editing-unavailable">
                    <Icon name="alert-2" size={16} fill="var(--warning-900)" />
                    {@html $_("order.details.invoice_overdue")}
                </div>
            {/if}

            <div class="scroll-box" bind:this={scrollBox} on:scroll={parseScroll}>
                <div class="content">
                    <!-- Customer & Invoice -->
                    <CustomerAndPaymentDetails
                        {order}
                        {editEmail}
                        {invalidInvoiceEmail}
                        {updateInvoiceEmail}
                        {formatAmount}
                        {state}
                        {fulfilledAmount}
                        withCancelledRemaining={Boolean(cancelledPartialOrders.length) ||
                            $isCancelledRemainingOrder}
                        onEdit={() => onClickPostHandler(editInvoiceEmail)}
                    />
                    <!-- Customer End -->

                    <!-- Invoice Start -->

                    {#if orderInvoices?.length}
                        <section id="products-area" class="card card--table">
                            <InvoicesSection
                                title={$_("order.details.invoices")}
                                columns={invoiceColumnsTranslated}
                                items={orderInvoices}
                                locale={countryLocale[order.country]}
                                currency={order.currency}
                                bind:isInstalmentOrder
                            />
                        </section>
                    {/if}

                    {#if orderCreditNotes?.length}
                        <section id="credit-notes-area" class="card card--table">
                            <CreditNotesSection
                                title={$_("order.details.credit_notes")}
                                columns={creditNoteColumns}
                                items={orderCreditNotes}
                                locale={countryLocale[order.country]}
                                currency={order.currency}
                                creditNoteDownloadLink={order.credit_note_download_url}
                            />
                        </section>
                    {/if}

                    <!-- Invoice End -->

                    <!-- Product Start -->

                    {#if !isPartialFulfillmentProcess && !isEditMode}
                        <section id="refund-area" class="card card--table">
                            <div class="scrollbar">
                                <div class="main-bar">
                                    {#if isRefundProcess}
                                        <span class="card-title"
                                            >{$_("order.details.select_products_refund")}</span
                                        >
                                    {:else}
                                        <span class="card-title"
                                            >{$_("order.details.products")}</span
                                        >
                                        <div class="button-container">
                                            <ToolTip
                                                width={tooltipWidth}
                                                position="left-up"
                                                hideTip={tooltipContent === ""}
                                                content={tooltipContent}
                                            >
                                                <button
                                                    class="edit-button"
                                                    disabled={state === OrderState.FULFILLING ||
                                                        tooltipContent !==
                                                            $_(
                                                                "order.details.edit_products_button"
                                                            )}
                                                    on:click={changeEditMode}
                                                >
                                                    {$_("components.edit")}
                                                </button>
                                            </ToolTip>
                                        </div>
                                    {/if}
                                </div>

                                <div class="product-table-wrap">
                                    <table class="table">
                                        <thead class="table-head">
                                            {#if isRefundProcess}
                                                <th
                                                    class="table-header table-header--left checkbox-col"
                                                    style="width: 50px"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id="all-products"
                                                        name="all-products"
                                                        class="product-checkbox"
                                                        bind:checked={allProductsChecked}
                                                        on:click={toggleAllProductItems}
                                                    />
                                                </th>
                                            {/if}
                                            {#each tableColumns as column}
                                                <th
                                                    class="table-header table-header--{column.alignment}"
                                                    style="width: {column.width}"
                                                >
                                                    {@html column.label}
                                                </th>
                                            {/each}
                                        </thead>
                                        {#if invalidRefund}
                                            <tr class="header-error-row">
                                                <td colspan="9">
                                                    <div class="flex px-5 gap-2">
                                                        <Icon
                                                            name="cross-circle"
                                                            size={22}
                                                            fill="var(--old-brick)"
                                                        />
                                                        {#if refundAmount > 0}
                                                            {$_(
                                                                "order.details.large_refund_amount"
                                                            )}
                                                        {:else}
                                                            {$_(
                                                                "order.details.small_refund_amount"
                                                            )}
                                                        {/if}
                                                    </div>
                                                </td>
                                            </tr>
                                        {/if}
                                        {#each currentLineItems as product, index}
                                            <tr class="table-row">
                                                {#if isRefundProcess}
                                                    <td
                                                        class="table-cell table-cell--left checkbox-col"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            value={index}
                                                            name="refundGroup"
                                                            on:change={() =>
                                                                setPartialRefundAmount(index)}
                                                            bind:group={refundGroup}
                                                            class="product-checkbox"
                                                            disabled={isDisabledProductItem(index)}
                                                        />
                                                    </td>
                                                {/if}
                                                {#each tableColumns as column}
                                                    <td
                                                        class="table-cell table-cell--{column.alignment}"
                                                        class:table-cell--bold={column.bold}
                                                    >
                                                        {#if isRefundProcess && isPartialRefundProcess && [$_("order.details.unitPrice"), $_("order.details.table.quantity"), $_("order.details.table.discount_amount")].includes(column.label) && refundGroup.includes(index)}
                                                            <div class="w-full relative with-type">
                                                                <Input
                                                                    type="number"
                                                                    value={product[
                                                                        column.propertyName
                                                                    ]}
                                                                    placeholder="0"
                                                                    on:change={(event) =>
                                                                        calculateRefundAmount(
                                                                            product,
                                                                            column,
                                                                            event
                                                                        )}
                                                                    showInvalidError={true}
                                                                />
                                                                <span class="item-type"
                                                                    >{column.label ===
                                                                        $_(
                                                                            "order.details.unitPrice"
                                                                        ) ||
                                                                    column.label ===
                                                                        $_(
                                                                            "order.details.table.discount_amount"
                                                                        )
                                                                        ? order.currency
                                                                        : "Pc"}</span
                                                                >
                                                            </div>
                                                        {:else}
                                                            {formatProductValue(
                                                                product,
                                                                index,
                                                                column
                                                            )}
                                                        {/if}
                                                    </td>
                                                {/each}
                                            </tr>
                                        {/each}
                                        {#if isPartialRefundProcess}
                                            <tr class="table-row refund-row">
                                                <td
                                                    class="table-cell table-cell--left checkbox-col"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        name="refundGroup"
                                                        bind:checked={customRefundChecked}
                                                        on:click={toggleCustomRefund}
                                                        class="product-checkbox"
                                                    />
                                                </td>
                                                <td class="table-cell table-cell--left"
                                                    >{order.lineItems.length + 1}</td
                                                >
                                                <td
                                                    class="table-cell table-cell--left table-cell--bold"
                                                >
                                                    <div class="w-full relative">
                                                        <Input
                                                            type="text"
                                                            bind:value={customRefundItem.name}
                                                            placeholder={$_(
                                                                "order.details.enterCustomItemName"
                                                            )}
                                                            on:change={nameAdded}
                                                            on:click={() => {
                                                                if (!customRefundChecked)
                                                                    toggleCustomRefund();
                                                            }}
                                                            invalid={noItemName &&
                                                                customRefundChecked}
                                                            showInvalidError={false}
                                                        />
                                                    </div>
                                                </td>
                                                <td class="table-cell table-cell--right">
                                                    <div class="w-full relative with-type">
                                                        {#if customRefundChecked}
                                                            <Input
                                                                type="number"
                                                                bind:value={
                                                                    customRefundItem.unit_price
                                                                }
                                                                placeholder="1,000.00"
                                                                on:change={handleCustomRefundChange}
                                                            />
                                                            <span class="item-type"
                                                                >{order.currency}</span
                                                            >
                                                        {:else}
                                                            <div>
                                                                {customRefundItem.unit_price || ""}
                                                            </div>
                                                        {/if}
                                                    </div>
                                                </td>
                                                <td class="table-cell table-cell--right">
                                                    <div class="w-full relative with-type">
                                                        {#if customRefundChecked}
                                                            <Input
                                                                type="number"
                                                                bind:value={
                                                                    customRefundItem.quantity
                                                                }
                                                                placeholder={"0"}
                                                                on:change={handleCustomRefundChange}
                                                            />
                                                            <span class="item-type">Pc</span>
                                                        {:else}
                                                            <div>
                                                                {customRefundItem.quantity || ""}
                                                            </div>
                                                        {/if}
                                                    </div>
                                                </td>
                                                <td class="table-cell table-cell--right" />
                                                <td class="table-cell table-cell--right">
                                                    <div class="w-full relative with-type">
                                                        <Input
                                                            type="text"
                                                            value={customRefundItem.net_amount?.toFixed(
                                                                2
                                                            )}
                                                            disabled={true}
                                                        />
                                                        <span class="item-type"
                                                            >{order.currency}</span
                                                        >
                                                    </div>
                                                </td>
                                                <td class="table-cell table-cell--right">
                                                    <Selector
                                                        required
                                                        options={filterItems}
                                                        bind:value={taxRateForCustomRefundItem}
                                                        placeholder="-"
                                                        on:change={handleCustomRefundChange}
                                                        size="44px"
                                                        backgroundColor="white"
                                                        labelType="BUTTON"
                                                        showInvalidError={false}
                                                    />
                                                </td>
                                                <td
                                                    class="table-cell table-cell--right table-cell--bold"
                                                >
                                                    <div class="custom-refund-container">
                                                        <div class="w-full relative with-type">
                                                            <Input
                                                                type="text"
                                                                value={customRefundItem.gross_amount}
                                                                disabled={true}
                                                                showInvalidError={false}
                                                            />
                                                            <span class="item-type"
                                                                >{order.currency}</span
                                                            >
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="bottom-row">
                                                <td colspan="2" />
                                                <td>
                                                    <div class="relative">
                                                        {#if noItemName && customRefundChecked}
                                                            <div class="item-to-refund--red">
                                                                {$_("order.details.enterItemName")}
                                                            </div>
                                                        {:else}
                                                            <div class="item-to-refund">
                                                                {$_("order.details.itemToRefund")}
                                                            </div>
                                                        {/if}
                                                    </div>
                                                </td>
                                                <td colspan="5" />
                                                <td>
                                                    <div class="relative">
                                                        <div class="amount-to-refund">
                                                            {$_("order.details.amountToRefund")}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        {/if}
                                        <tr>
                                            <td {colspan} />
                                            <td class="table-cell summary-cell table-cell--right"
                                                >{$_("order.edit.subtotal")}</td
                                            >
                                            <td class="summary-cell" />
                                            <td
                                                class="table-cell summary-cell table-cell--right table-cell--bold"
                                            >
                                                {formatAmount(order.amount)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td {colspan} />
                                            <td class="table-cell summary-cell table-cell--right"
                                                >{taxNaming}</td
                                            >
                                            <td class="table-cell summary-cell table-cell--right" />
                                            <td class="table-cell summary-cell table-cell--right">
                                                {formatAmount(order.tax_amount)}
                                            </td>
                                        </tr>
                                        {#if order.payment_status !== "PARTIAL_REFUND" && state !== OrderState.REFUNDED}
                                            <tr>
                                                <td {colspan} />
                                                <td
                                                    class="table-cell summary-cell table-cell--right"
                                                    >{$_("order.edit.total")}</td
                                                >
                                                <td class="summary-cell" />
                                                <td
                                                    class="table-cell summary-cell table-cell--right table-cell--bold"
                                                >
                                                    {formatAmount(order.grossAmount)}
                                                </td>
                                            </tr>
                                        {:else if order.rootOrderID === ""}
                                            <tr>
                                                <td {colspan} />
                                                <td
                                                    class="table-cell table-cell--right table-cell--bold"
                                                    >{$_("order.details.total_before_refund")}</td
                                                >
                                                <td />
                                                <td
                                                    class="table-cell table-cell--right table-cell--bold"
                                                >
                                                    {formatAmount(order.grossAmount)}
                                                </td>
                                            </tr>
                                        {/if}
                                        {#if (order.payment_status === "PARTIAL_REFUND" || state === OrderState.REFUNDED) && !totalRefundedItems}
                                            {#if order.rootOrderID === ""}
                                                <tr>
                                                    <td {colspan} />
                                                    <td
                                                        class="table-cell summary-cell table-cell--right table-cell--red"
                                                        >{$_("order.details.refunded")}</td
                                                    >
                                                    <td class="summary-cell" />
                                                    <td
                                                        class="table-cell summary-cell table-cell--right table-cell--redbold"
                                                    >
                                                        {formatAmount(totalOrderRefundedAmount)}
                                                    </td>
                                                </tr>
                                            {/if}
                                            <tr>
                                                <td {colspan} />
                                                <td
                                                    class="table-cell table-cell--right table-cell--bold"
                                                    >{$_("order.edit.total")}</td
                                                >
                                                <td />
                                                <td
                                                    class="table-cell table-cell--right table-cell--bold"
                                                >
                                                    {formatAmount(
                                                        Number(order.grossAmount) -
                                                            totalOrderRefundedAmount
                                                    )}
                                                </td>
                                            </tr>
                                            {#if order.rootOrderID !== ""}
                                                <tr>
                                                    <td colspan="2" />
                                                    <td
                                                        colspan="4"
                                                        class="table-cell table-cell--right table-cell--bold"
                                                        >{$_(
                                                            "order.details.associatedCreditNotes"
                                                        )}</td
                                                    >
                                                    <td
                                                        colspan="2"
                                                        class="table-cell table-cell--right table-cell--redbold"
                                                    >
                                                        <a
                                                            href={order.credit_note_download_url}
                                                            class="download-creditnote-button"
                                                            target="_blank"
                                                            >{$_("components.download")}</a
                                                        >
                                                    </td>
                                                </tr>
                                            {/if}
                                        {/if}
                                    </table>
                                </div>

                                {#if isRefundProcess}
                                    <div class="refund-summary">
                                        {$_("order.details.refund_summary")}
                                    </div>

                                    <table class="table">
                                        {#if !isPartialRefundProcess}
                                            <tr>
                                                <td
                                                    class="table-cell summary-cell table-cell--left"
                                                    style="width: 250px;"
                                                    >{$_("order.details.full_order_refund")}</td
                                                >
                                                <td colspan="5" class="summary-cell" />
                                                <td class="summary-cell summary-cell--medium" />
                                                <td class="summary-cell summary-cell--small" />
                                                <td
                                                    class="table-cell summary-cell table-cell--right table-cell--redbold"
                                                >
                                                    -{formatAmount(order.grossAmount)}
                                                </td>
                                            </tr>
                                        {/if}
                                        <tr>
                                            <td colspan="6" class="summary-cell" />
                                            <td
                                                class="table-cell summary-cell table-cell--right table-cell--bold summary-cell--medium"
                                                >{$_("order.totalRefunded")}</td
                                            >
                                            <td class="summary-cell summary-cell--small" />
                                            <td
                                                class="table-cell summary-cell table-cell--right table-cell--redbold"
                                            >
                                                -{formatAmount(
                                                    Math.abs(initialCreditNoteAmount) + refundAmount
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" />
                                            <td
                                                class="table-cell table-cell--right table-cell--bold summary-cell--medium"
                                                >{$_("order.details.total_remaining")}</td
                                            >
                                            <td class="summary-cell--small" />
                                            <td
                                                class="table-cell table-cell--right table-cell--bold"
                                            >
                                                {formatAmount(
                                                    Number(order.grossAmount) -
                                                        totalOrderRefundedAmount -
                                                        refundAmount
                                                )}
                                            </td>
                                        </tr>
                                    </table>

                                    <div class="refund-buttons-area-container">
                                        <button
                                            class="button button--refund-cancel"
                                            on:click={resetRefundForm}
                                            >{$_("components.cancel")}</button
                                        >
                                        {#if !isPartialRefundProcess}
                                            <button
                                                class="button button--refund-custom"
                                                on:click={() =>
                                                    onClickPostHandler(customRefundOrder)}
                                                >{$_("order.details.refund_custom_amount")}</button
                                            >
                                        {/if}
                                        <button
                                            class="button button--refund-full{disabledRefundClass}"
                                            on:click={() => onClickPostHandler(refundOrder)}
                                            disabled={disabledRefundClass !== ""}
                                            >{$_("order.refund")}
                                            {formatAmount(refundAmount)}</button
                                        >
                                    </div>
                                {/if}
                            </div>
                        </section>

                        {#if (order.payment_status === "PARTIAL_REFUND" || state === OrderState.REFUNDED) && totalRefundedItems}
                            <section id="refunded-area" class="card card--table">
                                <RefundedItems
                                    {isRefundProcess}
                                    {order}
                                    {tableColumns}
                                    {colspan}
                                    {totalOrderRefundedAmount}
                                    {totalOrderRefundedVAT}
                                    {taxNaming}
                                />
                            </section>
                        {/if}
                    {/if}

                    <!-- Product End -->

                    <!-- Edit order Start -->

                    {#if isEditMode}
                        <section id="products-area" class="card card--table">
                            <EditOrderSection
                                {order}
                                title={$_("order.details.products")}
                                columns={tableColumns}
                                bind:items={order.lineItems}
                                locale={countryLocale[order.country]}
                                currency={order.currency}
                                {taxNaming}
                                {shippingAddress}
                                {billingAddress}
                                on:cancelEdit={changeEditMode}
                                {maxOrderValue}
                                {orderJSON}
                            />
                        </section>
                    {/if}

                    <!-- Edit order End -->

                    <!-- Partial fulfillment Start -->

                    {#if isPartialFulfillmentProcess}
                        <section id="fulfillment-area" class="card card--table">
                            <PartialFulfillSection
                                {order}
                                {fulfilledAmount}
                                title={$_("order.details.select_products_to_fulfill")}
                                columns={tableColumns}
                                items={toBeInvoiced}
                                locale={countryLocale[order.country]}
                                currency={order.currency}
                                {taxNaming}
                                {shippingAddress}
                                {billingAddress}
                                on:cancelpartialfulfillment={() => resetPartialFulfillProcess()}
                            />
                        </section>
                    {/if}

                    <!-- Partial fulfillment End -->

                    <!-- Invoiced items Start -->

                    {#if productsInvoiced?.length && Number(order.grossAmount) > fulfilledAmount}
                        <section id="products-area" class="card card--table">
                            <ProductsInvoicedSection
                                title={$_("order.details.products_invoiced")}
                                columns={tableColumns}
                                items={productsInvoiced}
                                locale={countryLocale[order.country]}
                                currency={order.currency}
                                {taxNaming}
                                totalValue={Number(order.grossAmount)}
                            />
                        </section>
                    {/if}
                    <!-- Invoiced items End -->

                    <!-- Notes Start -->

                    <section class="details">
                        <div class="card two-col editable-card">
                            <AdditionalOrderDetails
                                bind:order
                                {shippingAddress}
                                {billingAddress}
                                enableEdit={enableInvoiceEditing}
                                {orderJSON}
                            />
                        </div>
                        <div class="card two-col editable-card">
                            <OrderNotes
                                bind:order
                                {shippingAddress}
                                {billingAddress}
                                enableEdit={enableInvoiceEditing}
                                {orderJSON}
                            />
                        </div>
                    </section>

                    <!-- Notes End -->
                </div>
            </div>
        {:else if loading}
            <div class="loader-wrap">
                <LoadingWrapper {loading} />
            </div>
        {:else}
            <div class="w-full h-full m-auto flex flex-col justify-center items-center pt-8">
                <img src="/pictures/mailboxstate.svg" alt="mailbox" />
                <p class="my-4 font-semibold text-black-dark text-center">
                    {$_("order.details.error_fetching_order")}
                </p>
            </div>
        {/if}
    </article>
</MetaWrapper>

<style lang="scss">
    .order-details {
        width: 100%;
        height: 100vh;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .order-header {
        display: flex;
        flex-direction: column;
        gap: 32px;
        background-color: var(--basic);
        width: 100%;
        padding: 32px;
        border-bottom: 1px solid #e4e7ec;
    }

    .two-col {
        width: 50%;
    }

    .editable-card {
        display: flex;
        flex-direction: column;
    }

    .flex {
        display: flex;
    }

    .header-order-container {
        display: grid;
        column-gap: 18px;
        grid-template-columns: 30px 1fr;
    }

    .subheader {
        grid-column: 2;
        font-size: 14px;
        line-height: 20px;
    }

    .header-order {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
    }

    .badges {
        display: flex;
        gap: 8px;
        margin-inline-end: 16px;
    }

    .recourse-tag {
        background-color: var(--gray-700);
        color: #fff;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        align-items: center;
        padding: 4px 8px;
    }

    .header-order-state {
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding: 4px 8px;
        display: flex;
        align-items: center;

        &--fulfilled,
        &--fulfilling,
        &--part-fulfilled {
            color: var(--success-600);
            background-color: var(--success-50);
        }

        &--unverified,
        &--pending {
            color: var(--gray-500);
            background-color: var(--gray-100);
        }
        &--ready-to-fulfill {
            color: var(--ctnSemanticInfoPrimary);
            background-color: var(--bgSemanticInfoUltraLight);
        }

        &--cancelled,
        &--refunded {
            color: var(--error-700);
            background-color: var(--error-50);
        }
    }

    .header-order-details-item {
        padding-right: 20px;
    }

    .header-order-details-label {
        color: #667085;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    .header-order-details-value {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    .buttons-area-container {
        display: flex;
        gap: 8px;
        margin-left: auto;
        justify-content: flex-end;
    }

    .refund-buttons-area-container {
        display: flex;
        gap: 8px;
        margin-left: auto;
        float: right;
        padding-right: 24px;
        padding-bottom: 24px;
    }

    .button {
        display: flex;
        gap: 10px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 10px 20px;
        height: 40px;

        &--refund,
        &--cancel {
            color: var(--error-600);
            background-color: var(--error-50);
            min-width: 122px;
        }

        &--createrefund {
            color: var(--gray-700);
            background-color: var(--basic);
            border-color: var(--gray-300);
            border-style: solid;
            border-width: 1px;
            min-width: 130px;
        }

        &--createrefund:hover {
            background-color: var(--gray-300);
        }

        &--createrefund-disabled {
            color: var(--gray-300);
            background-color: var(--basic);
            border-color: var(--gray-300);
            border-style: solid;
            border-width: 1px;
            pointer-events: none;
        }

        &--resend {
            color: var(--primary-700);
            background-color: var(--primary-50);
        }

        &--creditnote {
            color: var(--primary-700);
            background-color: var(--primary-50);
            display: flex;
            min-width: 228px;
        }

        &--download {
            color: var(--basic);
            background-color: var(--primary-600);
            display: flex;
        }

        &--download-disabled {
            color: var(--basic);
            background-color: var(--primary-200);
            display: flex;
        }

        &--fulfill {
            color: var(--basic);
            background-color: var(--primary-600);
            min-width: 170px;
        }

        &--refund-cancel {
            color: var(--gray-700);
            background-color: var(--basic);
            border-color: var(--gray-300);
            border-style: solid;
            border-width: 1px;
        }

        &--refund-cancel:hover {
            background-color: var(--gray-300);
        }

        &--refund-custom {
            color: var(--primary-700);
            background-color: var(--primary-50);
            min-width: 190px;
        }

        &--refund-full {
            color: var(--basic);
            background-color: var(--primary-600);
            min-width: 152px;
        }

        &--refund-full-disabled {
            color: var(--basic);
            background-color: var(--primary-200);
        }
    }

    .download-invoice-button {
        min-width: 135px;
        margin-left: 9px;
    }

    .download-creditnote-button {
        min-width: 150px;
    }

    .disabled {
        pointer-events: none;
    }

    .details {
        display: flex;
        gap: 16px;
    }

    .content {
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .scroll-box {
        overflow: auto;
        height: calc(100vh - 85px);
    }

    .card {
        flex-basis: 100%;
        border-radius: 0.5rem;
        background-color: var(--basic);
        padding: 1.5rem;
        position: relative;
        --tw-bg-opacity: 1;

        border: 1px solid #e4e7ec;
        box-shadow: 0 0 6px rgba(208, 213, 221, 0.25);

        &--table {
            padding: 0;
        }
    }

    .card-title {
        display: flex;
        gap: 10px;
        font-weight: 700;
        line-height: 24px;
    }

    .card--table .card-title {
        padding: 24px;
    }

    .card-date-container {
        background-color: var(--gray-100);
        border-radius: 0.5rem;
        padding: 4px 8px 4px 8px;
        margin-left: 8px;

        &--warning {
            background-color: var(--warning-300);
            color: var(--warning-600);
        }

        &--error {
            background-color: var(--error-50);
            color: var(--error-700);
        }

        &--success {
            background-color: var(--success-50);
            color: var(--success-600);
        }
    }

    .card-date {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    .card-due {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding-right: 3px;
    }

    .bold-700 {
        font-weight: 700;
    }

    .bold-400 {
        font-weight: 400;
    }

    .product-table-wrap {
        width: 100%;
        overflow: auto;
    }

    .table {
        width: 100%;
        min-width: 930px;
        table-layout: fixed;
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-700);
        margin-bottom: 24px;

        // global resets
        border-collapse: collapse;
        border-spacing: unset;
    }

    th,
    td {
        cursor: default;
    }

    .table-head {
        background-color: var(--bgGeneralQuarterary);
        color: var(--ctnGeneralSecondary);
    }

    .table-header {
        width: 100%;
        padding: 0 8px;
        height: 48px;

        &:first-child {
            padding-left: 24px;
        }

        &:last-child {
            padding-right: 24px;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }
    }

    .table-row {
        border-bottom: 1px solid var(--gray-200);
    }

    .table-cell {
        padding: 0 8px;
        font-weight: 400;

        &:first-child {
            padding-left: 24px;
        }

        &:last-child {
            padding-right: 24px;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }

        &--bold {
            font-weight: 500;
            color: var(--gray-800);
        }

        &--red {
            color: var(--error-700);
        }

        &--redbold {
            font-weight: 700;
            color: var(--error-700);
        }

        &--linkbold {
            font-weight: 700;
            color: var(--primary-700);
        }
    }

    .summary-cell {
        border-bottom: 1px solid var(--gray-200);

        &--medium {
            width: 150px;
        }

        &--small {
            width: 100px;
        }
    }

    .table-row-short {
        height: 35px;
    }

    .refund-summary {
        width: 100%;
        background-color: var(--gray-50);
        color: var(--gray-800);
        height: 54px;
        padding: 12px 24px 12px 24px;
    }

    .tick {
        border-style: none;
        display: inline;
        height: auto;
        margin-left: 5px;
        max-width: 12px;
        vertical-align: baseline;
        width: 12px;
    }

    .initial-order-value {
        margin-left: 5px;
    }

    .invoice-refunded {
        color: var(--error-600);
        font-weight: 700;
        margin-left: 5px;
    }

    .product-checkbox {
        // color: var(--primary-600);
        background-color: var(--basic);
        height: 16px;
        width: 16px;
    }

    .checkbox-col {
        padding-top: 5px;
    }

    .custom-refund-container {
        position: relative;
        text-align: right;
    }

    .custom-refund {
        border-radius: 0.5rem;
        color: var(--gray-500);
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: right;
        width: 50%;
        height: 44px;
    }

    .custom-refund-currency {
        position: absolute;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding: 12px 0 12px 14px;
        top: 17px;
        left: 50%;
    }

    .custom-refund-warning {
        position: absolute;
        padding: 12px 0 12px 14px;
        top: 20px;
        right: 3%;
    }

    .refund-row {
        height: 80px;
        border-bottom: none;
    }

    @media only screen and (max-width: 1800px) {
        .custom-refund {
            width: 80%;
        }

        .custom-refund-currency {
            left: 20%;
        }
    }

    @media only screen and (max-width: 1200px) {
        .custom-refund {
            width: 100%;
        }

        .custom-refund-currency {
            left: 10%;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    :global(input[type="number"]) {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    .amount-to-refund {
        color: var(--gray-500);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        position: absolute;
        width: max-content;
        right: 24px;
        top: -24px;

        &--red {
            color: var(--error-700);
            position: absolute;
            width: max-content;
            right: 24px;
            top: -24px;
        }
    }

    .item-to-refund {
        color: var(--gray-500);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;

        position: absolute;
        width: max-content;
        left: 8px;
        top: -24px;

        &--red {
            color: var(--error-700);
            position: absolute;
            width: max-content;
            left: 8px;
            top: -24px;
        }
    }

    .bottom-row {
        height: 24px;
        border-bottom: 1px solid var(--gray-200);
    }

    * :global(.select-container--blue svg > path) {
        fill: white;
    }

    * :global(.select-container--blue .selector-button > div) {
        background-color: var(--primary-600);
        color: white;
    }

    * :global(.select-container--blue .selector-button button:focus) {
        background: var(--primary-600) !important;
        color: white;
    }

    * :global(.select-container--blue .selector-button) {
        top: 0px;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 8px;
    }

    * :global(.tooltip-wrapper > .tooltip) {
        opacity: 1;
        visibility: initial;
        z-index: 1;
        border-radius: 2px;
        background: var(--Basic-White, #fff);
        box-shadow:
            0px 2px 4px -2px rgba(16, 24, 40, 0.06),
            0px 4px 8px -2px rgba(16, 24, 40, 0.1);

        left: 0 !important;
        transform: translate(0px, -56px) !important;
    }

    .link {
        color: var(--royal-blue);
    }

    .header-error-row {
        color: var(--old-brick);
        background-color: #fef3f2;
        height: 2.5rem;
        padding: 0.5rem;
    }

    .main-bar {
        display: flex;
        justify-content: space-between;
    }

    .button-container {
        margin: auto 1rem;
        display: flex;
        gap: 1rem;
        padding: 0.7rem;

        color: var(--primary-600);
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        .edit-button:disabled {
            color: var(--gray-400);
        }
    }

    .editing-unavailable {
        display: flex;
        height: 44px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;
        background: #fbce49;
        color: var(--warning-900);

        font-family: Aeonik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .replicate-btn {
        color: var(--ctnInteractiveGeneralDefault);
        white-space: nowrap;
        border: 1px solid var(--borderGeneralSecondary);
        font-weight: 400;

        &:hover {
            background-color: var(--bgInteractiveGeneralHover);
        }

        &:focus {
            box-shadow:
                0px 0px 0px 2px var(--borderGeneralSecondary),
                0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
    }
</style>
