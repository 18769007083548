<script lang="ts">
    import Fa from "svelte-fa";
    import { _ } from "svelte-i18n";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

    import { createEventDispatcher } from "svelte";
    import TableCell from "./TableCell.svelte";
    import Icon from "../../components/icons/Icon.svelte";
    import ToolTip from "../tooltip/ToolTip.svelte";
    import LoadingWrapper from "../Elements/LoadingWrapper.svelte";
    import CursorPagination from "../../components/pagination/CursorPagination.svelte";

    const dispatch = createEventDispatcher();

    export let numRows = 10;
    export let numAllRecords = 0;
    export let tableHeaders = [];
    export let tableRows = [];
    export let pageNum = 1;
    export let useBackendPagination = false;
    export let loading: boolean = false;
    export let itemSelected: CallableFunction = (event, tableRow) => {
        return { event, tableRow };
    };
    export let currentSort = "";
    export let rowDescription = "";
    export let useCursorPagination = false;
    export let cursorNextPage = "";
    export let cursorPrevPage = "";
    export let tableContainerNoOverflow = false;
    export let id = "";

    let numPages = Math.ceil(numAllRecords / numRows);
    let sortDown = true;

    function nextPage() {
        if (pageNum < numAllRecords / numRows) {
            pageNum++;
            pageChanged();
        }
    }

    function prevPage() {
        if (pageNum > 1) {
            pageNum--;
            pageChanged();
        }
    }

    function iconClicked(item) {
        dispatch("tableIconClicked", {
            id: item.detail.id,
            action: item.detail.action
        });
    }

    function pageChanged(pageReference: string | null = null) {
        dispatch("pagechanged", {
            pageNum,
            pageReference
        });
    }

    function sortData(dataColumn) {
        dispatch("sorting", {
            sortItem: dataColumn
        });
        if (currentSort !== dataColumn) {
            currentSort = dataColumn;
            sortDown = true;
        } else {
            sortDown = !sortDown;
        }
    }

    $: {
        if (tableHeaders && numAllRecords) {
            numPages = Math.ceil(numAllRecords / numRows);
            if (pageNum > numPages) pageNum = numPages;
        }
    }
    const isTableHeaderRightAlign = (type) => type === "right-align";
</script>

{#if loading || numAllRecords}
    <div {id} class="table-container" class:no-overflow={tableContainerNoOverflow}>
        <table class="w-full text-left table-auto">
            <thead class="text-xs table-head">
                {#each tableHeaders as tableHeader, index}
                    <th
                        class={`m-auto ${tableHeader.customClasses || ""}`}
                        class:clickable={tableHeader.clickable}
                        class:date={tableHeader.type === "date"}
                        class:number={tableHeader.type === "number"}
                        class:status={tableHeader.type === "status"}
                        class:customer-details={tableHeader.type === "customer-details"}
                        class:wide-customer-details={tableHeader.type === "wide-customer-details"}
                        class:right-align-th={isTableHeaderRightAlign(tableHeader.type)}
                        on:click={() => sortData(tableHeader.title)}
                    >
                        <div class="flex my-auto items-center">
                            {#if !tableHeader.notext}<div>
                                    {tableHeader.translation || tableHeader.title}
                                </div>{/if}
                            {#if tableHeader.clickable}
                                <div
                                    class="my-auto px-2"
                                    class:icon-rotate={currentSort === tableHeader.title &&
                                        sortDown}
                                >
                                    <Icon
                                        name="arrow-up"
                                        size={20}
                                        fill={currentSort === tableHeader.title
                                            ? "var(--ctnInteractiveSemanticHighlightDefault)"
                                            : "grey"}
                                    />
                                </div>
                            {:else if tableHeader.tooltip}
                                <div class="tooltip-container" style="margin-top: 2px">
                                    <ToolTip
                                        width="250px"
                                        position={index == 0 ? "right" : "top"}
                                        content={tableHeader.tooltipTranslation ||
                                            tableHeader.tooltip}
                                    >
                                        <Fa
                                            size="md"
                                            icon={faCircleQuestion}
                                            color="var(--primary-500)"
                                        />
                                    </ToolTip>
                                </div>
                            {/if}
                        </div>
                    </th>
                {/each}
            </thead>
            <tbody>
                {#if loading}
                    <tr class="loading-tr">
                        <td colspan="100">
                            <div class="loading-container">
                                <LoadingWrapper {loading} />
                            </div>
                        </td>
                    </tr>
                {:else if useBackendPagination}
                    {#each tableRows as tableRow}
                        <tr
                            on:click={(event) => {
                                console.debug(event);
                                itemSelected(event, tableRow);
                            }}
                        >
                            {#each tableHeaders as tableHeader}
                                <td
                                    class:right-align-td={isTableHeaderRightAlign(tableHeader.type)}
                                >
                                    <TableCell
                                        cellItem={tableRow[tableHeader.title]}
                                        type={tableHeader.type}
                                        on:itemClicked={iconClicked}
                                    />
                                </td>
                            {/each}
                        </tr>
                    {/each}
                {:else}
                    {#each tableRows as tableRow, idx}
                        {#if idx >= numRows * pageNum - numRows && idx < numRows * pageNum}
                            <tr
                                on:click={(event) => {
                                    console.debug(event);
                                    itemSelected(event, tableRow);
                                }}
                            >
                                {#each tableHeaders as tableHeader}
                                    <td
                                        class:cell-action={tableRow[tableHeader.title].actionModal}
                                        class:cell-max-width={!tableRow[tableHeader.title]
                                            .actionModal}
                                        class:right-align-td={isTableHeaderRightAlign(
                                            tableHeader.type
                                        )}
                                    >
                                        <TableCell
                                            cellItem={tableRow[tableHeader.title]}
                                            type={tableHeader.type}
                                            on:itemClicked={iconClicked}
                                        />
                                    </td>
                                {/each}
                            </tr>
                        {/if}
                    {/each}
                {/if}
            </tbody>
        </table>
    </div>
{/if}

{#if !loading && numAllRecords}
    {#if useCursorPagination}
        <CursorPagination
            {loading}
            setPage={pageChanged}
            rowCount={tableRows.length}
            pageLimit={numRows}
            nextPage={cursorNextPage}
            prevPage={cursorPrevPage}
            rowsDescription={rowDescription.toLowerCase()}
            bind:pageNum
        />
    {:else}
        <div class="py-4 pr-20 flex justify-end page-counter">
            <span class="mr-4"
                >{`${numRows * pageNum - numRows + 1}-${
                    numRows * pageNum > numAllRecords ? numAllRecords : numRows * pageNum
                } of ${numAllRecords}`}</span
            >
            <div
                class="cursor-pointer"
                disabled={pageNum === 1}
                on:click={() => prevPage()}
                on:keypress={() => prevPage()}
            >
                <Icon name="arrow-left" size={16} fill={pageNum === 1 ? "#D0D5DD" : "#667085"} />
            </div>
            <span class="px-1"
                >{$_("components.table.pageNumOfPages", {
                    values: { pageNum, amount: numPages }
                })}</span
            >
            <div
                class="cursor-pointer flip-icon"
                disabled={pageNum === numPages}
                on:click={() => nextPage()}
                on:keypress={() => nextPage()}
            >
                <Icon
                    name="arrow-left"
                    size={16}
                    fill={pageNum === numPages ? "#D0D5DD" : "#667085"}
                />
            </div>
        </div>
    {/if}
{/if}

<style lang="scss">
    .cell-max-width {
        max-width: 20vw;
    }

    td:first-child,
    th:first-child {
        padding-left: 2rem;
    }

    .table-container {
        height: 100%;
        &:not(.no-overflow) {
            overflow: auto;
        }
    }

    th {
        background-color: var(--bgGeneralQuarterary);
        height: 3rem;
        cursor: default;
        border-bottom: 1px solid var(--gray-300);
    }
    thead {
        position: sticky;
        top: 0;
        z-index: 2;
    }

    .clickable {
        cursor: pointer;
    }

    tr {
        border-bottom: 1px solid #e4e7ec;
        height: 4.5rem;
        cursor: var(--cursor, pointer);
    }

    tr:not(.loading-tr):hover {
        background-color: var(--bgInteractiveSemanticHighlightHoverSecondary);
    }

    .flip-icon {
        transform: scaleX(-1);
    }

    .page-counter {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #344054;
        user-select: none;
        box-shadow: 0px -10px 5px #eee;
        z-index: 10;
    }

    .date {
        width: 13rem;
    }

    // .number {
    //     width: 12rem;
    // }

    .status {
        // width: 12rem;
        z-index: 15;
    }

    .cell-action {
        max-width: max-content;
        width: 0;
        padding: 5px 10px 5px 0;
    }

    .customer-details {
        min-width: 12rem;
    }

    .wide-customer-details {
        min-width: 18rem;
    }

    .right-align-td {
        text-align: right;
    }
    :global(.right-align-td > div) {
        margin-right: 1rem;
    }
    :global(.right-align-td:last-child > div) {
        margin-right: 1.5rem;
    }
    .right-align-th > div {
        justify-content: flex-end;
        margin-right: 1rem;
    }
    .right-align-th:last-child > div {
        margin-right: 1.5rem;
    }

    .tooltip-container {
        margin: auto 0.5rem;
    }

    .loading-container {
        position: relative;
        width: 100%;
        height: 12rem;
    }

    @media only screen and (max-width: 1300px) {
        .date {
            width: 10rem;
        }

        .number {
            width: 8rem;
        }

        .status {
            width: 8rem;
        }
    }
</style>
