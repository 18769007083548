<script lang="ts">
    import { _ } from "svelte-i18n";
    import { AxiosError } from "axios";
    import { TextInput } from "@two-ui/core";

    import MetaWrapper from "../../../components/metadata/MetaWrapper.svelte";
    import PhoneInput from "../../../components/authentication/PhoneInput.svelte";
    import Button from "../../../components/Elements/Button.svelte";
    import VerifyCode from "../../../components/authentication/VerifyCode.svelte";
    import authenticationService from "../../../services/authenticationService";
    import authState from "../../../store/auth.store";
    import environment from "@/services/environment";
    import { validatePhoneNumber } from "../../../utils/validation";
    import { loggedInAsMerchantPortalUser } from "../../../store/merchant/account.store";

    export let isSentCode: boolean = false;

    let disableEdit: boolean = false;
    let phoneNumberWithCode: string = environment.branding.initialPhoneCountryCode;
    let phoneNumber: string = "";
    let countryCode: string = environment.branding.initialPhoneCountryCode;
    const EMAIL = "EMAIL";
    const SMS = "SMS";
    let loginMode: string = EMAIL;
    let userEmail: string = "";
    let isSubmitting: boolean = false;
    let inputError: string = "";
    const codeLength: number = 6;
    let verifyCode: string[] = new Array(codeLength).fill("");
    let isInvalid: boolean[] = new Array(codeLength).fill(false);

    function submitCode() {
        const formValue = {
            verification_type: loginMode === EMAIL ? EMAIL : SMS,
            code: verifyCode.join(""),
            verification_id: localStorage.getItem("verification_id")
        };
        isSubmitting = true;
        inputError = "";
        authenticationService
            .completeVerification(formValue)
            .then((res: any) => {
                loggedInAsMerchantPortalUser.set(true);
                window.location.href = res.complete_url;
            })
            .catch((err) => {
                if (err.response.data.error_code === "VERIFICATION_NOT_FOUND") {
                    inputError = $_("login.codeMismatch");
                } else if (err.response.data.error_code === "ACCESS_DENIED") {
                    inputError = $_("login.accessDenied");
                } else {
                    inputError = $_("login.loginError");
                    console.error(err);
                }
                isSubmitting = false;
            });
    }

    function submitEmail() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(userEmail)) {
            inputError = $_("login.invalidEmail");
            return;
        }
        inputError = "";
        requestCode(EMAIL, { email: userEmail });
    }

    async function requestCode(type: string, deliverTo: any) {
        isSubmitting = true;
        const formValue = {
            verification_type: type,
            verification_context: "MERCHANT_PORTAL_USER",
            ...deliverTo
        };
        authenticationService
            .triggerVerification(formValue)
            .then((res: any) => {
                localStorage.setItem("verification_id", res.verification_id);
                localStorage.setItem("redirect_url", res.redirect_url);

                isSentCode = true;
                isSubmitting = false;
                authState.action.updateIsValidPhone(true);
            })
            .catch(async (err: Promise<AxiosError>) => {
                let error = await err;
                if (error.response.status) {
                    inputError = $_("login.notConnectedToMerchant", {
                        values: {
                            type: type == SMS ? $_("login.thisPhone") : $_("login.thisEmail")
                        }
                    });
                } else {
                    inputError = $_("login.errorSendingCode");
                }
                isSubmitting = false;
            });
    }

    function submitMobile() {
        const invalid = validatePhoneNumber($_, countryCode, phoneNumber);
        if (invalid) {
            inputError = $_("login.invalidPhone");
            return;
        }
        requestCode(SMS, { phone_number: phoneNumberWithCode });
    }

    function setLoginMode(type: string) {
        loginMode = type;
        inputError = "";
        userEmail = "";
        phoneNumber = "";
        phoneNumberWithCode = countryCode;
    }
</script>

<MetaWrapper>
    <div>
        <div class="flex-col justify-center">
            {#if !isSentCode}
                <div class="login-tabs flex mt-8 gap-4">
                    <div
                        class="tab-item"
                        class:selected-tab={loginMode === EMAIL}
                        on:keypress={() => setLoginMode(EMAIL)}
                        on:click={() => setLoginMode(EMAIL)}
                    >
                        {$_("components.inputType.email")}
                    </div>
                    <div
                        class="tab-item"
                        class:selected-tab={loginMode === SMS}
                        on:keypress={() => setLoginMode(SMS)}
                        on:click={() => setLoginMode(SMS)}
                    >
                        {$_("components.inputType.phone")}
                    </div>
                </div>
            {/if}
            {#if !isSentCode}
                <div id="set-contact">
                    <p class="mt-6 input-label">
                        {loginMode === SMS
                            ? $_("components.inputType.phone")
                            : $_("components.inputType.email")}
                    </p>
                    {#if loginMode === SMS}
                        <PhoneInput
                            disable={disableEdit}
                            bind:phoneNumberWithCode
                            bind:phoneNumber
                            bind:countryCode
                            bind:invalid={inputError}
                            showInvalidError={true}
                        />
                    {:else}
                        <TextInput
                            size="md"
                            id="login-email"
                            bind:value={userEmail}
                            placeholder={"olivia@example.com"}
                            bind:error={inputError}
                        />
                    {/if}
                    <div class="relative mt-6">
                        <Button
                            id="login-btn"
                            loading={isSubmitting}
                            size="large"
                            label={$_("components.continue")}
                            on:click={loginMode === SMS ? submitMobile : submitEmail}
                        />
                    </div>
                </div>
            {:else}
                <div class="flex justify-center flex-wrap">
                    <div class="bg-white p-4 py-8 m-2 sm:p-12 card container">
                        <p class="text-xl font-bold text-center">
                            {loginMode === SMS
                                ? $_("components.input.checkPhone")
                                : $_("components.input.checkEmail")}
                        </p>
                        <p class="mt-6 text-center text-md ctn-general-secondary">
                            {$_("login.enterCode")}
                        </p>
                        <p class="text-center text-md">
                            {loginMode === SMS ? phoneNumber : userEmail}
                        </p>
                        <div class="mt-8">
                            <VerifyCode
                                bind:code={verifyCode}
                                bind:invalid={isInvalid}
                                codeError={inputError !== ""}
                            />
                            {#if inputError !== ""}
                                <div class=" error-message text-sm">{inputError}</div>
                            {/if}
                            <p class="mt-4 text-sm ctn-general-secondary">
                                {$_("login.resendCode")}
                                <span
                                    class="text-main cursor-pointer resend-btn"
                                    on:keydown={loginMode === SMS ? submitMobile : submitEmail}
                                    on:click={loginMode === SMS ? submitMobile : submitEmail}
                                >
                                    {$_("login.resendButton")}
                                </span>
                            </p>
                        </div>
                        <div class="relative mt-6">
                            <Button
                                id="submit-code"
                                loading={isSubmitting}
                                size="large"
                                label={$_("login.submitCode")}
                                on:click={submitCode}
                            />
                        </div>
                    </div>
                </div>
            {/if}
        </div>
    </div>
</MetaWrapper>

<style>
    .tab-item {
        height: 40px;
        padding: 8px 24px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-800);
    }
    .selected-tab {
        border-bottom: 2px solid var(--primary-500);
        color: var(--gray-800);
        font-weight: 700;
    }

    .error-message {
        color: var(--error-600);
        font-family: Aeonik;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
    }

    .login-tabs {
        border-bottom: 1px solid #e4e7ec;
    }

    * :global(.phone-input) {
        width: 100%;
    }

    .container {
        max-width: 500px;
        width: 100%;
        border-radius: 16px;
        box-shadow:
            0px 30px 50px rgba(var(--card-shadow), 0.03),
            0px 20px 35px rgba(var(--card-shadow), 0.04),
            0px 5px 20px rgba(var(--card-shadow), 0.05);
    }

    #set-contact {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .resend-btn {
        color: var(--ctnInteractiveSemanticHighlightDefault);
    }
</style>
